import { Tooltip, Card, Typography, Stack, Box, Button } from '@mui/material';
import { ActionInfo, AiFlowItemLogic } from 'src/api';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import { BiggerStyledIcon, SmallActionIcon } from 'src/components/nav-section/mini/styles';
import { useDispatch, useSelector } from 'src/redux/store';
import { useDraggable } from '@dnd-kit/core';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  addAiFlowItemBeforeSequence,
  addAiFlowItemsBeforeSequence,
  replaceItem,
  selectAiFlowItem,
  setSidebarPopoverOpen,
} from 'src/redux/slices/aiflows';
import _, { isObject } from 'lodash';
import { orderFlowItems, reassignSequencesUsingNames } from 'src/utils/aiFlowsUtils';

interface Params {
  actionInfo?: ActionInfo;
  sequence: string;
  type: string;
}
export default function AiFlowSidebarItem({ actionInfo, sequence, type }: Params) {
  // const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
  //   id: sequence,
  //   data: {
  //     actionInfo,
  //     type,
  //   },
  // });
  const isHovered = useBoolean(false);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const dispatch = useDispatch();
  const connections = useSelector((state) => state.connection.connections);
  const addBeforeSequence = useSelector((state) => state.aiFlows.addBeforeSequence);
  const addToParentSequence = useSelector((state) => state.aiFlows.addToParentSequence);
  const copiedItems = useSelector((state) => state.aiFlows.copiedItems);
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);

  return (
    <>
      <Tooltip
        PopperProps={{
          style: {
            zIndex: 3300,
          },
        }}
        style={{ zIndex: 3300 }}
        sx={{ zIndex: 3300 }}
        title={actionInfo?.description}
        placement="top"
      >
        <Card
          elevation={3}
          onClick={() => {
            console.log('actionInfo', actionInfo);
            let item = {
              header: actionInfo?.header as string,
              sequence: Number(sequence),
              aiFlowId: selectedAiFlow?.id as number,
            } as any;

            if (item.header === 'Paste') {
              if (!copiedItems.length) {
                return;
              }
              const items = _.cloneDeep(copiedItems);
              console.log('itemsz', items);
              items.forEach((i: AiFlowItemLogic, index: number) => {
                const nameAlreadyExist = selectedAiFlow?.items?.find(
                  (tmpItem) =>
                    tmpItem.name === i.name &&
                    tmpItem.sequence !== i.sequence &&
                    i.aiFlowVersionId === selectedAiFlowVersion?.id
                );
                i.name = nameAlreadyExist ? `${i.name}_${Math.floor(Math.random() * 2900 + 100)}` : i.name;
                i.aiFlowId = selectedAiFlow?.id as number;
                i.aiFlowVersionId = selectedAiFlowVersion?.id as number;
              });
              debugger;
              console.log('items1', items);
              reassignSequencesUsingNames(items);
              console.log('items2', items);
              debugger;
              dispatch(addAiFlowItemsBeforeSequence(items));
              dispatch(setSidebarPopoverOpen({ element: null }));
              return;
            }

            if (actionInfo?.prompt && actionInfo.systemPrompt) {
              const modelProvider = 'OpenAI';

              const connectionIds = connections
                .filter((connection: any) => connection.integrationName === modelProvider)
                .map((connection: any) => connection.id);
              if (connectionIds.length) {
                item = {
                  ...item,
                  inputData: {
                    actionInputData: {
                      promptInput: actionInfo.prompt,
                      configuration: {
                        connectionId: connectionIds[0],
                        modelProvider,
                      },
                    },
                  },
                };
              }
            }

            if (selectedItem) {
              item = {
                ...item,
                sequence: selectedItem.sequence,
                aiFlowId: selectedItem.aiFlowId,
                aiFlowVersionId: selectedItem.aiFlowVersionId,
                parentSequence: selectedItem.parentSequence,
              };
              dispatch(replaceItem({ oldItem: selectedItem, newItem: item }));
              dispatch(setSidebarPopoverOpen({ element: null }));
              dispatch(selectAiFlowItem(null));
              return;
            }

            if (addToParentSequence) {
              item = {
                ...item,
                parentSequence: Number(addToParentSequence),
              };
            }
            // // find the closest branch item that has lower sequence than this item
            // const closestBranchItem = selectedAiFlow?.items?.find(
            //   (i) => i && i.sequence && i.header === 'Branch' && i.sequence < Number(addBeforeSequence)
            // );
            // if (closestBranchItem?.inputData?.actionInputData?.branchingInputData?.branchInputData?.lastItemInBranchSequence as number
            //   >= Number(sequence)) {
            //     console.log('closestBranchItem', closestBranchItem);
            //   }

            debugger;
            if (item.header === 'Branches') {
              const newItem1 = {
                header: 'Branch',
                sequence: Number(sequence) + 1,
                parentSequence: (addToParentSequence as number) + 1,
                aiFlowId: selectedAiFlow?.id as number,
                aiFlowVersionId: selectedAiFlowVersion?.id as number,
                inputData: {
                  actionInputData: {
                    branchingInputData: {
                      branchInputData: {
                        branchName: 'A',
                        branchSequence: Number(sequence) + 1,
                        branchType: 'Run Always',
                      },
                    },
                  },
                },
              } as any;
              const newItem2 = {
                header: 'Branch',
                sequence: Number(sequence) + 2,
                parentSequence: (addToParentSequence as number) + 1,
                aiFlowId: selectedAiFlow?.id as number,
                aiFlowVersionId: selectedAiFlowVersion?.id as number,
                inputData: {
                  actionInputData: {
                    branchingInputData: {
                      branchInputData: {
                        branchName: 'B',
                        branchSequence: Number(sequence) + 2,
                        branchType: 'Run Always',
                      },
                    },
                  },
                },
              } as any;
              dispatch(addAiFlowItemsBeforeSequence([item, newItem1, newItem2]));
            } else {
              dispatch(addAiFlowItemBeforeSequence(item));
            }

            dispatch(setSidebarPopoverOpen({ element: null }));
          }}
          sx={{
            p: 2,
            zIndex: 3000,
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
            borderRadius: 1,
            borderColor: 'primary.main',
            cursor: 'pointer',
            ...(((!actionInfo?.disabled && actionInfo?.header !== 'Paste') ||
              (actionInfo?.header === 'Paste' && copiedItems.length > 0)) && {
              '&:hover': {
                border: '1px solid',
                borderColor: 'primary.main',
              },
            }),

            ...((actionInfo?.disabled ||
              (actionInfo?.header === 'Paste' && copiedItems.length === 0)) && {
              '::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(245, 245, 245, 0.6)',
                zIndex: 2,
                cursor: 'not-allowed',
              },
              p: 3,
              cursor: 'pointer',
              width: 1,
              minHeight: 110,
              position: 'relative',
            }),
            borderWidth: 2,
            border: '1px solid',
            //         ...style,
          }}
          onMouseEnter={isHovered.onTrue} // set hover state to true when mouse enters
          onMouseLeave={isHovered.onFalse} // set hover state to false when mouse leaves
          // {...(!actionInfo?.disabled ? { ...listeners, ...attributes } : {})}
        >
          {/* {isHovered.value && actionInfo?.custom && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.01)', // semi-transparent black background
                zIndex: 1, // ensure overlay is on top of other content
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    // dispatch(runAiFlow(aiFlow?.id as number, enqueueSnackbar));
                    // popover.onClose();
                  }}
                  sx = {{width:20}}
                >
                  <Stack direction="row" alignItems="center">
                    <Iconify icon="eva:more-vertical-fill" />
                  </Stack>
                </Button>
              </Stack>
            </Box>
          )} */}
          <Stack alignItems="center">
            {type === 'Action' && (
              <>
                <BiggerStyledIcon>{ACTION_ICONS[actionInfo?.icon as string]}</BiggerStyledIcon>

                <Typography sx={{ ml: 1 }} variant="subtitle2">
                  {actionInfo?.actionLabel}
                </Typography>
              </>
            )}
            {type === 'Validator' && (
              <>
                <BiggerStyledIcon>{ACTION_ICONS.validation}</BiggerStyledIcon>

                <Typography sx={{ ml: 1 }} variant="subtitle2">
                  Validator
                </Typography>
              </>
            )}
            {type === 'Delay' && (
              <>
                <Iconify width={30} height={30} icon="mdi:clock-outline" />

                <Typography sx={{ ml: 1 }} variant="subtitle2">
                  Delay
                </Typography>
              </>
            )}
          </Stack>
        </Card>
      </Tooltip>
    </>
  );
}
