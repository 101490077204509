import { Typography, Link, Tooltip, Box, Divider, Stack } from '@mui/material';
import Iconify from '../iconify';

interface Params {
  conditionResult: any;
  conditionResults: any[];
  selectedRunItems: any[];
  onSourceClick: (source: string) => void;
  index: number;
}

export default function ConditionResult({
  conditionResult,
  selectedRunItems,
  onSourceClick,
  conditionResults,
  index,
}: Params) {
  return (
    <>
      <Stack spacing={0}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            pl: 2,
            pr: 1,
            py: 1,
            //   '&:not(:last-of-type)': {
            //     borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
            //   },
          }}
        >
          <Iconify
            icon={conditionResult.result ? 'carbon:checkmark' : 'carbon:close'}
            sx={{ color: conditionResult.result ? 'primary.main' : 'error.main' }}
          />
          <Link
            onClick={() => {
              onSourceClick(conditionResult.source);
            }}
            sx={{ cursor: 'pointer' }}
            underline="always"
          >
            <Typography variant="subtitle1">
              {selectedRunItems.find((i) => i.name === conditionResult.source)?.sequence ?? '1'}.{' '}
              {conditionResult.source}
            </Typography>
          </Link>
          <Typography variant="subtitle1">{conditionResult.condition}</Typography>
          <Tooltip sx={{ zIndex: 9500 }} title={conditionResult.conditionValue}>
            <Typography
              sx={
                // add elipsis of 10ch
                {
                  maxWidth: '16ch',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }
              }
              variant="body1"
            >
              {conditionResult.conditionValue}
            </Typography>
          </Tooltip>
        </Stack>
        {conditionResults.length - 1 > index && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Divider sx={{ flexGrow: 1, mb: 1, borderStyle: 'dashed' }} />
              <Typography variant="body2" sx={{ mx: 2 }}>
                {conditionResult.operator}
              </Typography>
              <Divider sx={{ flexGrow: 1, mb: 1, borderStyle: 'dashed' }} />
            </Box>
          </>
        )}
      </Stack>
    </>
  );
}
