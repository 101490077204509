import { MenuItem, Avatar } from '@mui/material';
import { Stack } from '@mui/system';
import { RHSelect } from '../hook-form';
import { useState } from 'react';

export interface Props {
  handleSelectCondition: (property: string) => void;
  selectedConditionInput?: string;
  maxWidth?: number;
  disabled?: boolean;
  px?: any;
}

export default function ConditionSelect({
  handleSelectCondition,
  maxWidth,
  disabled,
  selectedConditionInput,
  px,
}: Props) {
  const conditions = [
    { conditionLabel: 'Equal', condition: 'equal' },
    { conditionLabel: 'Not Equal', condition: 'notEqual' },
    { conditionLabel: 'Greater Than', condition: 'greaterThan' },
    { conditionLabel: 'Less Than', condition: 'lessThan' },
    { conditionLabel: 'Greater Than or Equal', condition: 'greaterThanOrEqual' },
    { conditionLabel: 'Less Than or Equal', condition: 'lessThanOrEqual' },
    { conditionLabel: 'Contains', condition: 'contains' },
    { conditionLabel: 'Does Not Contain', condition: 'doesNotContain' },
    { conditionLabel: 'Starts With', condition: 'startsWith' },
    { conditionLabel: 'Ends With', condition: 'endsWith' },
    { conditionLabel: 'Matches Regex', condition: 'matchesRegex' },
    { conditionLabel: 'Does Not Match Regex', condition: 'doesNotMatchRegex' },
  ];
  const [selectedCondition, setSelectedCondition] = useState<string>("contains");

  return (
    <>
      <RHSelect
        disabled={disabled}
        name="selectCondition"
        sx={{ px: 1 }}
        label="Select Condition"
        zIndex={2500}
        value={(selectedCondition ?? selectedConditionInput) || ''}
        onChange={(e) => {
          setSelectedCondition(e.target.value);
          handleSelectCondition(e.target.value as string);
        }}
        size="small"
        InputLabelProps={{ shrink: true }}
      >
        {conditions.map((condition, index) => (
          <MenuItem key={index} value={condition?.condition ?? ''}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <span>{condition?.conditionLabel}</span>
            </Stack>
          </MenuItem>
        ))}
      </RHSelect>
    </>
  );
}
