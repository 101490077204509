import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputCondition } from 'src/api';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import ValidationForm from 'src/components/validation/ConditionForm';
import { setActionValidated, updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import * as Yup from 'yup';
import FailoverTypeSelect from './FailoverTypeSelect';
import { FailoverTypes } from 'src/@types/aiflow';

export default function AiFlowBranchActionSetup() {
  const dispatch = useDispatch();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const [branchName, setBranchName] = useState<string>();
  const [branchType, setBranchType] = useState<string>('Run Always');
  const [failoverType, setFailoverType] = useState<FailoverTypes>(FailoverTypes.ValidationFailure);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const [conditionValue, setConditionValue] = useState<string>('');
  const [conditions, setConditions] = useState<InputCondition[]>([{ condition: 'contains' }]);
  const [branchTypes, setBranchTypes] = useState([
    { label: 'Run Always', value: 'Run Always', disabled: runsMode },
    { label: 'Condition', value: 'Condition', disabled: runsMode },
    {
      label: 'Failover',
      value: 'Failover',
      disabled:
        runsMode &&
        !!selectedAiFlow?.items?.find(
          (i) =>
            i.header === 'Branch' &&
            i.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchType ===
              'Failover'
        ),
    },
  ]);

  const defaultValues = {
    branchName: branchName ?? '',
  };

  const AiFlowSchema: any = Yup.object().shape({
    branchName: Yup.string()
      .required('Required')
      .test(
        'uniqueName',
        'Name already exists',
        (value: any) => !branchNameAlreadyExist(value as string)
      ),
  });

  const methods = useForm<any>({
    resolver: yupResolver(AiFlowSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedItem) {
      setBranchName(
        selectedItem.inputData?.actionInputData?.branchingInputData?.branchInputData
          ?.branchName as string
      );
      setBranchType(
        (selectedItem.inputData?.actionInputData?.branchingInputData?.branchInputData
          ?.branchType as string) ?? 'Run Always'
      );
      setValue(
        'branchType',
        (selectedItem.inputData?.actionInputData?.branchingInputData?.branchInputData
          ?.branchType as string) ?? 'Run Always'
      );
      setValue(
        'branchName',
        selectedItem.inputData?.actionInputData?.branchingInputData?.branchInputData
          ?.branchName as string
      );
      setConditions(
        selectedItem.inputData?.actionInputData?.branchingInputData?.branchInputData
          ?.conditions ?? [{ condition: 'contains' }]
      );
      methods.trigger();
      setFailoverType(
        selectedItem.inputData?.actionInputData?.branchingInputData?.branchInputData
          ?.failoverType as FailoverTypes
      );
    }
  }, [selectedItem, setValue, methods]);

  const branchNameAlreadyExist = (name: string) => {
    if (!selectedItem?.id || runsMode) {
      return false;
    }
    const existingItem = selectedAiFlow?.items
      ?.filter((i) => i.aiFlowVersionId === selectedItem.aiFlowVersionId)
      .find(
        (item) =>
          item.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchName?.toLocaleLowerCase() ===
            name.toLocaleLowerCase() && item.id !== selectedItem?.id
      );
    return !!existingItem;
  };

  useEffect(() => {
    dispatch(setActionValidated(isValid));
  }, [isValid, dispatch]);

  const updateConditionsItem = (newConditions: InputCondition[]) => {
    const updatedItem = {
      ...selectedItem,
      inputData: {
        ...selectedItem?.inputData,
        actionInputData: {
          ...selectedItem?.inputData?.actionInputData,
          branchingInputData: {
            ...selectedItem?.inputData?.actionInputData?.branchingInputData,
            branchInputData: {
              ...selectedItem?.inputData?.actionInputData?.branchingInputData?.branchInputData,
              conditions: newConditions,
            },
          },
        },
      },
    };
    dispatch(updateSelectedAiFlowItem(updatedItem));
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Stack spacing={3} sx={{ mt: 2, mx: 1 }}>
          <RHFTextField
            id="branchName"
            disabled={runsMode}
            size="small"
            name="branchName"
            value={branchName}
            label="Branch Name"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setBranchName(e.target.value);
              setValue('branchName', e.target.value);
              const updateItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    branchingInputData: {
                      ...selectedItem?.inputData?.actionInputData?.branchingInputData,
                      branchInputData: {
                        ...selectedItem?.inputData?.actionInputData?.branchingInputData
                          ?.branchInputData,
                        branchName: e.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updateItem));
            }}
          />
          <RHRadioGroup
            value={branchType} // upload/url
            onChange={(event) => {
              setBranchType(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    branchingInputData: {
                      ...selectedItem?.inputData?.actionInputData?.branchingInputData,
                      branchInputData: {
                        ...selectedItem?.inputData?.actionInputData?.branchingInputData
                          ?.branchInputData,
                        branchType: event.target.value,
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={4}
            name="branchTypes"
            options={branchTypes}
          />
          {branchType === 'Run Always' ||
            (branchType === 'Failover' && (
              <>
                <Typography variant="body2" sx={{ textAlign: 'left', paddingBottom: 1, ml: 9 }}>
                  {branchType !== 'Failover' && ( // write short description about it
                    <span>Run Always: This branch will run every time.</span>
                  )}

                  {branchType === 'Failover' && ( // write short description about it
                    <span>
                      Failover: This branch will run only if any of the previous branches fails.
                    </span>
                  )}
                </Typography>
              </>
            ))}
          {branchType === 'Condition' && (
            <>
              <ValidationForm
                inputConditions={conditions}
                onUpdateConditions={updateConditionsItem}
              />
            </>
          )}
          {branchType === 'Failover' && (
            <FailoverTypeSelect
              disabled={runsMode}
              selectedFailoverTypeInput={failoverType}
              handleSelectFailoverType={(ft) => {
                setFailoverType(ft);
                const updatedItem = {
                  ...selectedItem,
                  inputData: {
                    ...selectedItem?.inputData,
                    actionInputData: {
                      ...selectedItem?.inputData?.actionInputData,
                      branchingInputData: {
                        ...selectedItem?.inputData?.actionInputData?.branchingInputData,
                        branchInputData: {
                          ...selectedItem?.inputData?.actionInputData?.branchingInputData
                            ?.branchInputData,
                          failoverType: ft,
                        },
                      },
                    },
                  },
                };
                dispatch(updateSelectedAiFlowItem(updatedItem));
              }}
            />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
