/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountOnBoardingLogic
 */
export interface AccountOnBoardingLogic {
    /**
     * 
     * @type {number}
     * @memberof AccountOnBoardingLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOnBoardingLogic
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOnBoardingLogic
     */
    'automationObjectives'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOnBoardingLogic
     */
    'companySize'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionInfo
 */
export interface ActionInfo {
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'actionLabel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'route'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'systemPrompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'icon'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfo
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionInfo
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfo
     */
    'disabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInfo
     */
    'custom'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ActionInputDataLogic
 */
export interface ActionInputDataLogic {
    /**
     * 
     * @type {string}
     * @memberof ActionInputDataLogic
     */
    'promptInput'?: string | null;
    /**
     * 
     * @type {InputData}
     * @memberof ActionInputDataLogic
     */
    'inputData'?: InputData;
    /**
     * 
     * @type {ConditionInputData}
     * @memberof ActionInputDataLogic
     */
    'conditionInputData'?: ConditionInputData;
    /**
     * 
     * @type {TriggerData}
     * @memberof ActionInputDataLogic
     */
    'triggerData'?: TriggerData;
    /**
     * 
     * @type {OperationsInputData}
     * @memberof ActionInputDataLogic
     */
    'operationsInputData'?: OperationsInputData;
    /**
     * 
     * @type {DelayInputData}
     * @memberof ActionInputDataLogic
     */
    'delayInputData'?: DelayInputData;
    /**
     * 
     * @type {ExportData}
     * @memberof ActionInputDataLogic
     */
    'exportData'?: ExportData;
    /**
     * 
     * @type {FunctionalInputData}
     * @memberof ActionInputDataLogic
     */
    'functionalInputData'?: FunctionalInputData;
    /**
     * 
     * @type {CommunicationInputData}
     * @memberof ActionInputDataLogic
     */
    'communicationInputData'?: CommunicationInputData;
    /**
     * 
     * @type {BranchingInputData}
     * @memberof ActionInputDataLogic
     */
    'branchingInputData'?: BranchingInputData;
    /**
     * 
     * @type {LlmInputData}
     * @memberof ActionInputDataLogic
     */
    'llmInputData'?: LlmInputData;
    /**
     * 
     * @type {ConfigurationLogic}
     * @memberof ActionInputDataLogic
     */
    'configuration'?: ConfigurationLogic;
    /**
     * 
     * @type {number}
     * @memberof ActionInputDataLogic
     */
    'configurationId'?: number | null;
}
/**
 * 
 * @export
 * @interface AddAiFlowRunRequest
 */
export interface AddAiFlowRunRequest {
    /**
     * 
     * @type {AiFlowRunLogic}
     * @memberof AddAiFlowRunRequest
     */
    'task'?: AiFlowRunLogic;
}
/**
 * 
 * @export
 * @interface AiFlowActionGroupLogic
 */
export interface AiFlowActionGroupLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowActionGroupLogic
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiFlowActionGroupLogic
     */
    'label'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AiFlowActionGroupLogic
     */
    'custom'?: boolean | null;
    /**
     * 
     * @type {Array<ActionInfo>}
     * @memberof AiFlowActionGroupLogic
     */
    'actions'?: Array<ActionInfo> | null;
}
/**
 * 
 * @export
 * @interface AiFlowFolderLogic
 */
export interface AiFlowFolderLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowFolderLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowFolderLogic
     */
    'aiFlowId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiFlowFolderLogic
     */
    'folderId'?: number;
}
/**
 * 
 * @export
 * @interface AiFlowItemLogic
 */
export interface AiFlowItemLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemLogic
     */
    'aiFlowId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemLogic
     */
    'sequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemLogic
     */
    'parentSequence'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowItemLogic
     */
    'header'?: string | null;
    /**
     * 
     * @type {InputDataLogic}
     * @memberof AiFlowItemLogic
     */
    'inputData'?: InputDataLogic;
    /**
     * 
     * @type {string}
     * @memberof AiFlowItemLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemLogic
     */
    'aiFlowVersionId'?: number | null;
    /**
     * 
     * @type {Array<AiFlowItemNoteLogic>}
     * @memberof AiFlowItemLogic
     */
    'aiFlowItemNotes'?: Array<AiFlowItemNoteLogic> | null;
}
/**
 * 
 * @export
 * @interface AiFlowItemNoteLogic
 */
export interface AiFlowItemNoteLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemNoteLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowItemNoteLogic
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowItemNoteLogic
     */
    'authorAvatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowItemNoteLogic
     */
    'note'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowItemNoteLogic
     */
    'createdAt'?: Date;
    /**
     * 
     * @type {number}
     * @memberof AiFlowItemNoteLogic
     */
    'aiFlowItemId'?: number;
}
/**
 * 
 * @export
 * @interface AiFlowLogic
 */
export interface AiFlowLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AiFlowLogic
     */
    'active'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowLogic
     */
    'createTime'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowLogic
     */
    'lastUpdated'?: Date | null;
    /**
     * 
     * @type {AiFlowType}
     * @memberof AiFlowLogic
     */
    'aiFlowType'?: AiFlowType;
    /**
     * 
     * @type {AiFlowStatus}
     * @memberof AiFlowLogic
     */
    'status'?: AiFlowStatus;
    /**
     * 
     * @type {AiFlowScheduleInfoLogic}
     * @memberof AiFlowLogic
     */
    'aiFlowScheduleInfo'?: AiFlowScheduleInfoLogic;
    /**
     * 
     * @type {Array<AiFlowItemLogic>}
     * @memberof AiFlowLogic
     */
    'items'?: Array<AiFlowItemLogic> | null;
    /**
     * 
     * @type {Array<AiFlowItemLogic>}
     * @memberof AiFlowLogic
     */
    'deletedItems'?: Array<AiFlowItemLogic> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AiFlowLogic
     */
    'folderIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<AiFlowVersionLogic>}
     * @memberof AiFlowLogic
     */
    'aiFlowVersions'?: Array<AiFlowVersionLogic> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AiFlowLogic
     */
    'draft'?: boolean;
}


/**
 * 
 * @export
 * @interface AiFlowRunLogic
 */
export interface AiFlowRunLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunLogic
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiFlowRunLogic
     */
    'aiFlowName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunLogic
     */
    'aiFlowId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiFlowRunLogic
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunLogic
     */
    'sequence'?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowRunLogic
     */
    'createdAt'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowRunLogic
     */
    'startedAt'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowRunLogic
     */
    'completedAt'?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunLogic
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunLogic
     */
    'runNumber'?: number;
}
/**
 * 
 * @export
 * @interface AiFlowRunOutputLogic
 */
export interface AiFlowRunOutputLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunOutputLogic
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunOutputLogic
     */
    'aiFlowRunId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunOutputLogic
     */
    'aiFlowItemId'?: number;
    /**
     * 
     * @type {RunOutput}
     * @memberof AiFlowRunOutputLogic
     */
    'output'?: RunOutput;
    /**
     * 
     * @type {Array<OutputCommentLogic>}
     * @memberof AiFlowRunOutputLogic
     */
    'comments'?: Array<OutputCommentLogic> | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowRunOutputLogic
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowRunOutputLogic
     */
    'outputNumber'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AiFlowRunStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type AiFlowRunStatus = typeof AiFlowRunStatus[keyof typeof AiFlowRunStatus];


/**
 * 
 * @export
 * @interface AiFlowScheduleInfoLogic
 */
export interface AiFlowScheduleInfoLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowScheduleInfoLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowScheduleInfoLogic
     */
    'aiFlowId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiFlowScheduleInfoLogic
     */
    'days'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowScheduleInfoLogic
     */
    'monthlyDate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowScheduleInfoLogic
     */
    'timeInMinutes'?: number;
    /**
     * 
     * @type {AiFlowScheduleType}
     * @memberof AiFlowScheduleInfoLogic
     */
    'type'?: AiFlowScheduleType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AiFlowScheduleType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AiFlowScheduleType = typeof AiFlowScheduleType[keyof typeof AiFlowScheduleType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AiFlowStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AiFlowStatus = typeof AiFlowStatus[keyof typeof AiFlowStatus];


/**
 * 
 * @export
 * @interface AiFlowTemplate
 */
export interface AiFlowTemplate {
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AiFlowTemplate
     */
    'soon'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiFlowTemplate
     */
    'integrations'?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowTemplate
     */
    'dateAdded'?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowTemplate
     */
    'used'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'subCategory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplate
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AiFlowItemLogic>}
     * @memberof AiFlowTemplate
     */
    'items'?: Array<AiFlowItemLogic> | null;
}
/**
 * 
 * @export
 * @interface AiFlowTemplateGroupLogic
 */
export interface AiFlowTemplateGroupLogic {
    /**
     * 
     * @type {string}
     * @memberof AiFlowTemplateGroupLogic
     */
    'group'?: string | null;
    /**
     * 
     * @type {Array<AiFlowTemplate>}
     * @memberof AiFlowTemplateGroupLogic
     */
    'templates'?: Array<AiFlowTemplate> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AiFlowType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AiFlowType = typeof AiFlowType[keyof typeof AiFlowType];


/**
 * 
 * @export
 * @interface AiFlowVersionLogic
 */
export interface AiFlowVersionLogic {
    /**
     * 
     * @type {number}
     * @memberof AiFlowVersionLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowVersionLogic
     */
    'aiFlowId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiFlowVersionLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AiFlowVersionLogic
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AiFlowVersionLogic
     */
    'version'?: number;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowVersionLogic
     */
    'createdAt'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AiFlowVersionLogic
     */
    'lastSaved'?: Date | null;
}
/**
 * 
 * @export
 * @interface ApiKeyDetails
 */
export interface ApiKeyDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDetails
     */
    'apiKey'?: string | null;
}
/**
 * 
 * @export
 * @interface AuthorizationData
 */
export interface AuthorizationData {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    'bearerToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    'apiKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    'apiKeyValue'?: string | null;
}
/**
 * 
 * @export
 * @interface BillingBasicInfo
 */
export interface BillingBasicInfo {
    /**
     * 
     * @type {string}
     * @memberof BillingBasicInfo
     */
    'subscriptionStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingBasicInfo
     */
    'productName'?: string | null;
}
/**
 * 
 * @export
 * @interface BillingDetailedInfo
 */
export interface BillingDetailedInfo {
    /**
     * 
     * @type {number}
     * @memberof BillingDetailedInfo
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailedInfo
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailedInfo
     */
    'billingCycle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailedInfo
     */
    'billingName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailedInfo
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingDetailedInfo
     */
    'paymentMethodLast4'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BillingDetailedInfo
     */
    'nextBillingDate'?: Date | null;
    /**
     * 
     * @type {Array<BillingInvoice>}
     * @memberof BillingDetailedInfo
     */
    'billingInvoices'?: Array<BillingInvoice> | null;
}
/**
 * 
 * @export
 * @interface BillingInvoice
 */
export interface BillingInvoice {
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    'number'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BillingInvoice
     */
    'date'?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof BillingInvoice
     */
    'amountPaid'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingInvoice
     */
    'invoicePdf'?: string | null;
}
/**
 * 
 * @export
 * @interface BranchInputData
 */
export interface BranchInputData {
    /**
     * 
     * @type {string}
     * @memberof BranchInputData
     */
    'branchName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchInputData
     */
    'branchType'?: string | null;
    /**
     * 
     * @type {FailoverTypes}
     * @memberof BranchInputData
     */
    'failoverType'?: FailoverTypes;
    /**
     * 
     * @type {Array<InputCondition>}
     * @memberof BranchInputData
     */
    'conditions'?: Array<InputCondition> | null;
}


/**
 * 
 * @export
 * @interface BranchesInputData
 */
export interface BranchesInputData {
    /**
     * 
     * @type {string}
     * @memberof BranchesInputData
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface BranchingInputData
 */
export interface BranchingInputData {
    /**
     * 
     * @type {BranchesInputData}
     * @memberof BranchingInputData
     */
    'branchesInputData'?: BranchesInputData;
    /**
     * 
     * @type {BranchInputData}
     * @memberof BranchingInputData
     */
    'branchInputData'?: BranchInputData;
}
/**
 * 
 * @export
 * @interface BrandVoiceLogic
 */
export interface BrandVoiceLogic {
    /**
     * 
     * @type {number}
     * @memberof BrandVoiceLogic
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandVoiceLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandVoiceLogic
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandVoiceLogic
     */
    'modelName'?: string | null;
}
/**
 * 
 * @export
 * @interface CancelAiFlowRunRequest
 */
export interface CancelAiFlowRunRequest {
    /**
     * 
     * @type {number}
     * @memberof CancelAiFlowRunRequest
     */
    'aiFlowRunId'?: number;
}
/**
 * 
 * @export
 * @interface ChangeAiFlowRunStatusRequest
 */
export interface ChangeAiFlowRunStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangeAiFlowRunStatusRequest
     */
    'aiFlowRunId'?: number;
    /**
     * 
     * @type {AiFlowRunStatus}
     * @memberof ChangeAiFlowRunStatusRequest
     */
    'newStatus'?: AiFlowRunStatus;
}


/**
 * 
 * @export
 * @interface CombineInputData
 */
export interface CombineInputData {
    /**
     * 
     * @type {Array<string>}
     * @memberof CombineInputData
     */
    'sources'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CombineInputData
     */
    'combineWith'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombineInputData
     */
    'propertyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombineInputData
     */
    'sourceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CombineInputData
     */
    'sourceSelectionMode'?: string | null;
}
/**
 * 
 * @export
 * @interface CommunicationInputData
 */
export interface CommunicationInputData {
    /**
     * 
     * @type {EmailInputData}
     * @memberof CommunicationInputData
     */
    'emailInput'?: EmailInputData;
    /**
     * 
     * @type {HttpInputData}
     * @memberof CommunicationInputData
     */
    'httpInput'?: HttpInputData;
}
/**
 * 
 * @export
 * @interface ConditionInputData
 */
export interface ConditionInputData {
    /**
     * 
     * @type {Array<InputCondition>}
     * @memberof ConditionInputData
     */
    'conditions'?: Array<InputCondition> | null;
}
/**
 * 
 * @export
 * @interface ConfigurationLogic
 */
export interface ConfigurationLogic {
    /**
     * 
     * @type {number}
     * @memberof ConfigurationLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationLogic
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationLogic
     */
    'modelProvider'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationLogic
     */
    'maxTokens'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationLogic
     */
    'temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationLogic
     */
    'connectionId'?: number | null;
    /**
     * 
     * @type {LoopSettings}
     * @memberof ConfigurationLogic
     */
    'loopSettings'?: LoopSettings;
}
/**
 * 
 * @export
 * @interface ConnectionLogic
 */
export interface ConnectionLogic {
    /**
     * 
     * @type {number}
     * @memberof ConnectionLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogic
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogic
     */
    'connectionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogic
     */
    'integrationType'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ConnectionLogic
     */
    'connectionDetails'?: object;
    /**
     * 
     * @type {ApiKeyDetails}
     * @memberof ConnectionLogic
     */
    'apiKeyDetails'?: ApiKeyDetails;
    /**
     * 
     * @type {OauthDetails}
     * @memberof ConnectionLogic
     */
    'oauthDetails'?: OauthDetails;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionLogic
     */
    'default'?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ConnectionLogic
     */
    'dateAdded'?: Date;
}
/**
 * 
 * @export
 * @interface ContactUsRequest
 */
export interface ContactUsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactUsRequest
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsRequest
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ContentAnalysisRequest
 */
export interface ContentAnalysisRequest {
    /**
     * 
     * @type {string}
     * @memberof ContentAnalysisRequest
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentAnalysisRequest
     */
    'contentChecks'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentAnalysisRequest
     */
    'customCheck'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentAnalysisRequest
     */
    'contentToAnalyze'?: string | null;
    /**
     * 
     * @type {ConfigurationLogic}
     * @memberof ContentAnalysisRequest
     */
    'configuration'?: ConfigurationLogic;
}
/**
 * 
 * @export
 * @interface ContentAnalysisResponse
 */
export interface ContentAnalysisResponse {
    /**
     * 
     * @type {string}
     * @memberof ContentAnalysisResponse
     */
    'analysis'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContentType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ContentType = typeof ContentType[keyof typeof ContentType];


/**
 * 
 * @export
 * @interface CopyMoveAiFolderRequest
 */
export interface CopyMoveAiFolderRequest {
    /**
     * 
     * @type {number}
     * @memberof CopyMoveAiFolderRequest
     */
    'moveFromFolderId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CopyMoveAiFolderRequest
     */
    'folderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CopyMoveAiFolderRequest
     */
    'aiFlowId'?: number;
}
/**
 * 
 * @export
 * @interface CraftRequestLogic
 */
export interface CraftRequestLogic {
    /**
     * 
     * @type {number}
     * @memberof CraftRequestLogic
     */
    'orgId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CraftRequestLogic
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CraftRequestLogic
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CraftRequestLogic
     */
    'system'?: string | null;
    /**
     * 
     * @type {ConfigurationLogic}
     * @memberof CraftRequestLogic
     */
    'configurations'?: ConfigurationLogic;
    /**
     * 
     * @type {number}
     * @memberof CraftRequestLogic
     */
    'customActionId'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CraftRequestLogic
     */
    'imageUrls'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CraftResponseLogic
 */
export interface CraftResponseLogic {
    /**
     * 
     * @type {string}
     * @memberof CraftResponseLogic
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CraftResponseLogic
     */
    'errorCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CraftResponseLogic
     */
    'content'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CraftResponseLogic
     */
    'contentId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CraftResponseLogic
     */
    'header'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAiFlowItemNoteRequest
 */
export interface CreateAiFlowItemNoteRequest {
    /**
     * 
     * @type {AiFlowItemNoteLogic}
     * @memberof CreateAiFlowItemNoteRequest
     */
    'aiFlowItemNote'?: AiFlowItemNoteLogic;
}
/**
 * 
 * @export
 * @interface CreateAiFlowVersionRequest
 */
export interface CreateAiFlowVersionRequest {
    /**
     * 
     * @type {AiFlowVersionLogic}
     * @memberof CreateAiFlowVersionRequest
     */
    'aiFlowVersion'?: AiFlowVersionLogic;
}
/**
 * 
 * @export
 * @interface CreateAiFlowVersionResponse
 */
export interface CreateAiFlowVersionResponse {
    /**
     * 
     * @type {AiFlowVersionLogic}
     * @memberof CreateAiFlowVersionResponse
     */
    'newVersion'?: AiFlowVersionLogic;
    /**
     * 
     * @type {Array<AiFlowItemLogic>}
     * @memberof CreateAiFlowVersionResponse
     */
    'items'?: Array<AiFlowItemLogic> | null;
}
/**
 * 
 * @export
 * @interface CreateNotificationRequest
 */
export interface CreateNotificationRequest {
    /**
     * 
     * @type {NotificationLogic}
     * @memberof CreateNotificationRequest
     */
    'notification'?: NotificationLogic;
}
/**
 * 
 * @export
 * @interface CreateOutputCommentRequest
 */
export interface CreateOutputCommentRequest {
    /**
     * 
     * @type {OutputCommentLogic}
     * @memberof CreateOutputCommentRequest
     */
    'comment'?: OutputCommentLogic;
}
/**
 * 
 * @export
 * @interface CreditStatusLogic
 */
export interface CreditStatusLogic {
    /**
     * 
     * @type {number}
     * @memberof CreditStatusLogic
     */
    'monthlyUnitsUsed'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditStatusLogic
     */
    'totalUnitsUsed'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditStatusLogic
     */
    'additionalUnits'?: number;
}
/**
 * 
 * @export
 * @interface CustomActionLogic
 */
export interface CustomActionLogic {
    /**
     * 
     * @type {number}
     * @memberof CustomActionLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomActionLogic
     */
    'actionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomActionLogic
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomActionLogic
     */
    'systemPrompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomActionLogic
     */
    'prompt'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomActionRequest
 */
export interface CustomActionRequest {
    /**
     * 
     * @type {CustomActionLogic}
     * @memberof CustomActionRequest
     */
    'customAction'?: CustomActionLogic;
}
/**
 * 
 * @export
 * @interface DashboardWidgetsData
 */
export interface DashboardWidgetsData {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DashboardWidgetsData
     */
    'popularActions'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Array<RecentOutputLogic>}
     * @memberof DashboardWidgetsData
     */
    'recentOutputs'?: Array<RecentOutputLogic> | null;
    /**
     * 
     * @type {RunsUsage}
     * @memberof DashboardWidgetsData
     */
    'runsUsage'?: RunsUsage;
    /**
     * 
     * @type {LlmModelUsage}
     * @memberof DashboardWidgetsData
     */
    'llmModelUsage'?: LlmModelUsage;
}
/**
 * 
 * @export
 * @interface DelayInputData
 */
export interface DelayInputData {
    /**
     * 
     * @type {DelayType}
     * @memberof DelayInputData
     */
    'delayType'?: DelayType;
    /**
     * 
     * @type {number}
     * @memberof DelayInputData
     */
    'delayQuantity'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DelayType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type DelayType = typeof DelayType[keyof typeof DelayType];


/**
 * 
 * @export
 * @interface DeleteAiFlowRequest
 */
export interface DeleteAiFlowRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteAiFlowRequest
     */
    'aiFlowId'?: number;
}
/**
 * 
 * @export
 * @interface DeleteBrandVoiceRequest
 */
export interface DeleteBrandVoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteBrandVoiceRequest
     */
    'brandVoiceId'?: number;
}
/**
 * 
 * @export
 * @interface DeleteConfigurationsRequest
 */
export interface DeleteConfigurationsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteConfigurationsRequest
     */
    'configurationsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteContentSnippetsRequest
 */
export interface DeleteContentSnippetsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteContentSnippetsRequest
     */
    'snippetContentIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeletePersonaRequest
 */
export interface DeletePersonaRequest {
    /**
     * 
     * @type {number}
     * @memberof DeletePersonaRequest
     */
    'personaId'?: number;
}
/**
 * 
 * @export
 * @interface DeleteUserGeneratedContentsRequest
 */
export interface DeleteUserGeneratedContentsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteUserGeneratedContentsRequest
     */
    'userGeneratedContentsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface EmailInputData
 */
export interface EmailInputData {
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'provider'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmailInputData
     */
    'connectionId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailInputData
     */
    'toList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailInputData
     */
    'ccList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailInputData
     */
    'bccList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailInputData
     */
    'attachmentSources'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'sendType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'fromEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'subjectLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'bodyType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'bodyPlainText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailInputData
     */
    'bodyHtml'?: string | null;
}
/**
 * 
 * @export
 * @interface EmbeddingInputData
 */
export interface EmbeddingInputData {
    /**
     * 
     * @type {string}
     * @memberof EmbeddingInputData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmbeddingInputData
     */
    'model'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmbeddingInputData
     */
    'connectionId'?: number;
}
/**
 * 
 * @export
 * @interface ExportData
 */
export interface ExportData {
    /**
     * 
     * @type {string}
     * @memberof ExportData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportData
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportData
     */
    'fileName'?: string | null;
}
/**
 * 
 * @export
 * @interface ExtractorInputData
 */
export interface ExtractorInputData {
    /**
     * 
     * @type {string}
     * @memberof ExtractorInputData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractorInputData
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractorInputData
     */
    'extract'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractorInputData
     */
    'extractInput'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FailoverTypes = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type FailoverTypes = typeof FailoverTypes[keyof typeof FailoverTypes];


/**
 * 
 * @export
 * @interface FilesRepoFileLogic
 */
export interface FilesRepoFileLogic {
    /**
     * 
     * @type {number}
     * @memberof FilesRepoFileLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilesRepoFileLogic
     */
    'filesRepoId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesRepoFileLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilesRepoFileLogic
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilesRepoFileLogic
     */
    'description'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FilesRepoFileLogic
     */
    'uploadedDate'?: Date;
}
/**
 * 
 * @export
 * @interface FilesRepoLogic
 */
export interface FilesRepoLogic {
    /**
     * 
     * @type {number}
     * @memberof FilesRepoLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FilesRepoLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FilesRepoLogic
     */
    'createdDate'?: Date;
}
/**
 * 
 * @export
 * @interface FineTuneInputData
 */
export interface FineTuneInputData {
    /**
     * 
     * @type {string}
     * @memberof FineTuneInputData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FineTuneInputData
     */
    'model'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FineTuneInputData
     */
    'connectionId'?: number;
}
/**
 * 
 * @export
 * @interface FolderLogic
 */
export interface FolderLogic {
    /**
     * 
     * @type {number}
     * @memberof FolderLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FolderLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FolderLogic
     */
    'parentId'?: number | null;
}
/**
 * 
 * @export
 * @interface FollowUpLogic
 */
export interface FollowUpLogic {
    /**
     * 
     * @type {string}
     * @memberof FollowUpLogic
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FollowUpLogic
     */
    'generatedResponse'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FollowUpLogic
     */
    'contentId'?: number;
    /**
     * 
     * @type {ContentType}
     * @memberof FollowUpLogic
     */
    'contentType'?: ContentType;
    /**
     * 
     * @type {Date}
     * @memberof FollowUpLogic
     */
    'generatedDate'?: Date | null;
}


/**
 * 
 * @export
 * @interface ForgotPasswordViewModel
 */
export interface ForgotPasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordViewModel
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface FunctionalInputData
 */
export interface FunctionalInputData {
    /**
     * 
     * @type {ScrapperInputData}
     * @memberof FunctionalInputData
     */
    'scrapperInput'?: ScrapperInputData;
    /**
     * 
     * @type {CombineInputData}
     * @memberof FunctionalInputData
     */
    'combineInput'?: CombineInputData;
    /**
     * 
     * @type {LocatorInputData}
     * @memberof FunctionalInputData
     */
    'locatorInput'?: LocatorInputData;
    /**
     * 
     * @type {SegmentInputData}
     * @memberof FunctionalInputData
     */
    'segmentInput'?: SegmentInputData;
    /**
     * 
     * @type {ValidatorInputData}
     * @memberof FunctionalInputData
     */
    'validatorInput'?: ValidatorInputData;
    /**
     * 
     * @type {TransformerInputData}
     * @memberof FunctionalInputData
     */
    'transformerInput'?: TransformerInputData;
    /**
     * 
     * @type {ExtractorInputData}
     * @memberof FunctionalInputData
     */
    'extractorInput'?: ExtractorInputData;
}
/**
 * 
 * @export
 * @interface GeneratePaymentLinkRequest
 */
export interface GeneratePaymentLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratePaymentLinkRequest
     */
    'product'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneratedLogic
 */
export interface GeneratedLogic {
    /**
     * 
     * @type {number}
     * @memberof GeneratedLogic
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneratedLogic
     */
    'generatedContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneratedLogic
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneratedLogic
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneratedLogic
     */
    'configurations'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GeneratedLogic
     */
    'generatedDate'?: Date | null;
}
/**
 * 
 * @export
 * @interface GetAiFlowRunItemsResponse
 */
export interface GetAiFlowRunItemsResponse {
    /**
     * 
     * @type {{ [key: string]: Array<AiFlowItemLogic>; }}
     * @memberof GetAiFlowRunItemsResponse
     */
    'aiFlowRunsItems'?: { [key: string]: Array<AiFlowItemLogic>; } | null;
}
/**
 * 
 * @export
 * @interface GetAiFlowRunsRequest
 */
export interface GetAiFlowRunsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetAiFlowRunsRequest
     */
    'aiFlowId'?: number;
    /**
     * 
     * @type {AiFlowRunStatus}
     * @memberof GetAiFlowRunsRequest
     */
    'status'?: AiFlowRunStatus;
}


/**
 * 
 * @export
 * @interface GetTemplatesThumbnailRequest
 */
export interface GetTemplatesThumbnailRequest {
    /**
     * 
     * @type {Array<TemplateData>}
     * @memberof GetTemplatesThumbnailRequest
     */
    'templatesData'?: Array<TemplateData> | null;
}
/**
 * 
 * @export
 * @interface GoogleAnalyticsInputData
 */
export interface GoogleAnalyticsInputData {
    /**
     * 
     * @type {Date}
     * @memberof GoogleAnalyticsInputData
     */
    'from'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GoogleAnalyticsInputData
     */
    'to'?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsInputData
     */
    'timeframeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsInputData
     */
    'selectedQuickSelect'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsInputData
     */
    'propertyId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleAnalyticsInputData
     */
    'dimensions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleAnalyticsInputData
     */
    'metrics'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GoogleAnalyticsProcessActionData
 */
export interface GoogleAnalyticsProcessActionData {
    /**
     * 
     * @type {Date}
     * @memberof GoogleAnalyticsProcessActionData
     */
    'from'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GoogleAnalyticsProcessActionData
     */
    'to'?: Date;
    /**
     * 
     * @type {string}
     * @memberof GoogleAnalyticsProcessActionData
     */
    'propertyId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleAnalyticsProcessActionData
     */
    'dimensions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoogleAnalyticsProcessActionData
     */
    'metrics'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GoogleTokenViewModel
 */
export interface GoogleTokenViewModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleTokenViewModel
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface HttpInputData
 */
export interface HttpInputData {
    /**
     * 
     * @type {string}
     * @memberof HttpInputData
     */
    'httpAction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HttpInputData
     */
    'url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HttpInputData
     */
    'authorization'?: boolean | null;
    /**
     * 
     * @type {AuthorizationData}
     * @memberof HttpInputData
     */
    'authorizationData'?: AuthorizationData;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof HttpInputData
     */
    'queryParams'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof HttpInputData
     */
    'body'?: string | null;
}
/**
 * 
 * @export
 * @interface ImportAiFlowRequest
 */
export interface ImportAiFlowRequest {
    /**
     * 
     * @type {AiFlowLogic}
     * @memberof ImportAiFlowRequest
     */
    'aiFlow'?: AiFlowLogic;
}
/**
 * 
 * @export
 * @interface InitialSetupStatusLogic
 */
export interface InitialSetupStatusLogic {
    /**
     * 
     * @type {boolean}
     * @memberof InitialSetupStatusLogic
     */
    'connectLLM'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitialSetupStatusLogic
     */
    'createAiFlow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitialSetupStatusLogic
     */
    'testAction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitialSetupStatusLogic
     */
    'exploreTemplates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitialSetupStatusLogic
     */
    'runAiFlow'?: boolean;
}
/**
 * 
 * @export
 * @interface InputCondition
 */
export interface InputCondition {
    /**
     * 
     * @type {string}
     * @memberof InputCondition
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputCondition
     */
    'property'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputCondition
     */
    'operator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputCondition
     */
    'condition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputCondition
     */
    'conditionValue'?: string | null;
}
/**
 * 
 * @export
 * @interface InputData
 */
export interface InputData {
    /**
     * 
     * @type {string}
     * @memberof InputData
     */
    'inputType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputData
     */
    'input'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputData
     */
    'uploadedFilesNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputData
     */
    'urlFiles'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InputData
     */
    'fileInputType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InputData
     */
    'filesRepoId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InputData
     */
    'filesRepoFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputData
     */
    'webUri'?: string | null;
    /**
     * 
     * @type {InputProviderData}
     * @memberof InputData
     */
    'providerData'?: InputProviderData;
}
/**
 * 
 * @export
 * @interface InputDataLogic
 */
export interface InputDataLogic {
    /**
     * 
     * @type {ActionInputDataLogic}
     * @memberof InputDataLogic
     */
    'actionInputData'?: ActionInputDataLogic;
    /**
     * 
     * @type {string}
     * @memberof InputDataLogic
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface InputProviderData
 */
export interface InputProviderData {
    /**
     * 
     * @type {string}
     * @memberof InputProviderData
     */
    'provider'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InputProviderData
     */
    'connectionId'?: number | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof InputProviderData
     */
    'params'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof InputProviderData
     */
    'method'?: string | null;
    /**
     * 
     * @type {GoogleAnalyticsInputData}
     * @memberof InputProviderData
     */
    'googleAnalyticsInputData'?: GoogleAnalyticsInputData;
    /**
     * 
     * @type {object}
     * @memberof InputProviderData
     */
    'hubSpotInputData'?: object;
    /**
     * 
     * @type {MailchimpInputData}
     * @memberof InputProviderData
     */
    'mailchimpInputData'?: MailchimpInputData;
    /**
     * 
     * @type {object}
     * @memberof InputProviderData
     */
    'salesforceInputData'?: object;
    /**
     * 
     * @type {object}
     * @memberof InputProviderData
     */
    'klaviyoInputData'?: object;
}
/**
 * 
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'integrationLabel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'integrationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    'integrationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'visible'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'invalid'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Integration
     */
    'canOnlyBeUsedIn'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Integration
     */
    'newBrowserWindow'?: boolean | null;
}
/**
 * 
 * @export
 * @interface IntegrationGroupLogic
 */
export interface IntegrationGroupLogic {
    /**
     * 
     * @type {string}
     * @memberof IntegrationGroupLogic
     */
    'label'?: string | null;
    /**
     * 
     * @type {Array<Integration>}
     * @memberof IntegrationGroupLogic
     */
    'integrations'?: Array<Integration> | null;
}
/**
 * 
 * @export
 * @interface InviteUserData
 */
export interface InviteUserData {
    /**
     * 
     * @type {number}
     * @memberof InviteUserData
     */
    'organizationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InviteUserData
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUserData
     */
    'invitedUserEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface InviteUserRequest
 */
export interface InviteUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteUserRequest
     */
    'invitedEmails'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LanguageViewModel
 */
export interface LanguageViewModel {
    /**
     * 
     * @type {string}
     * @memberof LanguageViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageViewModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface LlmInputData
 */
export interface LlmInputData {
    /**
     * 
     * @type {FineTuneInputData}
     * @memberof LlmInputData
     */
    'fineTuneInputData'?: FineTuneInputData;
    /**
     * 
     * @type {EmbeddingInputData}
     * @memberof LlmInputData
     */
    'embeddingInputData'?: EmbeddingInputData;
}
/**
 * 
 * @export
 * @interface LlmModelUsage
 */
export interface LlmModelUsage {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof LlmModelUsage
     */
    'groupedLlmModelsUsage'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {number}
     * @memberof LlmModelUsage
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface LocatorInputData
 */
export interface LocatorInputData {
    /**
     * 
     * @type {string}
     * @memberof LocatorInputData
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocatorInputData
     */
    'fileSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocatorInputData
     */
    'locatorType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocatorInputData
     */
    'locateBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocatorInputData
     */
    'selectedElementsSourceType'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocatorInputData
     */
    'elements'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LocatorInputData
     */
    'selectedElementsSource'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LoginResult = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type LoginResult = typeof LoginResult[keyof typeof LoginResult];


/**
 * 
 * @export
 * @interface LoginViewModel
 */
export interface LoginViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoopSettings
 */
export interface LoopSettings {
    /**
     * 
     * @type {string}
     * @memberof LoopSettings
     */
    'loopType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoopSettings
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoopSettings
     */
    'referenceKeyword'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoopSettings
     */
    'count'?: number | null;
}
/**
 * 
 * @export
 * @interface MailChimpProviderTriggerData
 */
export interface MailChimpProviderTriggerData {
    /**
     * 
     * @type {string}
     * @memberof MailChimpProviderTriggerData
     */
    'listId'?: string | null;
}
/**
 * 
 * @export
 * @interface MailchimpInputData
 */
export interface MailchimpInputData {
    /**
     * 
     * @type {Date}
     * @memberof MailchimpInputData
     */
    'from'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MailchimpInputData
     */
    'to'?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpInputData
     */
    'timeframeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpInputData
     */
    'selectedQuickSelect'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpInputData
     */
    'method'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpInputData
     */
    'campaignId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpInputData
     */
    'listId'?: string | null;
}
/**
 * 
 * @export
 * @interface MailchimpProcessActionData
 */
export interface MailchimpProcessActionData {
    /**
     * 
     * @type {Date}
     * @memberof MailchimpProcessActionData
     */
    'from'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailchimpProcessActionData
     */
    'to'?: Date;
    /**
     * 
     * @type {string}
     * @memberof MailchimpProcessActionData
     */
    'method'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpProcessActionData
     */
    'campaignId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailchimpProcessActionData
     */
    'listId'?: string | null;
}
/**
 * 
 * @export
 * @interface ManualValidationRequest
 */
export interface ManualValidationRequest {
    /**
     * 
     * @type {number}
     * @memberof ManualValidationRequest
     */
    'runId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManualValidationRequest
     */
    'validationDecision'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualValidationRequest
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface ManualValidationResponse
 */
export interface ManualValidationResponse {
    /**
     * 
     * @type {AiFlowRunOutputLogic}
     * @memberof ManualValidationResponse
     */
    'output'?: AiFlowRunOutputLogic;
    /**
     * 
     * @type {AiFlowRunLogic}
     * @memberof ManualValidationResponse
     */
    'run'?: AiFlowRunLogic;
}
/**
 * 
 * @export
 * @interface Method
 */
export interface Method {
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof Method
     */
    'get'?: Array<MethodInfo> | null;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof Method
     */
    'post'?: Array<MethodInfo> | null;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof Method
     */
    'put'?: Array<MethodInfo> | null;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof Method
     */
    'delete'?: Array<MethodInfo> | null;
}
/**
 * 
 * @export
 * @interface MethodInfo
 */
export interface MethodInfo {
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface NameId
 */
export interface NameId {
    /**
     * 
     * @type {string}
     * @memberof NameId
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameId
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationLogic
 */
export interface NotificationLogic {
    /**
     * 
     * @type {number}
     * @memberof NotificationLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationLogic
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationLogic
     */
    'read'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationLogic
     */
    'parameters'?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof NotificationLogic
     */
    'createdAt'?: Date;
}
/**
 * 
 * @export
 * @interface NotificationSettingsLogic
 */
export interface NotificationSettingsLogic {
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingsLogic
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsLogic
     */
    'flowBegin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsLogic
     */
    'flowCompleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsLogic
     */
    'flowFail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsLogic
     */
    'updates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettingsLogic
     */
    'newsletters'?: boolean;
}
/**
 * 
 * @export
 * @interface OauthDetails
 */
export interface OauthDetails {
    /**
     * 
     * @type {string}
     * @memberof OauthDetails
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OauthDetails
     */
    'refreshToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OauthDetails
     */
    'dc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OauthDetails
     */
    'account'?: string | null;
}
/**
 * 
 * @export
 * @interface OperationsInputData
 */
export interface OperationsInputData {
    /**
     * 
     * @type {string}
     * @memberof OperationsInputData
     */
    'operationsSource'?: string | null;
    /**
     * 
     * @type {InputProviderData}
     * @memberof OperationsInputData
     */
    'providerData'?: InputProviderData;
}
/**
 * 
 * @export
 * @interface OutputCommentLogic
 */
export interface OutputCommentLogic {
    /**
     * 
     * @type {number}
     * @memberof OutputCommentLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OutputCommentLogic
     */
    'commenterName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputCommentLogic
     */
    'commenterAvatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputCommentLogic
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OutputCommentLogic
     */
    'createdAt'?: Date;
    /**
     * 
     * @type {number}
     * @memberof OutputCommentLogic
     */
    'aiFlowRunOutputId'?: number;
}
/**
 * 
 * @export
 * @interface OutputRowData
 */
export interface OutputRowData {
    /**
     * 
     * @type {AiFlowItemLogic}
     * @memberof OutputRowData
     */
    'item'?: AiFlowItemLogic;
    /**
     * 
     * @type {AiFlowRunOutputLogic}
     * @memberof OutputRowData
     */
    'output'?: AiFlowRunOutputLogic;
}
/**
 * 
 * @export
 * @interface PasswordValidationViewModel
 */
export interface PasswordValidationViewModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordValidationViewModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordValidationViewModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface PauseGenerateContentRequest
 */
export interface PauseGenerateContentRequest {
    /**
     * 
     * @type {number}
     * @memberof PauseGenerateContentRequest
     */
    'generatedId'?: number;
}
/**
 * 
 * @export
 * @interface PersonaLogic
 */
export interface PersonaLogic {
    /**
     * 
     * @type {number}
     * @memberof PersonaLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonaLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonaLogic
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonaLogic
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonaLogic
     */
    'modelName'?: string | null;
}
/**
 * 
 * @export
 * @interface ProcessProviderActionRequest
 */
export interface ProcessProviderActionRequest {
    /**
     * 
     * @type {string}
     * @memberof ProcessProviderActionRequest
     */
    'provider'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProcessProviderActionRequest
     */
    'connectionId'?: number | null;
    /**
     * 
     * @type {SlackProcessActionData}
     * @memberof ProcessProviderActionRequest
     */
    'slackProcessActionData'?: SlackProcessActionData;
    /**
     * 
     * @type {GoogleAnalyticsProcessActionData}
     * @memberof ProcessProviderActionRequest
     */
    'googleAnalyticsProcessActionData'?: GoogleAnalyticsProcessActionData;
    /**
     * 
     * @type {MailchimpProcessActionData}
     * @memberof ProcessProviderActionRequest
     */
    'mailchimpProcessActionData'?: MailchimpProcessActionData;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ProcessProviderActionRequest
     */
    'params'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof ProcessProviderActionRequest
     */
    'inputData'?: string | null;
}
/**
 * 
 * @export
 * @interface PromptFollowUpRequestLogic
 */
export interface PromptFollowUpRequestLogic {
    /**
     * 
     * @type {number}
     * @memberof PromptFollowUpRequestLogic
     */
    'orgId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PromptFollowUpRequestLogic
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PromptFollowUpRequestLogic
     */
    'contentId'?: number;
    /**
     * 
     * @type {ContentType}
     * @memberof PromptFollowUpRequestLogic
     */
    'contentType'?: ContentType;
}


/**
 * 
 * @export
 * @interface PromptFollowUpResponseLogic
 */
export interface PromptFollowUpResponseLogic {
    /**
     * 
     * @type {string}
     * @memberof PromptFollowUpResponseLogic
     */
    'content'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PromptFollowUpResponseLogic
     */
    'contentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromptFollowUpResponseLogic
     */
    'actionType'?: string | null;
    /**
     * 
     * @type {ContentType}
     * @memberof PromptFollowUpResponseLogic
     */
    'contentType'?: ContentType;
}


/**
 * 
 * @export
 * @interface PromptLogic
 */
export interface PromptLogic {
    /**
     * 
     * @type {number}
     * @memberof PromptLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PromptLogic
     */
    'promptRepositoryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromptLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromptLogic
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromptLogic
     */
    'userPrompt'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PromptLogic
     */
    'createdAt'?: Date;
    /**
     * 
     * @type {string}
     * @memberof PromptLogic
     */
    'createdByUser'?: string | null;
}
/**
 * 
 * @export
 * @interface PromptRepositoryLogic
 */
export interface PromptRepositoryLogic {
    /**
     * 
     * @type {number}
     * @memberof PromptRepositoryLogic
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PromptRepositoryLogic
     */
    'name'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PromptRepositoryLogic
     */
    'createdAt'?: Date;
}
/**
 * 
 * @export
 * @interface ProviderMethod
 */
export interface ProviderMethod {
    /**
     * 
     * @type {string}
     * @memberof ProviderMethod
     */
    'provider'?: string | null;
    /**
     * 
     * @type {Array<Method>}
     * @memberof ProviderMethod
     */
    'methods'?: Array<Method> | null;
}
/**
 * 
 * @export
 * @interface ProviderTriggerData
 */
export interface ProviderTriggerData {
    /**
     * 
     * @type {string}
     * @memberof ProviderTriggerData
     */
    'provider'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProviderTriggerData
     */
    'webhookEvents'?: Array<string> | null;
    /**
     * 
     * @type {MailChimpProviderTriggerData}
     * @memberof ProviderTriggerData
     */
    'mailChimpProviderTriggerData'?: MailChimpProviderTriggerData;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'hubSpotProviderTriggerData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'slackProviderTriggerData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'googleAnalyticsTriggerData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'googleSheetsTriggerData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'googleDriveTriggerData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'googleCalendarTriggerData'?: object;
    /**
     * 
     * @type {object}
     * @memberof ProviderTriggerData
     */
    'googleGmailTriggerData'?: object;
    /**
     * 
     * @type {number}
     * @memberof ProviderTriggerData
     */
    'connectionId'?: number | null;
}
/**
 * 
 * @export
 * @interface RecentOutputLogic
 */
export interface RecentOutputLogic {
    /**
     * 
     * @type {string}
     * @memberof RecentOutputLogic
     */
    'output'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecentOutputLogic
     */
    'runNumber'?: number;
    /**
     * 
     * @type {AiFlowItemLogic}
     * @memberof RecentOutputLogic
     */
    'item'?: AiFlowItemLogic;
    /**
     * 
     * @type {Date}
     * @memberof RecentOutputLogic
     */
    'processedDate'?: Date;
}
/**
 * 
 * @export
 * @interface RegisterViewModel
 */
export interface RegisterViewModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterViewModel
     */
    'organizationId'?: number | null;
}
/**
 * 
 * @export
 * @interface RemoveAiFlowFolderRequest
 */
export interface RemoveAiFlowFolderRequest {
    /**
     * 
     * @type {AiFlowFolderLogic}
     * @memberof RemoveAiFlowFolderRequest
     */
    'aiFlowFolder'?: AiFlowFolderLogic;
}
/**
 * 
 * @export
 * @interface RenameTemplateRequest
 */
export interface RenameTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof RenameTemplateRequest
     */
    'templateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RenameTemplateRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ResetPasswordViewModel
 */
export interface ResetPasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface RunAiFlowRequest
 */
export interface RunAiFlowRequest {
    /**
     * 
     * @type {number}
     * @memberof RunAiFlowRequest
     */
    'aiFlowId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunAiFlowRequest
     */
    'aiFlowVersionId'?: number | null;
    /**
     * 
     * @type {Array<AiFlowItemLogic>}
     * @memberof RunAiFlowRequest
     */
    'items'?: Array<AiFlowItemLogic> | null;
}
/**
 * 
 * @export
 * @interface RunOutput
 */
export interface RunOutput {
    /**
     * 
     * @type {boolean}
     * @memberof RunOutput
     */
    'file'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunOutput
     */
    'results'?: Array<string> | null;
    /**
     * 
     * @type {Array<RunOutputInfo>}
     * @memberof RunOutput
     */
    'outputsInfo'?: Array<RunOutputInfo> | null;
}
/**
 * 
 * @export
 * @interface RunOutputInfo
 */
export interface RunOutputInfo {
    /**
     * 
     * @type {string}
     * @memberof RunOutputInfo
     */
    'completePrompt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RunOutputInfo
     */
    'inputTokenCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RunOutputInfo
     */
    'outputTokenCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RunOutputInfo
     */
    'modelProvider'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RunOutputInfo
     */
    'modelName'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RunOutputInfo
     */
    'processedDate'?: Date;
    /**
     * 
     * @type {number}
     * @memberof RunOutputInfo
     */
    'processTime'?: number;
}
/**
 * 
 * @export
 * @interface RunsUsage
 */
export interface RunsUsage {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof RunsUsage
     */
    'groupedRunsUsage'?: { [key: string]: { [key: string]: number; }; } | null;
}
/**
 * 
 * @export
 * @interface SaveAiFlowItemResponse
 */
export interface SaveAiFlowItemResponse {
    /**
     * 
     * @type {Array<AiFlowItemLogic>}
     * @memberof SaveAiFlowItemResponse
     */
    'items'?: Array<AiFlowItemLogic> | null;
}
/**
 * 
 * @export
 * @interface SaveTemplateRequest
 */
export interface SaveTemplateRequest {
    /**
     * 
     * @type {Template}
     * @memberof SaveTemplateRequest
     */
    'template'?: Template;
}
/**
 * 
 * @export
 * @interface SaveTemplatesRequest
 */
export interface SaveTemplatesRequest {
    /**
     * 
     * @type {Array<TemplateData>}
     * @memberof SaveTemplatesRequest
     */
    'templatesData'?: Array<TemplateData> | null;
}
/**
 * 
 * @export
 * @interface ScrapperInputData
 */
export interface ScrapperInputData {
    /**
     * 
     * @type {string}
     * @memberof ScrapperInputData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScrapperInputData
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScrapperInputData
     */
    'sourceType'?: string | null;
}
/**
 * 
 * @export
 * @interface SegmentInputData
 */
export interface SegmentInputData {
    /**
     * 
     * @type {string}
     * @memberof SegmentInputData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInputData
     */
    'sourceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInputData
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInputData
     */
    'segmentBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInputData
     */
    'take'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentInputData
     */
    'takeCustom'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SegmentInputData
     */
    'skip'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SegmentInputData
     */
    'skipCustom'?: number | null;
}
/**
 * 
 * @export
 * @interface SendChangePasswordVerificationRequest
 */
export interface SendChangePasswordVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendChangePasswordVerificationRequest
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendChangePasswordVerificationRequest
     */
    'newPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface SendEmailVerificationRequest
 */
export interface SendEmailVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendEmailVerificationRequest
     */
    'newEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface SendNewAccountEmailVerificationRequest
 */
export interface SendNewAccountEmailVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendNewAccountEmailVerificationRequest
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface SlackProcessActionData
 */
export interface SlackProcessActionData {
    /**
     * 
     * @type {string}
     * @memberof SlackProcessActionData
     */
    'channel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SlackProcessActionData
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SlackProcessActionData
     */
    'message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SlackProcessActionData
     */
    'connectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SlackProcessActionData
     */
    'actionType'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionTier
 */
export interface SubscriptionTier {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTier
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTier
     */
    'monthlyCreditUnits'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTier
     */
    'maxUsers'?: number;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {number}
     * @memberof Template
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'thumbnail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'providerName'?: string | null;
}
/**
 * 
 * @export
 * @interface TemplateData
 */
export interface TemplateData {
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateData
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface TemplateViewModel
 */
export interface TemplateViewModel {
    /**
     * 
     * @type {number}
     * @memberof TemplateViewModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateViewModel
     */
    'thumbnail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateViewModel
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateViewModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateViewModel
     */
    'providerName'?: string | null;
}
/**
 * 
 * @export
 * @interface TestActionRequest
 */
export interface TestActionRequest {
    /**
     * 
     * @type {string}
     * @memberof TestActionRequest
     */
    'prompt': string;
    /**
     * 
     * @type {ConfigurationLogic}
     * @memberof TestActionRequest
     */
    'configurations'?: ConfigurationLogic;
    /**
     * 
     * @type {string}
     * @memberof TestActionRequest
     */
    'header': string;
}
/**
 * 
 * @export
 * @interface TransformerInputData
 */
export interface TransformerInputData {
    /**
     * 
     * @type {string}
     * @memberof TransformerInputData
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformerInputData
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformerInputData
     */
    'transform'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformerInputData
     */
    'transformInput'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TranslateModels = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type TranslateModels = typeof TranslateModels[keyof typeof TranslateModels];


/**
 * 
 * @export
 * @interface TriggerData
 */
export interface TriggerData {
    /**
     * 
     * @type {string}
     * @memberof TriggerData
     */
    'triggerType'?: string | null;
    /**
     * 
     * @type {ProviderTriggerData}
     * @memberof TriggerData
     */
    'providerTriggerData'?: ProviderTriggerData;
    /**
     * 
     * @type {WebhookTriggerData}
     * @memberof TriggerData
     */
    'webhookTriggerData'?: WebhookTriggerData;
}
/**
 * 
 * @export
 * @interface UpdateAiFlowItemNoteRequest
 */
export interface UpdateAiFlowItemNoteRequest {
    /**
     * 
     * @type {AiFlowItemNoteLogic}
     * @memberof UpdateAiFlowItemNoteRequest
     */
    'aiFlowItemNote'?: AiFlowItemNoteLogic;
}
/**
 * 
 * @export
 * @interface UpdateInitialSetupRequest
 */
export interface UpdateInitialSetupRequest {
    /**
     * 
     * @type {InitialSetupStatusLogic}
     * @memberof UpdateInitialSetupRequest
     */
    'initialSetupStatus'?: InitialSetupStatusLogic;
}
/**
 * 
 * @export
 * @interface UpdateNotificationsRequest
 */
export interface UpdateNotificationsRequest {
    /**
     * 
     * @type {Array<NotificationLogic>}
     * @memberof UpdateNotificationsRequest
     */
    'notifications'?: Array<NotificationLogic> | null;
}
/**
 * 
 * @export
 * @interface UpdateOutputCommentRequest
 */
export interface UpdateOutputCommentRequest {
    /**
     * 
     * @type {OutputCommentLogic}
     * @memberof UpdateOutputCommentRequest
     */
    'outputComment'?: OutputCommentLogic;
}
/**
 * 
 * @export
 * @interface UpdateSubscriptionPlanRequest
 */
export interface UpdateSubscriptionPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSubscriptionPlanRequest
     */
    'productName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {UserLogic}
     * @memberof UpdateUserRequest
     */
    'user'?: UserLogic;
}
/**
 * 
 * @export
 * @interface UpdateUserSettingsRequest
 */
export interface UpdateUserSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserSettingsRequest
     */
    'selectedContentGenerationSettingsId'?: number | null;
}
/**
 * 
 * @export
 * @interface UploadedFileLogic
 */
export interface UploadedFileLogic {
    /**
     * 
     * @type {string}
     * @memberof UploadedFileLogic
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileLogic
     */
    'fileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertAiFlowItemRequest
 */
export interface UpsertAiFlowItemRequest {
    /**
     * 
     * @type {AiFlowItemLogic}
     * @memberof UpsertAiFlowItemRequest
     */
    'aiFlowItem'?: AiFlowItemLogic;
}
/**
 * 
 * @export
 * @interface UpsertAiFlowRequest
 */
export interface UpsertAiFlowRequest {
    /**
     * 
     * @type {AiFlowLogic}
     * @memberof UpsertAiFlowRequest
     */
    'aiFlow'?: AiFlowLogic;
    /**
     * 
     * @type {number}
     * @memberof UpsertAiFlowRequest
     */
    'selectedAiFlowVersionId'?: number;
}
/**
 * 
 * @export
 * @interface UpsertBrandVoiceRequest
 */
export interface UpsertBrandVoiceRequest {
    /**
     * 
     * @type {BrandVoiceLogic}
     * @memberof UpsertBrandVoiceRequest
     */
    'brandVoice'?: BrandVoiceLogic;
}
/**
 * 
 * @export
 * @interface UpsertConfigurationRequest
 */
export interface UpsertConfigurationRequest {
    /**
     * 
     * @type {ConfigurationLogic}
     * @memberof UpsertConfigurationRequest
     */
    'configuration'?: ConfigurationLogic;
}
/**
 * 
 * @export
 * @interface UpsertConnectionRequest
 */
export interface UpsertConnectionRequest {
    /**
     * 
     * @type {ConnectionLogic}
     * @memberof UpsertConnectionRequest
     */
    'connection'?: ConnectionLogic;
}
/**
 * 
 * @export
 * @interface UpsertContentSnippetRequest
 */
export interface UpsertContentSnippetRequest {
    /**
     * 
     * @type {number}
     * @memberof UpsertContentSnippetRequest
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertContentSnippetRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertContentSnippetRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertContentSnippetRequest
     */
    'shortcut'?: number | null;
}
/**
 * 
 * @export
 * @interface UpsertFolderRequest
 */
export interface UpsertFolderRequest {
    /**
     * 
     * @type {FolderLogic}
     * @memberof UpsertFolderRequest
     */
    'folder'?: FolderLogic;
}
/**
 * 
 * @export
 * @interface UpsertPersonaRequest
 */
export interface UpsertPersonaRequest {
    /**
     * 
     * @type {PersonaLogic}
     * @memberof UpsertPersonaRequest
     */
    'persona'?: PersonaLogic;
}
/**
 * 
 * @export
 * @interface UpsertScheduleInfoRequest
 */
export interface UpsertScheduleInfoRequest {
    /**
     * 
     * @type {AiFlowScheduleInfoLogic}
     * @memberof UpsertScheduleInfoRequest
     */
    'scheduleInfo'?: AiFlowScheduleInfoLogic;
}
/**
 * 
 * @export
 * @interface UserContentSnippet
 */
export interface UserContentSnippet {
    /**
     * 
     * @type {number}
     * @memberof UserContentSnippet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserContentSnippet
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserContentSnippet
     */
    'content'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserContentSnippet
     */
    'shortcut'?: number | null;
}
/**
 * 
 * @export
 * @interface UserGeneralInfo
 */
export interface UserGeneralInfo {
    /**
     * 
     * @type {string}
     * @memberof UserGeneralInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGeneralInfo
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGeneralInfo
     */
    'avatar'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLogic
 */
export interface UserLogic {
    /**
     * 
     * @type {UserGeneralInfo}
     * @memberof UserLogic
     */
    'generalInfo'?: UserGeneralInfo;
}
/**
 * 
 * @export
 * @interface UserProfileLogic
 */
export interface UserProfileLogic {
    /**
     * 
     * @type {string}
     * @memberof UserProfileLogic
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileLogic
     */
    'lastSignIn'?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLogic
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileLogic
     */
    'loginExternal'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLogic
     */
    'loginProvider'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLogic
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLogic
     */
    'organizationName'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSettingsViewModel
 */
export interface UserSettingsViewModel {
    /**
     * 
     * @type {number}
     * @memberof UserSettingsViewModel
     */
    'selectedContentGenerationSettingsId'?: number | null;
}
/**
 * 
 * @export
 * @interface ValidatorInputData
 */
export interface ValidatorInputData {
    /**
     * 
     * @type {string}
     * @memberof ValidatorInputData
     */
    'validatorType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidatorInputData
     */
    'automaticValidationType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidatorInputData
     */
    'manualValidationType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidatorInputData
     */
    'manualValidationDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidatorInputData
     */
    'targetSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidatorInputData
     */
    'expectedSource'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequest
     */
    'payload'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VerifyOperation = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type VerifyOperation = typeof VerifyOperation[keyof typeof VerifyOperation];


/**
 * 
 * @export
 * @interface VerifyResponse
 */
export interface VerifyResponse {
    /**
     * 
     * @type {VerifyOperation}
     * @memberof VerifyResponse
     */
    'verifyOperation'?: VerifyOperation;
}


/**
 * 
 * @export
 * @interface WebhookTriggerData
 */
export interface WebhookTriggerData {
    /**
     * 
     * @type {string}
     * @memberof WebhookTriggerData
     */
    'eventType'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookTriggerData
     */
    'customHeaders'?: Array<string> | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactUsRequest} [contactUsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountContactUsPost: async (contactUsRequest?: ContactUsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/contact-us`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactUsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEmailExistEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiAccountEmailExistEmailGet', 'email', email)
            const localVarPath = `/api/Account/email-exist/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {string} [remoteError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountExternalLoginCallbackGet: async (returnUrl?: string, remoteError?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/external-login-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }

            if (remoteError !== undefined) {
                localVarQueryParameter['remoteError'] = remoteError;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountExternalLoginGet: async (provider?: string, returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/external-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost: async (forgotPasswordViewModel?: ForgotPasswordViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GoogleTokenViewModel} [googleTokenViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGooglePost: async (googleTokenViewModel?: GoogleTokenViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleTokenViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost: async (loginViewModel?: LoginViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserProfileLogic} [userProfileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountProfilePost: async (userProfileLogic?: UserProfileLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterViewModel} [registerViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost: async (registerViewModel?: RegisterViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendNewAccountEmailVerificationRequest} [sendNewAccountEmailVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResendNewAccountEmailVerificationPost: async (sendNewAccountEmailVerificationRequest?: SendNewAccountEmailVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/resend-new-account-email-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendNewAccountEmailVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetPasswordPost: async (resetPasswordViewModel?: ResetPasswordViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendEmailVerificationRequest} [sendEmailVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSendEmailVerificationPost: async (sendEmailVerificationRequest?: SendEmailVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/send-email-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendChangePasswordVerificationRequest} [sendChangePasswordVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSendPasswordVerificationPost: async (sendChangePasswordVerificationRequest?: SendChangePasswordVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/send-password-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendChangePasswordVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordValidationViewModel} [passwordValidationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountTokenValidityPost: async (passwordValidationViewModel?: PasswordValidationViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/token-validity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordValidationViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [avatarFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUploadAvatarPost: async (avatarFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/upload-avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (avatarFile !== undefined) { 
                localVarFormParams.append('AvatarFile', avatarFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountVerifyEmailPost: async (verifyEmailRequest?: VerifyEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountVerifyNewAccountEmailPost: async (verifyEmailRequest?: VerifyEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/verify-new-account-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountVerifyPasswordPost: async (verifyEmailRequest?: VerifyEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/verify-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactUsRequest} [contactUsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountContactUsPost(contactUsRequest?: ContactUsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountContactUsPost(contactUsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountEmailExistEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountEmailExistEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {string} [remoteError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountExternalLoginCallbackGet(returnUrl?: string, remoteError?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountExternalLoginCallbackGet(returnUrl, remoteError, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountExternalLoginGet(provider?: string, returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountExternalLoginGet(provider, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountForgotPasswordPost(forgotPasswordViewModel?: ForgotPasswordViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountForgotPasswordPost(forgotPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GoogleTokenViewModel} [googleTokenViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountGooglePost(googleTokenViewModel?: GoogleTokenViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountGooglePost(googleTokenViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLoginPost(loginViewModel?: LoginViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLoginPost(loginViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserProfileLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserProfileLogic} [userProfileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountProfilePost(userProfileLogic?: UserProfileLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountProfilePost(userProfileLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterViewModel} [registerViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRegisterPost(registerViewModel?: RegisterViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRegisterPost(registerViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendNewAccountEmailVerificationRequest} [sendNewAccountEmailVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountResendNewAccountEmailVerificationPost(sendNewAccountEmailVerificationRequest?: SendNewAccountEmailVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountResendNewAccountEmailVerificationPost(sendNewAccountEmailVerificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountResetPasswordPost(resetPasswordViewModel?: ResetPasswordViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountResetPasswordPost(resetPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendEmailVerificationRequest} [sendEmailVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountSendEmailVerificationPost(sendEmailVerificationRequest?: SendEmailVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountSendEmailVerificationPost(sendEmailVerificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendChangePasswordVerificationRequest} [sendChangePasswordVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountSendPasswordVerificationPost(sendChangePasswordVerificationRequest?: SendChangePasswordVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountSendPasswordVerificationPost(sendChangePasswordVerificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordValidationViewModel} [passwordValidationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountTokenValidityPost(passwordValidationViewModel?: PasswordValidationViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountTokenValidityPost(passwordValidationViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [avatarFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUploadAvatarPost(avatarFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUploadAvatarPost(avatarFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountVerifyEmailPost(verifyEmailRequest?: VerifyEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountVerifyEmailPost(verifyEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountVerifyNewAccountEmailPost(verifyEmailRequest?: VerifyEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountVerifyNewAccountEmailPost(verifyEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountVerifyPasswordPost(verifyEmailRequest?: VerifyEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountVerifyPasswordPost(verifyEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactUsRequest} [contactUsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountContactUsPost(contactUsRequest?: ContactUsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountContactUsPost(contactUsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEmailExistEmailGet(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountEmailExistEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {string} [remoteError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountExternalLoginCallbackGet(returnUrl?: string, remoteError?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountExternalLoginCallbackGet(returnUrl, remoteError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountExternalLoginGet(provider?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountExternalLoginGet(provider, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost(forgotPasswordViewModel?: ForgotPasswordViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountForgotPasswordPost(forgotPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GoogleTokenViewModel} [googleTokenViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGooglePost(googleTokenViewModel?: GoogleTokenViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountGooglePost(googleTokenViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(loginViewModel?: LoginViewModel, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.apiAccountLoginPost(loginViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountProfileGet(options?: any): AxiosPromise<Array<UserProfileLogic>> {
            return localVarFp.apiAccountProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserProfileLogic} [userProfileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountProfilePost(userProfileLogic?: UserProfileLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountProfilePost(userProfileLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterViewModel} [registerViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(registerViewModel?: RegisterViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountRegisterPost(registerViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendNewAccountEmailVerificationRequest} [sendNewAccountEmailVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResendNewAccountEmailVerificationPost(sendNewAccountEmailVerificationRequest?: SendNewAccountEmailVerificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountResendNewAccountEmailVerificationPost(sendNewAccountEmailVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetPasswordPost(resetPasswordViewModel?: ResetPasswordViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountResetPasswordPost(resetPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendEmailVerificationRequest} [sendEmailVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSendEmailVerificationPost(sendEmailVerificationRequest?: SendEmailVerificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountSendEmailVerificationPost(sendEmailVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendChangePasswordVerificationRequest} [sendChangePasswordVerificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSendPasswordVerificationPost(sendChangePasswordVerificationRequest?: SendChangePasswordVerificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountSendPasswordVerificationPost(sendChangePasswordVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordValidationViewModel} [passwordValidationViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountTokenValidityPost(passwordValidationViewModel?: PasswordValidationViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountTokenValidityPost(passwordValidationViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [avatarFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUploadAvatarPost(avatarFile?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUploadAvatarPost(avatarFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountVerifyEmailPost(verifyEmailRequest?: VerifyEmailRequest, options?: any): AxiosPromise<VerifyResponse> {
            return localVarFp.apiAccountVerifyEmailPost(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountVerifyNewAccountEmailPost(verifyEmailRequest?: VerifyEmailRequest, options?: any): AxiosPromise<VerifyResponse> {
            return localVarFp.apiAccountVerifyNewAccountEmailPost(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailRequest} [verifyEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountVerifyPasswordPost(verifyEmailRequest?: VerifyEmailRequest, options?: any): AxiosPromise<VerifyResponse> {
            return localVarFp.apiAccountVerifyPasswordPost(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {ContactUsRequest} [contactUsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountContactUsPost(contactUsRequest?: ContactUsRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountContactUsPost(contactUsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountEmailExistEmailGet(email: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountEmailExistEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {string} [remoteError] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountExternalLoginCallbackGet(returnUrl?: string, remoteError?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountExternalLoginCallbackGet(returnUrl, remoteError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [provider] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountExternalLoginGet(provider?: string, returnUrl?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountExternalLoginGet(provider, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountForgotPasswordPost(forgotPasswordViewModel?: ForgotPasswordViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountForgotPasswordPost(forgotPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GoogleTokenViewModel} [googleTokenViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountGooglePost(googleTokenViewModel?: GoogleTokenViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountGooglePost(googleTokenViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginViewModel} [loginViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLoginPost(loginViewModel?: LoginViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLoginPost(loginViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLogoutPost(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountProfileGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountProfileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserProfileLogic} [userProfileLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountProfilePost(userProfileLogic?: UserProfileLogic, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountProfilePost(userProfileLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterViewModel} [registerViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRegisterPost(registerViewModel?: RegisterViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRegisterPost(registerViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendNewAccountEmailVerificationRequest} [sendNewAccountEmailVerificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountResendNewAccountEmailVerificationPost(sendNewAccountEmailVerificationRequest?: SendNewAccountEmailVerificationRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountResendNewAccountEmailVerificationPost(sendNewAccountEmailVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountResetPasswordPost(resetPasswordViewModel?: ResetPasswordViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountResetPasswordPost(resetPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendEmailVerificationRequest} [sendEmailVerificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountSendEmailVerificationPost(sendEmailVerificationRequest?: SendEmailVerificationRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountSendEmailVerificationPost(sendEmailVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendChangePasswordVerificationRequest} [sendChangePasswordVerificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountSendPasswordVerificationPost(sendChangePasswordVerificationRequest?: SendChangePasswordVerificationRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountSendPasswordVerificationPost(sendChangePasswordVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordValidationViewModel} [passwordValidationViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountTokenValidityPost(passwordValidationViewModel?: PasswordValidationViewModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountTokenValidityPost(passwordValidationViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [avatarFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUploadAvatarPost(avatarFile?: File, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUploadAvatarPost(avatarFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailRequest} [verifyEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountVerifyEmailPost(verifyEmailRequest?: VerifyEmailRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountVerifyEmailPost(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailRequest} [verifyEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountVerifyNewAccountEmailPost(verifyEmailRequest?: VerifyEmailRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountVerifyNewAccountEmailPost(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailRequest} [verifyEmailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountVerifyPasswordPost(verifyEmailRequest?: VerifyEmailRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountVerifyPasswordPost(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountOnboardingApi - axios parameter creator
 * @export
 */
export const AccountOnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOnboardingOnboardingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccountOnboarding/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountOnBoardingLogic} [accountOnBoardingLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOnboardingOnboardingPost: async (accountOnBoardingLogic?: AccountOnBoardingLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccountOnboarding/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountOnBoardingLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountOnboardingApi - functional programming interface
 * @export
 */
export const AccountOnboardingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountOnboardingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOnboardingOnboardingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOnboardingOnboardingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccountOnBoardingLogic} [accountOnBoardingLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOnboardingOnboardingPost(accountOnBoardingLogic?: AccountOnBoardingLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOnboardingOnboardingPost(accountOnBoardingLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountOnboardingApi - factory interface
 * @export
 */
export const AccountOnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountOnboardingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOnboardingOnboardingGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountOnboardingOnboardingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountOnBoardingLogic} [accountOnBoardingLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOnboardingOnboardingPost(accountOnBoardingLogic?: AccountOnBoardingLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountOnboardingOnboardingPost(accountOnBoardingLogic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountOnboardingApi - object-oriented interface
 * @export
 * @class AccountOnboardingApi
 * @extends {BaseAPI}
 */
export class AccountOnboardingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOnboardingApi
     */
    public apiAccountOnboardingOnboardingGet(options?: AxiosRequestConfig) {
        return AccountOnboardingApiFp(this.configuration).apiAccountOnboardingOnboardingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountOnBoardingLogic} [accountOnBoardingLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountOnboardingApi
     */
    public apiAccountOnboardingOnboardingPost(accountOnBoardingLogic?: AccountOnBoardingLogic, options?: AxiosRequestConfig) {
        return AccountOnboardingApiFp(this.configuration).apiAccountOnboardingOnboardingPost(accountOnBoardingLogic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiFlowApi - axios parameter creator
 * @export
 */
export const AiFlowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowActionGroupsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/action-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowAddAiflowPost: async (upsertAiFlowRequest?: UpsertAiFlowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/add-aiflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAiFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowAiFlowsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/AiFlows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowAiflowItemIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAiFlowAiflowItemIdGet', 'id', id)
            const localVarPath = `/api/AiFlow/aiflow-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCopyAiflowToFolderPost: async (copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/copy-aiflow-to-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyMoveAiFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAiFlowItemNoteRequest} [createAiFlowItemNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCreateAiflowitemNotePost: async (createAiFlowItemNoteRequest?: CreateAiFlowItemNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/create-aiflowitem-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAiFlowItemNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/custom-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAiFlowCustomActionIdDelete', 'id', id)
            const localVarPath = `/api/AiFlow/custom-action/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomActionRequest} [customActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionPost: async (customActionRequest?: CustomActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/custom-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomActionRequest} [customActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionPut: async (customActionRequest?: CustomActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/custom-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteAiFlowRequest} [deleteAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowDeleteAiflowDelete: async (deleteAiFlowRequest?: DeleteAiFlowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/delete-aiflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAiFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowDeleteAiflowitemNoteIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAiFlowDeleteAiflowitemNoteIdDelete', 'id', id)
            const localVarPath = `/api/AiFlow/delete-aiflowitem-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [aiFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowGetAiflowVersionsGet: async (aiFlowId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/get-aiflow-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aiFlowId !== undefined) {
                localVarQueryParameter['AiFlowId'] = aiFlowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [aiFlowItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowGetAiflowitemNotesGet: async (aiFlowItemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/get-aiflowitem-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aiFlowItemId !== undefined) {
                localVarQueryParameter['AiFlowItemId'] = aiFlowItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportAiFlowRequest} [importAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowImportAiflowPost: async (importAiFlowRequest?: ImportAiFlowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/import-aiflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importAiFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowMoveAiflowToFolderPut: async (copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/move-aiflow-to-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyMoveAiFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveAiFlowFolderRequest} [removeAiFlowFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRemoveAiflowFromFolderDelete: async (removeAiFlowFolderRequest?: RemoveAiFlowFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/remove-aiflow-from-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeAiFlowFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RunAiFlowRequest} [runAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunAiflowPost: async (runAiFlowRequest?: RunAiFlowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/run-aiflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(runAiFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowSaveAiflowItemPut: async (upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/save-aiflow-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAiFlowItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowSaveAiflowWithoutItemsPut: async (upsertAiFlowRequest?: UpsertAiFlowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/save-aiflow-without-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAiFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowScheduleInfoPost: async (upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/schedule-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertScheduleInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowScheduleInfoPut: async (upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/schedule-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertScheduleInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestActionRequest} [testActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowTestActionPost: async (testActionRequest?: TestActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/test-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowUpdateAiflowPut: async (upsertAiFlowRequest?: UpsertAiFlowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/update-aiflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAiFlowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAiFlowItemNoteRequest} [updateAiFlowItemNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowUpdateAiflowitemNotePut: async (updateAiFlowItemNoteRequest?: UpdateAiFlowItemNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/update-aiflowitem-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAiFlowItemNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowUpsertAiflowItemPut: async (upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlow/upsert-aiflow-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAiFlowItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiFlowApi - functional programming interface
 * @export
 */
export const AiFlowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiFlowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowActionGroupsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowActionGroupLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowActionGroupsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowAddAiflowPost(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowAddAiflowPost(upsertAiFlowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowAiFlowsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowAiFlowsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowAiflowItemIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowItemLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowAiflowItemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowFolderLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAiFlowItemNoteRequest} [createAiFlowItemNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowCreateAiflowitemNotePost(createAiFlowItemNoteRequest?: CreateAiFlowItemNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowItemNoteLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowCreateAiflowitemNotePost(createAiFlowItemNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowCustomActionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowCustomActionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowCustomActionIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowCustomActionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomActionRequest} [customActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowCustomActionPost(customActionRequest?: CustomActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowCustomActionPost(customActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomActionRequest} [customActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowCustomActionPut(customActionRequest?: CustomActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowCustomActionPut(customActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteAiFlowRequest} [deleteAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowDeleteAiflowDelete(deleteAiFlowRequest?: DeleteAiFlowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowDeleteAiflowDelete(deleteAiFlowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowDeleteAiflowitemNoteIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowDeleteAiflowitemNoteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [aiFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowGetAiflowVersionsGet(aiFlowId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowVersionLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowGetAiflowVersionsGet(aiFlowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [aiFlowItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowGetAiflowitemNotesGet(aiFlowItemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowItemNoteLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowGetAiflowitemNotesGet(aiFlowItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportAiFlowRequest} [importAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowImportAiflowPost(importAiFlowRequest?: ImportAiFlowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowImportAiflowPost(importAiFlowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveAiFlowFolderRequest} [removeAiFlowFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRemoveAiflowFromFolderDelete(removeAiFlowFolderRequest?: RemoveAiFlowFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRemoveAiflowFromFolderDelete(removeAiFlowFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RunAiFlowRequest} [runAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunAiflowPost(runAiFlowRequest?: RunAiFlowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowRunLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunAiflowPost(runAiFlowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowSaveAiflowItemPut(upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveAiFlowItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowSaveAiflowItemPut(upsertAiFlowItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowSaveAiflowWithoutItemsPut(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowSaveAiflowWithoutItemsPut(upsertAiFlowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowScheduleInfoPost(upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowScheduleInfoLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowScheduleInfoPost(upsertScheduleInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowScheduleInfoPut(upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowScheduleInfoLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowScheduleInfoPut(upsertScheduleInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestActionRequest} [testActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowTestActionPost(testActionRequest?: TestActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowTestActionPost(testActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowUpdateAiflowPut(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowUpdateAiflowPut(upsertAiFlowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAiFlowItemNoteRequest} [updateAiFlowItemNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowUpdateAiflowitemNotePut(updateAiFlowItemNoteRequest?: UpdateAiFlowItemNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowItemNoteLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowUpdateAiflowitemNotePut(updateAiFlowItemNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowUpsertAiflowItemPut(upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowItemLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowUpsertAiflowItemPut(upsertAiFlowItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiFlowApi - factory interface
 * @export
 */
export const AiFlowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiFlowApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowActionGroupsGet(options?: any): AxiosPromise<Array<AiFlowActionGroupLogic>> {
            return localVarFp.apiAiFlowActionGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowAddAiflowPost(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: any): AxiosPromise<AiFlowLogic> {
            return localVarFp.apiAiFlowAddAiflowPost(upsertAiFlowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowAiFlowsGet(options?: any): AxiosPromise<Array<AiFlowLogic>> {
            return localVarFp.apiAiFlowAiFlowsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowAiflowItemIdGet(id: number, options?: any): AxiosPromise<AiFlowItemLogic> {
            return localVarFp.apiAiFlowAiflowItemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options?: any): AxiosPromise<AiFlowFolderLogic> {
            return localVarFp.apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAiFlowItemNoteRequest} [createAiFlowItemNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCreateAiflowitemNotePost(createAiFlowItemNoteRequest?: CreateAiFlowItemNoteRequest, options?: any): AxiosPromise<AiFlowItemNoteLogic> {
            return localVarFp.apiAiFlowCreateAiflowitemNotePost(createAiFlowItemNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowCustomActionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowCustomActionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomActionRequest} [customActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionPost(customActionRequest?: CustomActionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowCustomActionPost(customActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomActionRequest} [customActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowCustomActionPut(customActionRequest?: CustomActionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowCustomActionPut(customActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteAiFlowRequest} [deleteAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowDeleteAiflowDelete(deleteAiFlowRequest?: DeleteAiFlowRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowDeleteAiflowDelete(deleteAiFlowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowDeleteAiflowitemNoteIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowDeleteAiflowitemNoteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [aiFlowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowGetAiflowVersionsGet(aiFlowId?: number, options?: any): AxiosPromise<Array<AiFlowVersionLogic>> {
            return localVarFp.apiAiFlowGetAiflowVersionsGet(aiFlowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [aiFlowItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowGetAiflowitemNotesGet(aiFlowItemId?: number, options?: any): AxiosPromise<Array<AiFlowItemNoteLogic>> {
            return localVarFp.apiAiFlowGetAiflowitemNotesGet(aiFlowItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportAiFlowRequest} [importAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowImportAiflowPost(importAiFlowRequest?: ImportAiFlowRequest, options?: any): AxiosPromise<AiFlowLogic> {
            return localVarFp.apiAiFlowImportAiflowPost(importAiFlowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveAiFlowFolderRequest} [removeAiFlowFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRemoveAiflowFromFolderDelete(removeAiFlowFolderRequest?: RemoveAiFlowFolderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowRemoveAiflowFromFolderDelete(removeAiFlowFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RunAiFlowRequest} [runAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunAiflowPost(runAiFlowRequest?: RunAiFlowRequest, options?: any): AxiosPromise<AiFlowRunLogic> {
            return localVarFp.apiAiFlowRunAiflowPost(runAiFlowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowSaveAiflowItemPut(upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options?: any): AxiosPromise<SaveAiFlowItemResponse> {
            return localVarFp.apiAiFlowSaveAiflowItemPut(upsertAiFlowItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowSaveAiflowWithoutItemsPut(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowSaveAiflowWithoutItemsPut(upsertAiFlowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowScheduleInfoPost(upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options?: any): AxiosPromise<AiFlowScheduleInfoLogic> {
            return localVarFp.apiAiFlowScheduleInfoPost(upsertScheduleInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowScheduleInfoPut(upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options?: any): AxiosPromise<AiFlowScheduleInfoLogic> {
            return localVarFp.apiAiFlowScheduleInfoPut(upsertScheduleInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestActionRequest} [testActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowTestActionPost(testActionRequest?: TestActionRequest, options?: any): AxiosPromise<RunOutput> {
            return localVarFp.apiAiFlowTestActionPost(testActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowUpdateAiflowPut(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: any): AxiosPromise<AiFlowLogic> {
            return localVarFp.apiAiFlowUpdateAiflowPut(upsertAiFlowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAiFlowItemNoteRequest} [updateAiFlowItemNoteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowUpdateAiflowitemNotePut(updateAiFlowItemNoteRequest?: UpdateAiFlowItemNoteRequest, options?: any): AxiosPromise<AiFlowItemNoteLogic> {
            return localVarFp.apiAiFlowUpdateAiflowitemNotePut(updateAiFlowItemNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowUpsertAiflowItemPut(upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options?: any): AxiosPromise<AiFlowItemLogic> {
            return localVarFp.apiAiFlowUpsertAiflowItemPut(upsertAiFlowItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiFlowApi - object-oriented interface
 * @export
 * @class AiFlowApi
 * @extends {BaseAPI}
 */
export class AiFlowApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowActionGroupsGet(options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowActionGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowAddAiflowPost(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowAddAiflowPost(upsertAiFlowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowAiFlowsGet(options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowAiFlowsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowAiflowItemIdGet(id: number, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowAiflowItemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowCopyAiflowToFolderPost(copyMoveAiFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAiFlowItemNoteRequest} [createAiFlowItemNoteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowCreateAiflowitemNotePost(createAiFlowItemNoteRequest?: CreateAiFlowItemNoteRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowCreateAiflowitemNotePost(createAiFlowItemNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowCustomActionGet(options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowCustomActionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowCustomActionIdDelete(id: number, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowCustomActionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomActionRequest} [customActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowCustomActionPost(customActionRequest?: CustomActionRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowCustomActionPost(customActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomActionRequest} [customActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowCustomActionPut(customActionRequest?: CustomActionRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowCustomActionPut(customActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteAiFlowRequest} [deleteAiFlowRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowDeleteAiflowDelete(deleteAiFlowRequest?: DeleteAiFlowRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowDeleteAiflowDelete(deleteAiFlowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowDeleteAiflowitemNoteIdDelete(id: number, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowDeleteAiflowitemNoteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [aiFlowId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowGetAiflowVersionsGet(aiFlowId?: number, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowGetAiflowVersionsGet(aiFlowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [aiFlowItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowGetAiflowitemNotesGet(aiFlowItemId?: number, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowGetAiflowitemNotesGet(aiFlowItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportAiFlowRequest} [importAiFlowRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowImportAiflowPost(importAiFlowRequest?: ImportAiFlowRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowImportAiflowPost(importAiFlowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CopyMoveAiFolderRequest} [copyMoveAiFolderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest?: CopyMoveAiFolderRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowMoveAiflowToFolderPut(copyMoveAiFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveAiFlowFolderRequest} [removeAiFlowFolderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowRemoveAiflowFromFolderDelete(removeAiFlowFolderRequest?: RemoveAiFlowFolderRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowRemoveAiflowFromFolderDelete(removeAiFlowFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RunAiFlowRequest} [runAiFlowRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowRunAiflowPost(runAiFlowRequest?: RunAiFlowRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowRunAiflowPost(runAiFlowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowSaveAiflowItemPut(upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowSaveAiflowItemPut(upsertAiFlowItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowSaveAiflowWithoutItemsPut(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowSaveAiflowWithoutItemsPut(upsertAiFlowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowScheduleInfoPost(upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowScheduleInfoPost(upsertScheduleInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertScheduleInfoRequest} [upsertScheduleInfoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowScheduleInfoPut(upsertScheduleInfoRequest?: UpsertScheduleInfoRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowScheduleInfoPut(upsertScheduleInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestActionRequest} [testActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowTestActionPost(testActionRequest?: TestActionRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowTestActionPost(testActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertAiFlowRequest} [upsertAiFlowRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowUpdateAiflowPut(upsertAiFlowRequest?: UpsertAiFlowRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowUpdateAiflowPut(upsertAiFlowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAiFlowItemNoteRequest} [updateAiFlowItemNoteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowUpdateAiflowitemNotePut(updateAiFlowItemNoteRequest?: UpdateAiFlowItemNoteRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowUpdateAiflowitemNotePut(updateAiFlowItemNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertAiFlowItemRequest} [upsertAiFlowItemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowApi
     */
    public apiAiFlowUpsertAiflowItemPut(upsertAiFlowItemRequest?: UpsertAiFlowItemRequest, options?: AxiosRequestConfig) {
        return AiFlowApiFp(this.configuration).apiAiFlowUpsertAiflowItemPut(upsertAiFlowItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiFlowRunsApi - axios parameter creator
 * @export
 */
export const AiFlowRunsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-all-runs-outputs-by-header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [aiFlowRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunItemsGet: async (aiFlowRunId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-run-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aiFlowRunId !== undefined) {
                localVarQueryParameter['AiFlowRunId'] = aiFlowRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsItemsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-runs-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [aiFlowItemId] 
         * @param {number} [aiFlowRunOutputId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputDataGet: async (aiFlowItemId?: number, aiFlowRunOutputId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-runs-output-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aiFlowItemId !== undefined) {
                localVarQueryParameter['AiFlowItemId'] = aiFlowItemId;
            }

            if (aiFlowRunOutputId !== undefined) {
                localVarQueryParameter['AiFlowRunOutputId'] = aiFlowRunOutputId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-runs-outputs-by-header-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [header] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputsByHeaderGet: async (header?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-runs-outputs-by-header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (header !== undefined) {
                localVarQueryParameter['header'] = header;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [aiFlowRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputsGet: async (aiFlowRunId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlow-runs-outputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aiFlowRunId !== undefined) {
                localVarQueryParameter['AiFlowRunId'] = aiFlowRunId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowsRunsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/AiFlows-runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelAiFlowRunRequest} [cancelAiFlowRunRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsCancelAiflowPut: async (cancelAiFlowRunRequest?: CancelAiFlowRunRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/cancel-aiflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelAiFlowRunRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOutputCommentRequest} [createOutputCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsCreateOutputCommentPost: async (createOutputCommentRequest?: CreateOutputCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/create-output-comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOutputCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAiFlowRunsRequest} [getAiFlowRunsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsGet: async (getAiFlowRunsRequest?: GetAiFlowRunsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAiFlowRunsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManualValidationRequest} [manualValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsManualValidationPut: async (manualValidationRequest?: ManualValidationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/manual-validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [outputCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsOutputCommentDelete: async (outputCommentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/output-comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (outputCommentId !== undefined) {
                localVarQueryParameter['OutputCommentId'] = outputCommentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOutputCommentRequest} [updateOutputCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsOutputCommentPut: async (updateOutputCommentRequest?: UpdateOutputCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/output-comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOutputCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [aiFlowRunOutputId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsOutputCommentsGet: async (aiFlowRunOutputId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns/output-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (aiFlowRunOutputId !== undefined) {
                localVarQueryParameter['AiFlowRunOutputId'] = aiFlowRunOutputId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddAiFlowRunRequest} [addAiFlowRunRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsPost: async (addAiFlowRunRequest?: AddAiFlowRunRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAiFlowRunRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeAiFlowRunStatusRequest} [changeAiFlowRunStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsPut: async (changeAiFlowRunStatusRequest?: ChangeAiFlowRunStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowRuns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeAiFlowRunStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiFlowRunsApi - functional programming interface
 * @export
 */
export const AiFlowRunsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiFlowRunsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [aiFlowRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowItemLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowRunsItemsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAiFlowRunItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowRunsItemsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [aiFlowItemId] 
         * @param {number} [aiFlowRunOutputId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowRunsOutputDataGet(aiFlowItemId?: number, aiFlowRunOutputId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputRowData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowRunsOutputDataGet(aiFlowItemId, aiFlowRunOutputId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [header] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(header?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(header, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [aiFlowRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowRunsOutputsGet(aiFlowRunId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowRunOutputLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowRunsOutputsGet(aiFlowRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsAiFlowsRunsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowRunLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsAiFlowsRunsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CancelAiFlowRunRequest} [cancelAiFlowRunRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsCancelAiflowPut(cancelAiFlowRunRequest?: CancelAiFlowRunRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsCancelAiflowPut(cancelAiFlowRunRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOutputCommentRequest} [createOutputCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsCreateOutputCommentPost(createOutputCommentRequest?: CreateOutputCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputCommentLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsCreateOutputCommentPost(createOutputCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetAiFlowRunsRequest} [getAiFlowRunsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsGet(getAiFlowRunsRequest?: GetAiFlowRunsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowRunLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsGet(getAiFlowRunsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManualValidationRequest} [manualValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsManualValidationPut(manualValidationRequest?: ManualValidationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsManualValidationPut(manualValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [outputCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsOutputCommentDelete(outputCommentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsOutputCommentDelete(outputCommentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOutputCommentRequest} [updateOutputCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsOutputCommentPut(updateOutputCommentRequest?: UpdateOutputCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsOutputCommentPut(updateOutputCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [aiFlowRunOutputId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsOutputCommentsGet(aiFlowRunOutputId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputCommentLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsOutputCommentsGet(aiFlowRunOutputId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddAiFlowRunRequest} [addAiFlowRunRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsPost(addAiFlowRunRequest?: AddAiFlowRunRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowRunLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsPost(addAiFlowRunRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangeAiFlowRunStatusRequest} [changeAiFlowRunStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowRunsPut(changeAiFlowRunStatusRequest?: ChangeAiFlowRunStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiFlowRunLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowRunsPut(changeAiFlowRunStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiFlowRunsApi - factory interface
 * @export
 */
export const AiFlowRunsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiFlowRunsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [aiFlowRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId?: number, options?: any): AxiosPromise<Array<AiFlowItemLogic>> {
            return localVarFp.apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsItemsGet(options?: any): AxiosPromise<GetAiFlowRunItemsResponse> {
            return localVarFp.apiAiFlowRunsAiFlowRunsItemsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [aiFlowItemId] 
         * @param {number} [aiFlowRunOutputId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputDataGet(aiFlowItemId?: number, aiFlowRunOutputId?: number, options?: any): AxiosPromise<OutputRowData> {
            return localVarFp.apiAiFlowRunsAiFlowRunsOutputDataGet(aiFlowItemId, aiFlowRunOutputId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [header] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(header?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(header, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [aiFlowRunId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowRunsOutputsGet(aiFlowRunId?: number, options?: any): AxiosPromise<Array<AiFlowRunOutputLogic>> {
            return localVarFp.apiAiFlowRunsAiFlowRunsOutputsGet(aiFlowRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsAiFlowsRunsGet(options?: any): AxiosPromise<Array<AiFlowRunLogic>> {
            return localVarFp.apiAiFlowRunsAiFlowsRunsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelAiFlowRunRequest} [cancelAiFlowRunRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsCancelAiflowPut(cancelAiFlowRunRequest?: CancelAiFlowRunRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowRunsCancelAiflowPut(cancelAiFlowRunRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOutputCommentRequest} [createOutputCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsCreateOutputCommentPost(createOutputCommentRequest?: CreateOutputCommentRequest, options?: any): AxiosPromise<OutputCommentLogic> {
            return localVarFp.apiAiFlowRunsCreateOutputCommentPost(createOutputCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetAiFlowRunsRequest} [getAiFlowRunsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsGet(getAiFlowRunsRequest?: GetAiFlowRunsRequest, options?: any): AxiosPromise<AiFlowRunLogic> {
            return localVarFp.apiAiFlowRunsGet(getAiFlowRunsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManualValidationRequest} [manualValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsManualValidationPut(manualValidationRequest?: ManualValidationRequest, options?: any): AxiosPromise<ManualValidationResponse> {
            return localVarFp.apiAiFlowRunsManualValidationPut(manualValidationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [outputCommentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsOutputCommentDelete(outputCommentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowRunsOutputCommentDelete(outputCommentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOutputCommentRequest} [updateOutputCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsOutputCommentPut(updateOutputCommentRequest?: UpdateOutputCommentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAiFlowRunsOutputCommentPut(updateOutputCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [aiFlowRunOutputId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsOutputCommentsGet(aiFlowRunOutputId?: number, options?: any): AxiosPromise<Array<OutputCommentLogic>> {
            return localVarFp.apiAiFlowRunsOutputCommentsGet(aiFlowRunOutputId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddAiFlowRunRequest} [addAiFlowRunRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsPost(addAiFlowRunRequest?: AddAiFlowRunRequest, options?: any): AxiosPromise<AiFlowRunLogic> {
            return localVarFp.apiAiFlowRunsPost(addAiFlowRunRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeAiFlowRunStatusRequest} [changeAiFlowRunStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowRunsPut(changeAiFlowRunStatusRequest?: ChangeAiFlowRunStatusRequest, options?: any): AxiosPromise<AiFlowRunLogic> {
            return localVarFp.apiAiFlowRunsPut(changeAiFlowRunStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiFlowRunsApi - object-oriented interface
 * @export
 * @class AiFlowRunsApi
 * @extends {BaseAPI}
 */
export class AiFlowRunsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet(options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowAllRunsOutputsByHeaderGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [aiFlowRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId?: number, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowRunItemsGet(aiFlowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowRunsItemsGet(options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowRunsItemsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [aiFlowItemId] 
     * @param {number} [aiFlowRunOutputId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowRunsOutputDataGet(aiFlowItemId?: number, aiFlowRunOutputId?: number, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowRunsOutputDataGet(aiFlowItemId, aiFlowRunOutputId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet(options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowRunsOutputsByHeaderCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [header] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(header?: string, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowRunsOutputsByHeaderGet(header, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [aiFlowRunId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowRunsOutputsGet(aiFlowRunId?: number, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowRunsOutputsGet(aiFlowRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsAiFlowsRunsGet(options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsAiFlowsRunsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CancelAiFlowRunRequest} [cancelAiFlowRunRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsCancelAiflowPut(cancelAiFlowRunRequest?: CancelAiFlowRunRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsCancelAiflowPut(cancelAiFlowRunRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOutputCommentRequest} [createOutputCommentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsCreateOutputCommentPost(createOutputCommentRequest?: CreateOutputCommentRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsCreateOutputCommentPost(createOutputCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetAiFlowRunsRequest} [getAiFlowRunsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsGet(getAiFlowRunsRequest?: GetAiFlowRunsRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsGet(getAiFlowRunsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManualValidationRequest} [manualValidationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsManualValidationPut(manualValidationRequest?: ManualValidationRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsManualValidationPut(manualValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [outputCommentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsOutputCommentDelete(outputCommentId?: number, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsOutputCommentDelete(outputCommentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOutputCommentRequest} [updateOutputCommentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsOutputCommentPut(updateOutputCommentRequest?: UpdateOutputCommentRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsOutputCommentPut(updateOutputCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [aiFlowRunOutputId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsOutputCommentsGet(aiFlowRunOutputId?: number, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsOutputCommentsGet(aiFlowRunOutputId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddAiFlowRunRequest} [addAiFlowRunRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsPost(addAiFlowRunRequest?: AddAiFlowRunRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsPost(addAiFlowRunRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeAiFlowRunStatusRequest} [changeAiFlowRunStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowRunsApi
     */
    public apiAiFlowRunsPut(changeAiFlowRunStatusRequest?: ChangeAiFlowRunStatusRequest, options?: AxiosRequestConfig) {
        return AiFlowRunsApiFp(this.configuration).apiAiFlowRunsPut(changeAiFlowRunStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AiFlowVersionApi - axios parameter creator
 * @export
 */
export const AiFlowVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAiFlowVersionRequest} [createAiFlowVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowVersionPost: async (createAiFlowVersionRequest?: CreateAiFlowVersionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AiFlowVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAiFlowVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiFlowVersionApi - functional programming interface
 * @export
 */
export const AiFlowVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiFlowVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAiFlowVersionRequest} [createAiFlowVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAiFlowVersionPost(createAiFlowVersionRequest?: CreateAiFlowVersionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAiFlowVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAiFlowVersionPost(createAiFlowVersionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiFlowVersionApi - factory interface
 * @export
 */
export const AiFlowVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiFlowVersionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAiFlowVersionRequest} [createAiFlowVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAiFlowVersionPost(createAiFlowVersionRequest?: CreateAiFlowVersionRequest, options?: any): AxiosPromise<CreateAiFlowVersionResponse> {
            return localVarFp.apiAiFlowVersionPost(createAiFlowVersionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiFlowVersionApi - object-oriented interface
 * @export
 * @class AiFlowVersionApi
 * @extends {BaseAPI}
 */
export class AiFlowVersionApi extends BaseAPI {
    /**
     * 
     * @param {CreateAiFlowVersionRequest} [createAiFlowVersionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiFlowVersionApi
     */
    public apiAiFlowVersionPost(createAiFlowVersionRequest?: CreateAiFlowVersionRequest, options?: AxiosRequestConfig) {
        return AiFlowVersionApiFp(this.configuration).apiAiFlowVersionPost(createAiFlowVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisApi - axios parameter creator
 * @export
 */
export const AnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContentAnalysisRequest} [contentAnalysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalysisContentAnalysisPost: async (contentAnalysisRequest?: ContentAnalysisRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Analysis/content-analysis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentAnalysisRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisApi - functional programming interface
 * @export
 */
export const AnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContentAnalysisRequest} [contentAnalysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalysisContentAnalysisPost(contentAnalysisRequest?: ContentAnalysisRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalysisContentAnalysisPost(contentAnalysisRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisApi - factory interface
 * @export
 */
export const AnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisApiFp(configuration)
    return {
        /**
         * 
         * @param {ContentAnalysisRequest} [contentAnalysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalysisContentAnalysisPost(contentAnalysisRequest?: ContentAnalysisRequest, options?: any): AxiosPromise<ContentAnalysisResponse> {
            return localVarFp.apiAnalysisContentAnalysisPost(contentAnalysisRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
export class AnalysisApi extends BaseAPI {
    /**
     * 
     * @param {ContentAnalysisRequest} [contentAnalysisRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public apiAnalysisContentAnalysisPost(contentAnalysisRequest?: ContentAnalysisRequest, options?: AxiosRequestConfig) {
        return AnalysisApiFp(this.configuration).apiAnalysisContentAnalysisPost(contentAnalysisRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApiGenerateApiKeyPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Api/generate-api-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApiGenerateApiKeyPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApiGenerateApiKeyPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApiGenerateApiKeyPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiApiGenerateApiKeyPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiApiGenerateApiKeyPost(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiApiGenerateApiKeyPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandVoiceApi - axios parameter creator
 * @export
 */
export const BrandVoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteBrandVoiceRequest} [deleteBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoiceDelete: async (deleteBrandVoiceRequest?: DeleteBrandVoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandVoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBrandVoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoiceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandVoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoicePost: async (upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandVoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertBrandVoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoicePut: async (upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandVoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertBrandVoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandVoiceApi - functional programming interface
 * @export
 */
export const BrandVoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandVoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteBrandVoiceRequest} [deleteBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandVoiceDelete(deleteBrandVoiceRequest?: DeleteBrandVoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandVoiceDelete(deleteBrandVoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandVoiceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonaLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandVoiceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandVoicePost(upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonaLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandVoicePost(upsertBrandVoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandVoicePut(upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandVoicePut(upsertBrandVoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandVoiceApi - factory interface
 * @export
 */
export const BrandVoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandVoiceApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteBrandVoiceRequest} [deleteBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoiceDelete(deleteBrandVoiceRequest?: DeleteBrandVoiceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandVoiceDelete(deleteBrandVoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoiceGet(options?: any): AxiosPromise<Array<PersonaLogic>> {
            return localVarFp.apiBrandVoiceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoicePost(upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options?: any): AxiosPromise<PersonaLogic> {
            return localVarFp.apiBrandVoicePost(upsertBrandVoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandVoicePut(upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandVoicePut(upsertBrandVoiceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandVoiceApi - object-oriented interface
 * @export
 * @class BrandVoiceApi
 * @extends {BaseAPI}
 */
export class BrandVoiceApi extends BaseAPI {
    /**
     * 
     * @param {DeleteBrandVoiceRequest} [deleteBrandVoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandVoiceApi
     */
    public apiBrandVoiceDelete(deleteBrandVoiceRequest?: DeleteBrandVoiceRequest, options?: AxiosRequestConfig) {
        return BrandVoiceApiFp(this.configuration).apiBrandVoiceDelete(deleteBrandVoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandVoiceApi
     */
    public apiBrandVoiceGet(options?: AxiosRequestConfig) {
        return BrandVoiceApiFp(this.configuration).apiBrandVoiceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandVoiceApi
     */
    public apiBrandVoicePost(upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options?: AxiosRequestConfig) {
        return BrandVoiceApiFp(this.configuration).apiBrandVoicePost(upsertBrandVoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertBrandVoiceRequest} [upsertBrandVoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandVoiceApi
     */
    public apiBrandVoicePut(upsertBrandVoiceRequest?: UpsertBrandVoiceRequest, options?: AxiosRequestConfig) {
        return BrandVoiceApiFp(this.configuration).apiBrandVoicePut(upsertBrandVoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationsApi - axios parameter creator
 * @export
 */
export const ConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteConfigurationsRequest} [deleteConfigurationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsDelete: async (deleteConfigurationsRequest?: DeleteConfigurationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteConfigurationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsPost: async (upsertConfigurationRequest?: UpsertConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsPut: async (upsertConfigurationRequest?: UpsertConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationsApi - functional programming interface
 * @export
 */
export const ConfigurationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteConfigurationsRequest} [deleteConfigurationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConfigurationsDelete(deleteConfigurationsRequest?: DeleteConfigurationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigurationsDelete(deleteConfigurationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConfigurationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigurationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConfigurationsPost(upsertConfigurationRequest?: UpsertConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigurationsPost(upsertConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConfigurationsPut(upsertConfigurationRequest?: UpsertConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigurationsPut(upsertConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationsApi - factory interface
 * @export
 */
export const ConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationsApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteConfigurationsRequest} [deleteConfigurationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsDelete(deleteConfigurationsRequest?: DeleteConfigurationsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiConfigurationsDelete(deleteConfigurationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsGet(options?: any): AxiosPromise<Array<ConfigurationLogic>> {
            return localVarFp.apiConfigurationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsPost(upsertConfigurationRequest?: UpsertConfigurationRequest, options?: any): AxiosPromise<ConfigurationLogic> {
            return localVarFp.apiConfigurationsPost(upsertConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationsPut(upsertConfigurationRequest?: UpsertConfigurationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiConfigurationsPut(upsertConfigurationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationsApi - object-oriented interface
 * @export
 * @class ConfigurationsApi
 * @extends {BaseAPI}
 */
export class ConfigurationsApi extends BaseAPI {
    /**
     * 
     * @param {DeleteConfigurationsRequest} [deleteConfigurationsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public apiConfigurationsDelete(deleteConfigurationsRequest?: DeleteConfigurationsRequest, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).apiConfigurationsDelete(deleteConfigurationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public apiConfigurationsGet(options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).apiConfigurationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public apiConfigurationsPost(upsertConfigurationRequest?: UpsertConfigurationRequest, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).apiConfigurationsPost(upsertConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertConfigurationRequest} [upsertConfigurationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationsApi
     */
    public apiConfigurationsPut(upsertConfigurationRequest?: UpsertConfigurationRequest, options?: AxiosRequestConfig) {
        return ConfigurationsApiFp(this.configuration).apiConfigurationsPut(upsertConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiConnectionConnectionIdDelete', 'id', id)
            const localVarPath = `/api/Connection/connection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionPost: async (upsertConnectionRequest?: UpsertConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Connection/connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionPut: async (upsertConnectionRequest?: UpsertConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Connection/connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Connection/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConnectionConnectionIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionConnectionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConnectionConnectionPost(upsertConnectionRequest?: UpsertConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionConnectionPost(upsertConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConnectionConnectionPut(upsertConnectionRequest?: UpsertConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionConnectionPut(upsertConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConnectionConnectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectionLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConnectionConnectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiConnectionConnectionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionPost(upsertConnectionRequest?: UpsertConnectionRequest, options?: any): AxiosPromise<ConnectionLogic> {
            return localVarFp.apiConnectionConnectionPost(upsertConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionPut(upsertConnectionRequest?: UpsertConnectionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiConnectionConnectionPut(upsertConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectionConnectionsGet(options?: any): AxiosPromise<Array<ConnectionLogic>> {
            return localVarFp.apiConnectionConnectionsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiConnectionConnectionIdDelete(id: number, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiConnectionConnectionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiConnectionConnectionPost(upsertConnectionRequest?: UpsertConnectionRequest, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiConnectionConnectionPost(upsertConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertConnectionRequest} [upsertConnectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiConnectionConnectionPut(upsertConnectionRequest?: UpsertConnectionRequest, options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiConnectionConnectionPut(upsertConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public apiConnectionConnectionsGet(options?: AxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).apiConnectionConnectionsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentSnippetApi - axios parameter creator
 * @export
 */
export const ContentSnippetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteContentSnippetsRequest} [deleteContentSnippetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetDelete: async (deleteContentSnippetsRequest?: DeleteContentSnippetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContentSnippet/content-snippet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteContentSnippetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetPost: async (upsertContentSnippetRequest?: UpsertContentSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContentSnippet/content-snippet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertContentSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetPut: async (upsertContentSnippetRequest?: UpsertContentSnippetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContentSnippet/content-snippet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertContentSnippetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContentSnippet/content-snippets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentSnippetApi - functional programming interface
 * @export
 */
export const ContentSnippetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentSnippetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteContentSnippetsRequest} [deleteContentSnippetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentSnippetContentSnippetDelete(deleteContentSnippetsRequest?: DeleteContentSnippetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentSnippetContentSnippetDelete(deleteContentSnippetsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentSnippetContentSnippetPost(upsertContentSnippetRequest?: UpsertContentSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserContentSnippet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentSnippetContentSnippetPost(upsertContentSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentSnippetContentSnippetPut(upsertContentSnippetRequest?: UpsertContentSnippetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentSnippetContentSnippetPut(upsertContentSnippetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentSnippetContentSnippetsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserContentSnippet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentSnippetContentSnippetsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentSnippetApi - factory interface
 * @export
 */
export const ContentSnippetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentSnippetApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteContentSnippetsRequest} [deleteContentSnippetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetDelete(deleteContentSnippetsRequest?: DeleteContentSnippetsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiContentSnippetContentSnippetDelete(deleteContentSnippetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetPost(upsertContentSnippetRequest?: UpsertContentSnippetRequest, options?: any): AxiosPromise<UserContentSnippet> {
            return localVarFp.apiContentSnippetContentSnippetPost(upsertContentSnippetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetPut(upsertContentSnippetRequest?: UpsertContentSnippetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiContentSnippetContentSnippetPut(upsertContentSnippetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentSnippetContentSnippetsGet(options?: any): AxiosPromise<Array<UserContentSnippet>> {
            return localVarFp.apiContentSnippetContentSnippetsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentSnippetApi - object-oriented interface
 * @export
 * @class ContentSnippetApi
 * @extends {BaseAPI}
 */
export class ContentSnippetApi extends BaseAPI {
    /**
     * 
     * @param {DeleteContentSnippetsRequest} [deleteContentSnippetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentSnippetApi
     */
    public apiContentSnippetContentSnippetDelete(deleteContentSnippetsRequest?: DeleteContentSnippetsRequest, options?: AxiosRequestConfig) {
        return ContentSnippetApiFp(this.configuration).apiContentSnippetContentSnippetDelete(deleteContentSnippetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentSnippetApi
     */
    public apiContentSnippetContentSnippetPost(upsertContentSnippetRequest?: UpsertContentSnippetRequest, options?: AxiosRequestConfig) {
        return ContentSnippetApiFp(this.configuration).apiContentSnippetContentSnippetPost(upsertContentSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertContentSnippetRequest} [upsertContentSnippetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentSnippetApi
     */
    public apiContentSnippetContentSnippetPut(upsertContentSnippetRequest?: UpsertContentSnippetRequest, options?: AxiosRequestConfig) {
        return ContentSnippetApiFp(this.configuration).apiContentSnippetContentSnippetPut(upsertContentSnippetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentSnippetApi
     */
    public apiContentSnippetContentSnippetsGet(options?: AxiosRequestConfig) {
        return ContentSnippetApiFp(this.configuration).apiContentSnippetContentSnippetsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CraftApi - axios parameter creator
 * @export
 */
export const CraftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftFollowUpsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/follow-ups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CraftRequestLogic} [craftRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftGenerateContentStreamPost: async (craftRequestLogic?: CraftRequestLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/generate-content-stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(craftRequestLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CraftRequestLogic} [craftRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftGeneratePost: async (craftRequestLogic?: CraftRequestLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(craftRequestLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftGeneratedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/generated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PauseGenerateContentRequest} [pauseGenerateContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftPauseGenerateContentStreamPut: async (pauseGenerateContentRequest?: PauseGenerateContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/pause-generate-content-stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pauseGenerateContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromptFollowUpRequestLogic} [promptFollowUpRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftPromptFollowUpPost: async (promptFollowUpRequestLogic?: PromptFollowUpRequestLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/prompt-follow-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptFollowUpRequestLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserGeneratedContentsRequest} [deleteUserGeneratedContentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftUserGeneratedContentsDelete: async (deleteUserGeneratedContentsRequest?: DeleteUserGeneratedContentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Craft/user-generated-contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserGeneratedContentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CraftApi - functional programming interface
 * @export
 */
export const CraftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CraftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftFollowUpsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FollowUpLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftFollowUpsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CraftRequestLogic} [craftRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftGenerateContentStreamPost(craftRequestLogic?: CraftRequestLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftGenerateContentStreamPost(craftRequestLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CraftRequestLogic} [craftRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftGeneratePost(craftRequestLogic?: CraftRequestLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CraftResponseLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftGeneratePost(craftRequestLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftGeneratedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneratedLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftGeneratedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PauseGenerateContentRequest} [pauseGenerateContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftPauseGenerateContentStreamPut(pauseGenerateContentRequest?: PauseGenerateContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftPauseGenerateContentStreamPut(pauseGenerateContentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromptFollowUpRequestLogic} [promptFollowUpRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftPromptFollowUpPost(promptFollowUpRequestLogic?: PromptFollowUpRequestLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptFollowUpResponseLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftPromptFollowUpPost(promptFollowUpRequestLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUserGeneratedContentsRequest} [deleteUserGeneratedContentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCraftUserGeneratedContentsDelete(deleteUserGeneratedContentsRequest?: DeleteUserGeneratedContentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCraftUserGeneratedContentsDelete(deleteUserGeneratedContentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CraftApi - factory interface
 * @export
 */
export const CraftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CraftApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftFollowUpsGet(options?: any): AxiosPromise<Array<FollowUpLogic>> {
            return localVarFp.apiCraftFollowUpsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CraftRequestLogic} [craftRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftGenerateContentStreamPost(craftRequestLogic?: CraftRequestLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiCraftGenerateContentStreamPost(craftRequestLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CraftRequestLogic} [craftRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftGeneratePost(craftRequestLogic?: CraftRequestLogic, options?: any): AxiosPromise<CraftResponseLogic> {
            return localVarFp.apiCraftGeneratePost(craftRequestLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftGeneratedGet(options?: any): AxiosPromise<Array<GeneratedLogic>> {
            return localVarFp.apiCraftGeneratedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PauseGenerateContentRequest} [pauseGenerateContentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftPauseGenerateContentStreamPut(pauseGenerateContentRequest?: PauseGenerateContentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCraftPauseGenerateContentStreamPut(pauseGenerateContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromptFollowUpRequestLogic} [promptFollowUpRequestLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftPromptFollowUpPost(promptFollowUpRequestLogic?: PromptFollowUpRequestLogic, options?: any): AxiosPromise<PromptFollowUpResponseLogic> {
            return localVarFp.apiCraftPromptFollowUpPost(promptFollowUpRequestLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUserGeneratedContentsRequest} [deleteUserGeneratedContentsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCraftUserGeneratedContentsDelete(deleteUserGeneratedContentsRequest?: DeleteUserGeneratedContentsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCraftUserGeneratedContentsDelete(deleteUserGeneratedContentsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CraftApi - object-oriented interface
 * @export
 * @class CraftApi
 * @extends {BaseAPI}
 */
export class CraftApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftFollowUpsGet(options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftFollowUpsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CraftRequestLogic} [craftRequestLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftGenerateContentStreamPost(craftRequestLogic?: CraftRequestLogic, options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftGenerateContentStreamPost(craftRequestLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CraftRequestLogic} [craftRequestLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftGeneratePost(craftRequestLogic?: CraftRequestLogic, options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftGeneratePost(craftRequestLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftGeneratedGet(options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftGeneratedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PauseGenerateContentRequest} [pauseGenerateContentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftPauseGenerateContentStreamPut(pauseGenerateContentRequest?: PauseGenerateContentRequest, options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftPauseGenerateContentStreamPut(pauseGenerateContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromptFollowUpRequestLogic} [promptFollowUpRequestLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftPromptFollowUpPost(promptFollowUpRequestLogic?: PromptFollowUpRequestLogic, options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftPromptFollowUpPost(promptFollowUpRequestLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUserGeneratedContentsRequest} [deleteUserGeneratedContentsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CraftApi
     */
    public apiCraftUserGeneratedContentsDelete(deleteUserGeneratedContentsRequest?: DeleteUserGeneratedContentsRequest, options?: AxiosRequestConfig) {
        return CraftApiFp(this.configuration).apiCraftUserGeneratedContentsDelete(deleteUserGeneratedContentsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CreditApi - axios parameter creator
 * @export
 */
export const CreditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCreditGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditApi - functional programming interface
 * @export
 */
export const CreditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCreditGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditStatusLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCreditGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditApi - factory interface
 * @export
 */
export const CreditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCreditGet(options?: any): AxiosPromise<CreditStatusLogic> {
            return localVarFp.apiCreditGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreditApi - object-oriented interface
 * @export
 * @class CreditApi
 * @extends {BaseAPI}
 */
export class CreditApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditApi
     */
    public apiCreditGet(options?: AxiosRequestConfig) {
        return CreditApiFp(this.configuration).apiCreditGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardWidgetsData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGet(options?: any): AxiosPromise<DashboardWidgetsData> {
            return localVarFp.apiDashboardGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailProviderApi - axios parameter creator
 * @export
 */
export const EmailProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [connectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailProviderCampaignsGet: async (providerName?: string, connectionId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailProvider/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (providerName !== undefined) {
                localVarQueryParameter['ProviderName'] = providerName;
            }

            if (connectionId !== undefined) {
                localVarQueryParameter['ConnectionId'] = connectionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [connectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailProviderListsGet: async (providerName?: string, connectionId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailProvider/lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (providerName !== undefined) {
                localVarQueryParameter['ProviderName'] = providerName;
            }

            if (connectionId !== undefined) {
                localVarQueryParameter['ConnectionId'] = connectionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailProviderApi - functional programming interface
 * @export
 */
export const EmailProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [connectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailProviderCampaignsGet(providerName?: string, connectionId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailProviderCampaignsGet(providerName, connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [connectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailProviderListsGet(providerName?: string, connectionId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailProviderListsGet(providerName, connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailProviderApi - factory interface
 * @export
 */
export const EmailProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [connectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailProviderCampaignsGet(providerName?: string, connectionId?: number, options?: any): AxiosPromise<Array<NameId>> {
            return localVarFp.apiEmailProviderCampaignsGet(providerName, connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [providerName] 
         * @param {number} [connectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailProviderListsGet(providerName?: string, connectionId?: number, options?: any): AxiosPromise<Array<NameId>> {
            return localVarFp.apiEmailProviderListsGet(providerName, connectionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailProviderApi - object-oriented interface
 * @export
 * @class EmailProviderApi
 * @extends {BaseAPI}
 */
export class EmailProviderApi extends BaseAPI {
    /**
     * 
     * @param {string} [providerName] 
     * @param {number} [connectionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailProviderApi
     */
    public apiEmailProviderCampaignsGet(providerName?: string, connectionId?: number, options?: AxiosRequestConfig) {
        return EmailProviderApiFp(this.configuration).apiEmailProviderCampaignsGet(providerName, connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [providerName] 
     * @param {number} [connectionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailProviderApi
     */
    public apiEmailProviderListsGet(providerName?: string, connectionId?: number, options?: AxiosRequestConfig) {
        return EmailProviderApiFp(this.configuration).apiEmailProviderListsGet(providerName, connectionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailTemplateApi - axios parameter creator
 * @export
 */
export const EmailTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateDeleteTemplateIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEmailTemplateDeleteTemplateIdDelete', 'id', id)
            const localVarPath = `/api/EmailTemplate/delete-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateGetSavedTemplatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/get-saved-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTemplatesThumbnailRequest} [getTemplatesThumbnailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateGetTemplatesThumbnailPost: async (getTemplatesThumbnailRequest?: GetTemplatesThumbnailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/get-templates-thumbnail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTemplatesThumbnailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenameTemplateRequest} [renameTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateRenameTemplatePut: async (renameTemplateRequest?: RenameTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/rename-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(renameTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveTemplateRequest} [saveTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateSaveTemplatePut: async (saveTemplateRequest?: SaveTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/save-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveTemplatesRequest} [saveTemplatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateSaveTemplatesPost: async (saveTemplatesRequest?: SaveTemplatesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EmailTemplate/save-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveTemplatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplateApi - functional programming interface
 * @export
 */
export const EmailTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateDeleteTemplateIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateDeleteTemplateIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateGetSavedTemplatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateGetSavedTemplatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetTemplatesThumbnailRequest} [getTemplatesThumbnailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateGetTemplatesThumbnailPost(getTemplatesThumbnailRequest?: GetTemplatesThumbnailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateGetTemplatesThumbnailPost(getTemplatesThumbnailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RenameTemplateRequest} [renameTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateRenameTemplatePut(renameTemplateRequest?: RenameTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateRenameTemplatePut(renameTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveTemplateRequest} [saveTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateSaveTemplatePut(saveTemplateRequest?: SaveTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateSaveTemplatePut(saveTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveTemplatesRequest} [saveTemplatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmailTemplateSaveTemplatesPost(saveTemplatesRequest?: SaveTemplatesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmailTemplateSaveTemplatesPost(saveTemplatesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplateApi - factory interface
 * @export
 */
export const EmailTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateDeleteTemplateIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmailTemplateDeleteTemplateIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateGetSavedTemplatesGet(options?: any): AxiosPromise<Array<TemplateViewModel>> {
            return localVarFp.apiEmailTemplateGetSavedTemplatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTemplatesThumbnailRequest} [getTemplatesThumbnailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateGetTemplatesThumbnailPost(getTemplatesThumbnailRequest?: GetTemplatesThumbnailRequest, options?: any): AxiosPromise<Array<TemplateViewModel>> {
            return localVarFp.apiEmailTemplateGetTemplatesThumbnailPost(getTemplatesThumbnailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenameTemplateRequest} [renameTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateRenameTemplatePut(renameTemplateRequest?: RenameTemplateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmailTemplateRenameTemplatePut(renameTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveTemplateRequest} [saveTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateSaveTemplatePut(saveTemplateRequest?: SaveTemplateRequest, options?: any): AxiosPromise<TemplateViewModel> {
            return localVarFp.apiEmailTemplateSaveTemplatePut(saveTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveTemplatesRequest} [saveTemplatesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmailTemplateSaveTemplatesPost(saveTemplatesRequest?: SaveTemplatesRequest, options?: any): AxiosPromise<Array<TemplateViewModel>> {
            return localVarFp.apiEmailTemplateSaveTemplatesPost(saveTemplatesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplateApi - object-oriented interface
 * @export
 * @class EmailTemplateApi
 * @extends {BaseAPI}
 */
export class EmailTemplateApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateDeleteTemplateIdDelete(id: number, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateDeleteTemplateIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateGetSavedTemplatesGet(options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateGetSavedTemplatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetTemplatesThumbnailRequest} [getTemplatesThumbnailRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateGetTemplatesThumbnailPost(getTemplatesThumbnailRequest?: GetTemplatesThumbnailRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateGetTemplatesThumbnailPost(getTemplatesThumbnailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenameTemplateRequest} [renameTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateRenameTemplatePut(renameTemplateRequest?: RenameTemplateRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateRenameTemplatePut(renameTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveTemplateRequest} [saveTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateSaveTemplatePut(saveTemplateRequest?: SaveTemplateRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateSaveTemplatePut(saveTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveTemplatesRequest} [saveTemplatesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplateApi
     */
    public apiEmailTemplateSaveTemplatesPost(saveTemplatesRequest?: SaveTemplatesRequest, options?: AxiosRequestConfig) {
        return EmailTemplateApiFp(this.configuration).apiEmailTemplateSaveTemplatesPost(saveTemplatesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorApi - axios parameter creator
 * @export
 */
export const ErrorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorApi - functional programming interface
 * @export
 */
export const ErrorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorApi - factory interface
 * @export
 */
export const ErrorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorGet(options?: any): AxiosPromise<void> {
            return localVarFp.errorGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorApi - object-oriented interface
 * @export
 * @class ErrorApi
 * @extends {BaseAPI}
 */
export class ErrorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorApi
     */
    public errorGet(options?: AxiosRequestConfig) {
        return ErrorApiFp(this.configuration).errorGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} containerName 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesDownloadContainerNameGet: async (containerName: string, path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerName' is not null or undefined
            assertParamExists('apiFilesDownloadContainerNameGet', 'containerName', containerName)
            const localVarPath = `/api/Files/download/{containerName}`
                .replace(`{${"containerName"}}`, encodeURIComponent(String(containerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} containerName 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesSasContainerNameGet: async (containerName: string, path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerName' is not null or undefined
            assertParamExists('apiFilesSasContainerNameGet', 'containerName', containerName)
            const localVarPath = `/api/Files/sas/{containerName}`
                .replace(`{${"containerName"}}`, encodeURIComponent(String(containerName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerName] 
         * @param {Array<File>} [files] 
         * @param {string} [objectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost: async (containerName?: string, files?: Array<File>, objectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (containerName !== undefined) { 
                localVarFormParams.append('ContainerName', containerName as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
            if (objectId !== undefined) { 
                localVarFormParams.append('ObjectId', objectId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} containerName 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesDownloadContainerNameGet(containerName: string, path?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesDownloadContainerNameGet(containerName, path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} containerName 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesSasContainerNameGet(containerName: string, path?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesSasContainerNameGet(containerName, path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerName] 
         * @param {Array<File>} [files] 
         * @param {string} [objectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesUploadPost(containerName?: string, files?: Array<File>, objectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadedFileLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesUploadPost(containerName, files, objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} containerName 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesDownloadContainerNameGet(containerName: string, path?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiFilesDownloadContainerNameGet(containerName, path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} containerName 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesSasContainerNameGet(containerName: string, path?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiFilesSasContainerNameGet(containerName, path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [containerName] 
         * @param {Array<File>} [files] 
         * @param {string} [objectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost(containerName?: string, files?: Array<File>, objectId?: string, options?: any): AxiosPromise<Array<UploadedFileLogic>> {
            return localVarFp.apiFilesUploadPost(containerName, files, objectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {string} containerName 
     * @param {string} [path] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public apiFilesDownloadContainerNameGet(containerName: string, path?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).apiFilesDownloadContainerNameGet(containerName, path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} containerName 
     * @param {string} [path] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public apiFilesSasContainerNameGet(containerName: string, path?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).apiFilesSasContainerNameGet(containerName, path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [containerName] 
     * @param {Array<File>} [files] 
     * @param {string} [objectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public apiFilesUploadPost(containerName?: string, files?: Array<File>, objectId?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).apiFilesUploadPost(containerName, files, objectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesRepoApi - axios parameter creator
 * @export
 */
export const FilesRepoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} filesRepoFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFileFilesRepoFileIdDelete: async (filesRepoFileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filesRepoFileId' is not null or undefined
            assertParamExists('apiFilesRepoFileFilesRepoFileIdDelete', 'filesRepoFileId', filesRepoFileId)
            const localVarPath = `/api/FilesRepo/file/{filesRepoFileId}`
                .replace(`{${"filesRepoFileId"}}`, encodeURIComponent(String(filesRepoFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} filesRepoFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFileFilesRepoFileIdGet: async (filesRepoFileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filesRepoFileId' is not null or undefined
            assertParamExists('apiFilesRepoFileFilesRepoFileIdGet', 'filesRepoFileId', filesRepoFileId)
            const localVarPath = `/api/FilesRepo/file/{filesRepoFileId}`
                .replace(`{${"filesRepoFileId"}}`, encodeURIComponent(String(filesRepoFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilePost: async (filesRepoFileLogic?: FilesRepoFileLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FilesRepo/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesRepoFileLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilePut: async (filesRepoFileLogic?: FilesRepoFileLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FilesRepo/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesRepoFileLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilesFilesRepoIdGet: async (filesRepoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filesRepoId' is not null or undefined
            assertParamExists('apiFilesRepoFilesFilesRepoIdGet', 'filesRepoId', filesRepoId)
            const localVarPath = `/api/FilesRepo/files/{filesRepoId}`
                .replace(`{${"filesRepoId"}}`, encodeURIComponent(String(filesRepoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilesRepoIdDelete: async (filesRepoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filesRepoId' is not null or undefined
            assertParamExists('apiFilesRepoFilesRepoIdDelete', 'filesRepoId', filesRepoId)
            const localVarPath = `/api/FilesRepo/{filesRepoId}`
                .replace(`{${"filesRepoId"}}`, encodeURIComponent(String(filesRepoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilesRepoIdGet: async (filesRepoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filesRepoId' is not null or undefined
            assertParamExists('apiFilesRepoFilesRepoIdGet', 'filesRepoId', filesRepoId)
            const localVarPath = `/api/FilesRepo/{filesRepoId}`
                .replace(`{${"filesRepoId"}}`, encodeURIComponent(String(filesRepoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FilesRepo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilesRepoLogic} [filesRepoLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoPost: async (filesRepoLogic?: FilesRepoLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FilesRepo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesRepoLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilesRepoLogic} [filesRepoLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoPut: async (filesRepoLogic?: FilesRepoLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FilesRepo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesRepoLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesRepoApi - functional programming interface
 * @export
 */
export const FilesRepoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesRepoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} filesRepoFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} filesRepoFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFileFilesRepoFileIdGet(filesRepoFileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFileFilesRepoFileIdGet(filesRepoFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFilePost(filesRepoFileLogic?: FilesRepoFileLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFilePost(filesRepoFileLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFilePut(filesRepoFileLogic?: FilesRepoFileLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFilePut(filesRepoFileLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFilesFilesRepoIdGet(filesRepoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilesRepoFileLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFilesFilesRepoIdGet(filesRepoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFilesRepoIdDelete(filesRepoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFilesRepoIdDelete(filesRepoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoFilesRepoIdGet(filesRepoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoFilesRepoIdGet(filesRepoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilesRepoLogic} [filesRepoLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoPost(filesRepoLogic?: FilesRepoLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoPost(filesRepoLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilesRepoLogic} [filesRepoLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesRepoPut(filesRepoLogic?: FilesRepoLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesRepoPut(filesRepoLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesRepoApi - factory interface
 * @export
 */
export const FilesRepoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesRepoApiFp(configuration)
    return {
        /**
         * 
         * @param {number} filesRepoFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} filesRepoFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFileFilesRepoFileIdGet(filesRepoFileId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoFileFilesRepoFileIdGet(filesRepoFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilePost(filesRepoFileLogic?: FilesRepoFileLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoFilePost(filesRepoFileLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilePut(filesRepoFileLogic?: FilesRepoFileLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoFilePut(filesRepoFileLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilesFilesRepoIdGet(filesRepoId: number, options?: any): AxiosPromise<Array<FilesRepoFileLogic>> {
            return localVarFp.apiFilesRepoFilesFilesRepoIdGet(filesRepoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilesRepoIdDelete(filesRepoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoFilesRepoIdDelete(filesRepoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} filesRepoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoFilesRepoIdGet(filesRepoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoFilesRepoIdGet(filesRepoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilesRepoLogic} [filesRepoLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoPost(filesRepoLogic?: FilesRepoLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoPost(filesRepoLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilesRepoLogic} [filesRepoLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesRepoPut(filesRepoLogic?: FilesRepoLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesRepoPut(filesRepoLogic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesRepoApi - object-oriented interface
 * @export
 * @class FilesRepoApi
 * @extends {BaseAPI}
 */
export class FilesRepoApi extends BaseAPI {
    /**
     * 
     * @param {number} filesRepoFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId: number, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFileFilesRepoFileIdDelete(filesRepoFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} filesRepoFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFileFilesRepoFileIdGet(filesRepoFileId: number, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFileFilesRepoFileIdGet(filesRepoFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFilePost(filesRepoFileLogic?: FilesRepoFileLogic, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFilePost(filesRepoFileLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilesRepoFileLogic} [filesRepoFileLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFilePut(filesRepoFileLogic?: FilesRepoFileLogic, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFilePut(filesRepoFileLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} filesRepoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFilesFilesRepoIdGet(filesRepoId: number, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFilesFilesRepoIdGet(filesRepoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} filesRepoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFilesRepoIdDelete(filesRepoId: number, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFilesRepoIdDelete(filesRepoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} filesRepoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoFilesRepoIdGet(filesRepoId: number, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoFilesRepoIdGet(filesRepoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoGet(options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilesRepoLogic} [filesRepoLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoPost(filesRepoLogic?: FilesRepoLogic, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoPost(filesRepoLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilesRepoLogic} [filesRepoLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesRepoApi
     */
    public apiFilesRepoPut(filesRepoLogic?: FilesRepoLogic, options?: AxiosRequestConfig) {
        return FilesRepoApiFp(this.configuration).apiFilesRepoPut(filesRepoLogic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderApi - axios parameter creator
 * @export
 */
export const FolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFolderIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFolderFolderIdDelete', 'id', id)
            const localVarPath = `/api/Folder/folder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertFolderRequest} [upsertFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFolderPost: async (upsertFolderRequest?: UpsertFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Folder/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertFolderRequest} [upsertFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFolderPut: async (upsertFolderRequest?: UpsertFolderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Folder/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFoldersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Folder/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderApi - functional programming interface
 * @export
 */
export const FolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFolderFolderIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFolderFolderIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertFolderRequest} [upsertFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFolderFolderPost(upsertFolderRequest?: UpsertFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFolderFolderPost(upsertFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertFolderRequest} [upsertFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFolderFolderPut(upsertFolderRequest?: UpsertFolderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFolderFolderPut(upsertFolderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFolderFoldersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FolderLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFolderFoldersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderApi - factory interface
 * @export
 */
export const FolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFolderIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFolderFolderIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertFolderRequest} [upsertFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFolderPost(upsertFolderRequest?: UpsertFolderRequest, options?: any): AxiosPromise<FolderLogic> {
            return localVarFp.apiFolderFolderPost(upsertFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertFolderRequest} [upsertFolderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFolderPut(upsertFolderRequest?: UpsertFolderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiFolderFolderPut(upsertFolderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFolderFoldersGet(options?: any): AxiosPromise<Array<FolderLogic>> {
            return localVarFp.apiFolderFoldersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderApi - object-oriented interface
 * @export
 * @class FolderApi
 * @extends {BaseAPI}
 */
export class FolderApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public apiFolderFolderIdDelete(id: number, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).apiFolderFolderIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertFolderRequest} [upsertFolderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public apiFolderFolderPost(upsertFolderRequest?: UpsertFolderRequest, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).apiFolderFolderPost(upsertFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertFolderRequest} [upsertFolderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public apiFolderFolderPut(upsertFolderRequest?: UpsertFolderRequest, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).apiFolderFolderPut(upsertFolderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public apiFolderFoldersGet(options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).apiFolderFoldersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InitialSetupApi - axios parameter creator
 * @export
 */
export const InitialSetupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInitialSetupInitialSetupStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InitialSetup/initial-setup-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateInitialSetupRequest} [updateInitialSetupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInitialSetupUpdateInitialSetupPut: async (updateInitialSetupRequest?: UpdateInitialSetupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InitialSetup/update-initial-setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInitialSetupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InitialSetupApi - functional programming interface
 * @export
 */
export const InitialSetupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InitialSetupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInitialSetupInitialSetupStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitialSetupStatusLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInitialSetupInitialSetupStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateInitialSetupRequest} [updateInitialSetupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInitialSetupUpdateInitialSetupPut(updateInitialSetupRequest?: UpdateInitialSetupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInitialSetupUpdateInitialSetupPut(updateInitialSetupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InitialSetupApi - factory interface
 * @export
 */
export const InitialSetupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InitialSetupApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInitialSetupInitialSetupStatusGet(options?: any): AxiosPromise<InitialSetupStatusLogic> {
            return localVarFp.apiInitialSetupInitialSetupStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateInitialSetupRequest} [updateInitialSetupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInitialSetupUpdateInitialSetupPut(updateInitialSetupRequest?: UpdateInitialSetupRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiInitialSetupUpdateInitialSetupPut(updateInitialSetupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InitialSetupApi - object-oriented interface
 * @export
 * @class InitialSetupApi
 * @extends {BaseAPI}
 */
export class InitialSetupApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialSetupApi
     */
    public apiInitialSetupInitialSetupStatusGet(options?: AxiosRequestConfig) {
        return InitialSetupApiFp(this.configuration).apiInitialSetupInitialSetupStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateInitialSetupRequest} [updateInitialSetupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InitialSetupApi
     */
    public apiInitialSetupUpdateInitialSetupPut(updateInitialSetupRequest?: UpdateInitialSetupRequest, options?: AxiosRequestConfig) {
        return InitialSetupApiFp(this.configuration).apiInitialSetupUpdateInitialSetupPut(updateInitialSetupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsIntegrationGroupsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Integrations/integration-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsPost: async (code?: string, provider?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [integrationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsTestApiKeyGet: async (apiKey?: string, integrationName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Integrations/test-api-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (apiKey !== undefined) {
                localVarQueryParameter['ApiKey'] = apiKey;
            }

            if (integrationName !== undefined) {
                localVarQueryParameter['IntegrationName'] = integrationName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIntegrationsIntegrationGroupsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationGroupLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiIntegrationsIntegrationGroupsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIntegrationsPost(code?: string, provider?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiIntegrationsPost(code, provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [integrationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIntegrationsTestApiKeyGet(apiKey?: string, integrationName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiIntegrationsTestApiKeyGet(apiKey, integrationName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsIntegrationGroupsGet(options?: any): AxiosPromise<Array<IntegrationGroupLogic>> {
            return localVarFp.apiIntegrationsIntegrationGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsPost(code?: string, provider?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiIntegrationsPost(code, provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiKey] 
         * @param {string} [integrationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntegrationsTestApiKeyGet(apiKey?: string, integrationName?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiIntegrationsTestApiKeyGet(apiKey, integrationName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsIntegrationGroupsGet(options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsIntegrationGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsPost(code?: string, provider?: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsPost(code, provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiKey] 
     * @param {string} [integrationName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public apiIntegrationsTestApiKeyGet(apiKey?: string, integrationName?: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).apiIntegrationsTestApiKeyGet(apiKey, integrationName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [model] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageDetectLanguageGet: async (model?: string, content?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/detect-language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (model !== undefined) {
                localVarQueryParameter['Model'] = model;
            }

            if (content !== undefined) {
                localVarQueryParameter['Content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageSupportedLanguagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/supported-languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslateModels} [model] 
         * @param {string} [text] 
         * @param {string} [targetLanguage] 
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageTranslateTextGet: async (model?: TranslateModels, text?: string, targetLanguage?: string, sourceLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/translate-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (model !== undefined) {
                localVarQueryParameter['Model'] = model;
            }

            if (text !== undefined) {
                localVarQueryParameter['Text'] = text;
            }

            if (targetLanguage !== undefined) {
                localVarQueryParameter['TargetLanguage'] = targetLanguage;
            }

            if (sourceLanguage !== undefined) {
                localVarQueryParameter['SourceLanguage'] = sourceLanguage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [model] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageDetectLanguageGet(model?: string, content?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageDetectLanguageGet(model, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageSupportedLanguagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageSupportedLanguagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TranslateModels} [model] 
         * @param {string} [text] 
         * @param {string} [targetLanguage] 
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageTranslateTextGet(model?: TranslateModels, text?: string, targetLanguage?: string, sourceLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageTranslateTextGet(model, text, targetLanguage, sourceLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [model] 
         * @param {string} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageDetectLanguageGet(model?: string, content?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiLanguageDetectLanguageGet(model, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageSupportedLanguagesGet(options?: any): AxiosPromise<Array<LanguageViewModel>> {
            return localVarFp.apiLanguageSupportedLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslateModels} [model] 
         * @param {string} [text] 
         * @param {string} [targetLanguage] 
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageTranslateTextGet(model?: TranslateModels, text?: string, targetLanguage?: string, sourceLanguage?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiLanguageTranslateTextGet(model, text, targetLanguage, sourceLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @param {string} [model] 
     * @param {string} [content] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageDetectLanguageGet(model?: string, content?: string, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLanguageDetectLanguageGet(model, content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageSupportedLanguagesGet(options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLanguageSupportedLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslateModels} [model] 
     * @param {string} [text] 
     * @param {string} [targetLanguage] 
     * @param {string} [sourceLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageTranslateTextGet(model?: TranslateModels, text?: string, targetLanguage?: string, sourceLanguage?: string, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLanguageTranslateTextGet(model, text, targetLanguage, sourceLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LlmApi - axios parameter creator
 * @export
 */
export const LlmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLlmFineTuneModelsConnectionIdGet: async (connectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('apiLlmFineTuneModelsConnectionIdGet', 'connectionId', connectionId)
            const localVarPath = `/api/Llm/fine-tune-models/{connectionId}`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LlmApi - functional programming interface
 * @export
 */
export const LlmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LlmApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLlmFineTuneModelsConnectionIdGet(connectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLlmFineTuneModelsConnectionIdGet(connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LlmApi - factory interface
 * @export
 */
export const LlmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LlmApiFp(configuration)
    return {
        /**
         * 
         * @param {number} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLlmFineTuneModelsConnectionIdGet(connectionId: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLlmFineTuneModelsConnectionIdGet(connectionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LlmApi - object-oriented interface
 * @export
 * @class LlmApi
 * @extends {BaseAPI}
 */
export class LlmApi extends BaseAPI {
    /**
     * 
     * @param {number} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LlmApi
     */
    public apiLlmFineTuneModelsConnectionIdGet(connectionId: number, options?: AxiosRequestConfig) {
        return LlmApiFp(this.configuration).apiLlmFineTuneModelsConnectionIdGet(connectionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationAllNotificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notification/all-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNotificationRequest} [createNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationPost: async (createNotificationRequest?: CreateNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notification/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notification/notification-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationSettingsLogic} [notificationSettingsLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationSettingsPut: async (notificationSettingsLogic?: NotificationSettingsLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notification/notification-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSettingsLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationsGet: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notification/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNotificationsRequest} [updateNotificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationsPut: async (updateNotificationsRequest?: UpdateNotificationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notification/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationAllNotificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationAllNotificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateNotificationRequest} [createNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationNotificationPost(createNotificationRequest?: CreateNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationNotificationPost(createNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationNotificationSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationNotificationSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationSettingsLogic} [notificationSettingsLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationNotificationSettingsPut(notificationSettingsLogic?: NotificationSettingsLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationNotificationSettingsPut(notificationSettingsLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationNotificationsGet(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationNotificationsGet(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateNotificationsRequest} [updateNotificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationNotificationsPut(updateNotificationsRequest?: UpdateNotificationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationNotificationsPut(updateNotificationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationAllNotificationsGet(options?: any): AxiosPromise<Array<NotificationLogic>> {
            return localVarFp.apiNotificationAllNotificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateNotificationRequest} [createNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationPost(createNotificationRequest?: CreateNotificationRequest, options?: any): AxiosPromise<NotificationLogic> {
            return localVarFp.apiNotificationNotificationPost(createNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationSettingsGet(options?: any): AxiosPromise<NotificationSettingsLogic> {
            return localVarFp.apiNotificationNotificationSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationSettingsLogic} [notificationSettingsLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationSettingsPut(notificationSettingsLogic?: NotificationSettingsLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationNotificationSettingsPut(notificationSettingsLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationsGet(skip?: number, take?: number, options?: any): AxiosPromise<Array<NotificationLogic>> {
            return localVarFp.apiNotificationNotificationsGet(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNotificationsRequest} [updateNotificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationNotificationsPut(updateNotificationsRequest?: UpdateNotificationsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiNotificationNotificationsPut(updateNotificationsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationAllNotificationsGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationAllNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateNotificationRequest} [createNotificationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationNotificationPost(createNotificationRequest?: CreateNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationNotificationPost(createNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationNotificationSettingsGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationNotificationSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationSettingsLogic} [notificationSettingsLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationNotificationSettingsPut(notificationSettingsLogic?: NotificationSettingsLogic, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationNotificationSettingsPut(notificationSettingsLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationNotificationsGet(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationNotificationsGet(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNotificationsRequest} [updateNotificationsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiNotificationNotificationsPut(updateNotificationsRequest?: UpdateNotificationsRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiNotificationNotificationsPut(updateNotificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OAuthCallbackApi - axios parameter creator
 * @export
 */
export const OAuthCallbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOauthCallbackGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/oauth-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthCallbackApi - functional programming interface
 * @export
 */
export const OAuthCallbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthCallbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOauthCallbackGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOauthCallbackGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthCallbackApi - factory interface
 * @export
 */
export const OAuthCallbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthCallbackApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOauthCallbackGet(options?: any): AxiosPromise<void> {
            return localVarFp.authOauthCallbackGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthCallbackApi - object-oriented interface
 * @export
 * @class OAuthCallbackApi
 * @extends {BaseAPI}
 */
export class OAuthCallbackApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthCallbackApi
     */
    public authOauthCallbackGet(options?: AxiosRequestConfig) {
        return OAuthCallbackApiFp(this.configuration).authOauthCallbackGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OidcConfigurationApi - axios parameter creator
 * @export
 */
export const OidcConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationClientIdGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('configurationClientIdGet', 'clientId', clientId)
            const localVarPath = `/_configuration/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OidcConfigurationApi - functional programming interface
 * @export
 */
export const OidcConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OidcConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationClientIdGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationClientIdGet(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OidcConfigurationApi - factory interface
 * @export
 */
export const OidcConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OidcConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationClientIdGet(clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.configurationClientIdGet(clientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OidcConfigurationApi - object-oriented interface
 * @export
 * @class OidcConfigurationApi
 * @extends {BaseAPI}
 */
export class OidcConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcConfigurationApi
     */
    public configurationClientIdGet(clientId: string, options?: AxiosRequestConfig) {
        return OidcConfigurationApiFp(this.configuration).configurationClientIdGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [payload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationInviteUserDataGet: async (payload?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/invite-user-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (payload !== undefined) {
                localVarQueryParameter['Payload'] = payload;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteUserRequest} [inviteUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationInviteUserPost: async (inviteUserRequest?: InviteUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/invite-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUpdateUserPut: async (updateUserRequest?: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/update-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUsersUserIdDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiOrganizationUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/api/Organization/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [payload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationInviteUserDataGet(payload?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationInviteUserDataGet(payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InviteUserRequest} [inviteUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationInviteUserPost(inviteUserRequest?: InviteUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationInviteUserPost(inviteUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationUpdateUserPut(updateUserRequest?: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationUpdateUserPut(updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationUsersUserIdDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationUsersUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [payload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationInviteUserDataGet(payload?: string, options?: any): AxiosPromise<InviteUserData> {
            return localVarFp.apiOrganizationInviteUserDataGet(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InviteUserRequest} [inviteUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationInviteUserPost(inviteUserRequest?: InviteUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationInviteUserPost(inviteUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUpdateUserPut(updateUserRequest?: UpdateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationUpdateUserPut(updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUserGet(options?: any): AxiosPromise<UserLogic> {
            return localVarFp.apiOrganizationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUsersGet(options?: any): AxiosPromise<Array<UserLogic>> {
            return localVarFp.apiOrganizationUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationUsersUserIdDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationUsersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @param {string} [payload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationInviteUserDataGet(payload?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationInviteUserDataGet(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InviteUserRequest} [inviteUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationInviteUserPost(inviteUserRequest?: InviteUserRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationInviteUserPost(inviteUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationUpdateUserPut(updateUserRequest?: UpdateUserRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationUpdateUserPut(updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationUserGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationUsersGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationUsersUserIdDelete(userId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationUsersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonaApi - axios parameter creator
 * @export
 */
export const PersonaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeletePersonaRequest} [deletePersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaDelete: async (deletePersonaRequest?: DeletePersonaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePersonaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaPost: async (upsertPersonaRequest?: UpsertPersonaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPersonaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaPut: async (upsertPersonaRequest?: UpsertPersonaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPersonaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonaApi - functional programming interface
 * @export
 */
export const PersonaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeletePersonaRequest} [deletePersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPersonaDelete(deletePersonaRequest?: DeletePersonaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPersonaDelete(deletePersonaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPersonaGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonaLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPersonaGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPersonaPost(upsertPersonaRequest?: UpsertPersonaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonaLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPersonaPost(upsertPersonaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPersonaPut(upsertPersonaRequest?: UpsertPersonaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPersonaPut(upsertPersonaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonaApi - factory interface
 * @export
 */
export const PersonaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonaApiFp(configuration)
    return {
        /**
         * 
         * @param {DeletePersonaRequest} [deletePersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaDelete(deletePersonaRequest?: DeletePersonaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPersonaDelete(deletePersonaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaGet(options?: any): AxiosPromise<Array<PersonaLogic>> {
            return localVarFp.apiPersonaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaPost(upsertPersonaRequest?: UpsertPersonaRequest, options?: any): AxiosPromise<PersonaLogic> {
            return localVarFp.apiPersonaPost(upsertPersonaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonaPut(upsertPersonaRequest?: UpsertPersonaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPersonaPut(upsertPersonaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonaApi - object-oriented interface
 * @export
 * @class PersonaApi
 * @extends {BaseAPI}
 */
export class PersonaApi extends BaseAPI {
    /**
     * 
     * @param {DeletePersonaRequest} [deletePersonaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public apiPersonaDelete(deletePersonaRequest?: DeletePersonaRequest, options?: AxiosRequestConfig) {
        return PersonaApiFp(this.configuration).apiPersonaDelete(deletePersonaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public apiPersonaGet(options?: AxiosRequestConfig) {
        return PersonaApiFp(this.configuration).apiPersonaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public apiPersonaPost(upsertPersonaRequest?: UpsertPersonaRequest, options?: AxiosRequestConfig) {
        return PersonaApiFp(this.configuration).apiPersonaPost(upsertPersonaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertPersonaRequest} [upsertPersonaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public apiPersonaPut(upsertPersonaRequest?: UpsertPersonaRequest, options?: AxiosRequestConfig) {
        return PersonaApiFp(this.configuration).apiPersonaPut(upsertPersonaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromptsApi - axios parameter creator
 * @export
 */
export const PromptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PromptLogic} [promptLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsCreatePromptPost: async (promptLogic?: PromptLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/create-prompt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsCreatePromptRepositoryPost: async (promptRepositoryLogic?: PromptRepositoryLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/create-prompt-repository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptRepositoryLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsDeletePromptDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/delete-prompt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsDeletePromptRepositoryDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/delete-prompt-repository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsPromptRepositoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/prompt-repositories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} promptRepositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsPromptsPromptRepositoryIdGet: async (promptRepositoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptRepositoryId' is not null or undefined
            assertParamExists('apiPromptsPromptsPromptRepositoryIdGet', 'promptRepositoryId', promptRepositoryId)
            const localVarPath = `/api/Prompts/prompts/{promptRepositoryId}`
                .replace(`{${"promptRepositoryId"}}`, encodeURIComponent(String(promptRepositoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromptLogic} [promptLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsUpdatePromptPut: async (promptLogic?: PromptLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/update-prompt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsUpdatePromptRepositoryPut: async (promptRepositoryLogic?: PromptRepositoryLogic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Prompts/update-prompt-repository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptRepositoryLogic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromptsApi - functional programming interface
 * @export
 */
export const PromptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PromptLogic} [promptLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsCreatePromptPost(promptLogic?: PromptLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsCreatePromptPost(promptLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsCreatePromptRepositoryPost(promptRepositoryLogic?: PromptRepositoryLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptRepositoryLogic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsCreatePromptRepositoryPost(promptRepositoryLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsDeletePromptDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsDeletePromptDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsDeletePromptRepositoryDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsDeletePromptRepositoryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsPromptRepositoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromptRepositoryLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsPromptRepositoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} promptRepositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsPromptsPromptRepositoryIdGet(promptRepositoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromptLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsPromptsPromptRepositoryIdGet(promptRepositoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromptLogic} [promptLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsUpdatePromptPut(promptLogic?: PromptLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsUpdatePromptPut(promptLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPromptsUpdatePromptRepositoryPut(promptRepositoryLogic?: PromptRepositoryLogic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPromptsUpdatePromptRepositoryPut(promptRepositoryLogic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromptsApi - factory interface
 * @export
 */
export const PromptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromptsApiFp(configuration)
    return {
        /**
         * 
         * @param {PromptLogic} [promptLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsCreatePromptPost(promptLogic?: PromptLogic, options?: any): AxiosPromise<PromptLogic> {
            return localVarFp.apiPromptsCreatePromptPost(promptLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsCreatePromptRepositoryPost(promptRepositoryLogic?: PromptRepositoryLogic, options?: any): AxiosPromise<PromptRepositoryLogic> {
            return localVarFp.apiPromptsCreatePromptRepositoryPost(promptRepositoryLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsDeletePromptDelete(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiPromptsDeletePromptDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsDeletePromptRepositoryDelete(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiPromptsDeletePromptRepositoryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsPromptRepositoriesGet(options?: any): AxiosPromise<Array<PromptRepositoryLogic>> {
            return localVarFp.apiPromptsPromptRepositoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} promptRepositoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsPromptsPromptRepositoryIdGet(promptRepositoryId: number, options?: any): AxiosPromise<Array<PromptLogic>> {
            return localVarFp.apiPromptsPromptsPromptRepositoryIdGet(promptRepositoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromptLogic} [promptLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsUpdatePromptPut(promptLogic?: PromptLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiPromptsUpdatePromptPut(promptLogic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPromptsUpdatePromptRepositoryPut(promptRepositoryLogic?: PromptRepositoryLogic, options?: any): AxiosPromise<void> {
            return localVarFp.apiPromptsUpdatePromptRepositoryPut(promptRepositoryLogic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromptsApi - object-oriented interface
 * @export
 * @class PromptsApi
 * @extends {BaseAPI}
 */
export class PromptsApi extends BaseAPI {
    /**
     * 
     * @param {PromptLogic} [promptLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsCreatePromptPost(promptLogic?: PromptLogic, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsCreatePromptPost(promptLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsCreatePromptRepositoryPost(promptRepositoryLogic?: PromptRepositoryLogic, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsCreatePromptRepositoryPost(promptRepositoryLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsDeletePromptDelete(id?: number, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsDeletePromptDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsDeletePromptRepositoryDelete(id?: number, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsDeletePromptRepositoryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsPromptRepositoriesGet(options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsPromptRepositoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} promptRepositoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsPromptsPromptRepositoryIdGet(promptRepositoryId: number, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsPromptsPromptRepositoryIdGet(promptRepositoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromptLogic} [promptLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsUpdatePromptPut(promptLogic?: PromptLogic, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsUpdatePromptPut(promptLogic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromptRepositoryLogic} [promptRepositoryLogic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptsApi
     */
    public apiPromptsUpdatePromptRepositoryPut(promptRepositoryLogic?: PromptRepositoryLogic, options?: AxiosRequestConfig) {
        return PromptsApiFp(this.configuration).apiPromptsUpdatePromptRepositoryPut(promptRepositoryLogic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProcessProviderActionRequest} [processProviderActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProviderProcessPost: async (processProviderActionRequest?: ProcessProviderActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Provider/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processProviderActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProviderProviderMethodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Provider/provider-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProcessProviderActionRequest} [processProviderActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProviderProcessPost(processProviderActionRequest?: ProcessProviderActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProviderProcessPost(processProviderActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProviderProviderMethodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProviderProviderMethodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {ProcessProviderActionRequest} [processProviderActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProviderProcessPost(processProviderActionRequest?: ProcessProviderActionRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiProviderProcessPost(processProviderActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProviderProviderMethodsGet(options?: any): AxiosPromise<Array<ProviderMethod>> {
            return localVarFp.apiProviderProviderMethodsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     * 
     * @param {ProcessProviderActionRequest} [processProviderActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public apiProviderProcessPost(processProviderActionRequest?: ProcessProviderActionRequest, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).apiProviderProcessPost(processProviderActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public apiProviderProviderMethodsGet(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).apiProviderProviderMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeCancelSubscriptionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/cancel-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeCheckoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeneratePaymentLinkRequest} [generatePaymentLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeGeneratePaymentLinkPost: async (generatePaymentLinkRequest?: GeneratePaymentLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/generate-payment-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generatePaymentLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeReactivateSubscriptionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/reactivate-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeTestPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSubscriptionPlanRequest} [updateSubscriptionPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeUpdateSubscriptionPlanPost: async (updateSubscriptionPlanRequest?: UpdateSubscriptionPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/update-subscription-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeWebhookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeCancelSubscriptionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeCancelSubscriptionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeCheckoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeCheckoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GeneratePaymentLinkRequest} [generatePaymentLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeGeneratePaymentLinkPost(generatePaymentLinkRequest?: GeneratePaymentLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeGeneratePaymentLinkPost(generatePaymentLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeReactivateSubscriptionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeReactivateSubscriptionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeTestPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeTestPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSubscriptionPlanRequest} [updateSubscriptionPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeUpdateSubscriptionPlanPost(updateSubscriptionPlanRequest?: UpdateSubscriptionPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeUpdateSubscriptionPlanPost(updateSubscriptionPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripeWebhookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeWebhookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeCancelSubscriptionPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiStripeCancelSubscriptionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeCheckoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiStripeCheckoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneratePaymentLinkRequest} [generatePaymentLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeGeneratePaymentLinkPost(generatePaymentLinkRequest?: GeneratePaymentLinkRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiStripeGeneratePaymentLinkPost(generatePaymentLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeReactivateSubscriptionPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiStripeReactivateSubscriptionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeTestPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiStripeTestPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSubscriptionPlanRequest} [updateSubscriptionPlanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeUpdateSubscriptionPlanPost(updateSubscriptionPlanRequest?: UpdateSubscriptionPlanRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiStripeUpdateSubscriptionPlanPost(updateSubscriptionPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripeWebhookPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiStripeWebhookPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeCancelSubscriptionPost(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeCancelSubscriptionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeCheckoutPost(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeCheckoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratePaymentLinkRequest} [generatePaymentLinkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeGeneratePaymentLinkPost(generatePaymentLinkRequest?: GeneratePaymentLinkRequest, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeGeneratePaymentLinkPost(generatePaymentLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeReactivateSubscriptionPost(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeReactivateSubscriptionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeTestPost(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeTestPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSubscriptionPlanRequest} [updateSubscriptionPlanRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeUpdateSubscriptionPlanPost(updateSubscriptionPlanRequest?: UpdateSubscriptionPlanRequest, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeUpdateSubscriptionPlanPost(updateSubscriptionPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public apiStripeWebhookPost(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).apiStripeWebhookPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionBillingInfoBasicGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/billing-info-basic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionBillingInfoDetailedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/billing-info-detailed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionTiersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscription/tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionBillingInfoBasicGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingBasicInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionBillingInfoBasicGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionBillingInfoDetailedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDetailedInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionBillingInfoDetailedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionTiersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionTier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionTiersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionBillingInfoBasicGet(options?: any): AxiosPromise<BillingBasicInfo> {
            return localVarFp.apiSubscriptionBillingInfoBasicGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionBillingInfoDetailedGet(options?: any): AxiosPromise<BillingDetailedInfo> {
            return localVarFp.apiSubscriptionBillingInfoDetailedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionTiersGet(options?: any): AxiosPromise<Array<SubscriptionTier>> {
            return localVarFp.apiSubscriptionTiersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionBillingInfoBasicGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionBillingInfoBasicGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionBillingInfoDetailedGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionBillingInfoDetailedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiSubscriptionTiersGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiSubscriptionTiersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateAiflowTemplateGroupsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Template/aiflow-template-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTemplateAiflowTemplateGroupsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiFlowTemplateGroupLogic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTemplateAiflowTemplateGroupsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateAiflowTemplateGroupsGet(options?: any): AxiosPromise<Array<AiFlowTemplateGroupLogic>> {
            return localVarFp.apiTemplateAiflowTemplateGroupsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public apiTemplateAiflowTemplateGroupsGet(options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).apiTemplateAiflowTemplateGroupsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserSettingsRequest} [updateUserSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingsPut: async (updateUserSettingsRequest?: UpdateUserSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserSettingsRequest} [updateUserSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSettingsPut(updateUserSettingsRequest?: UpdateUserSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSettingsPut(updateUserSettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingsGet(options?: any): AxiosPromise<UserSettingsViewModel> {
            return localVarFp.apiUserSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserSettingsRequest} [updateUserSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSettingsPut(updateUserSettingsRequest?: UpdateUserSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSettingsPut(updateUserSettingsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiUserSettingsGet(options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiUserSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserSettingsRequest} [updateUserSettingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiUserSettingsPut(updateUserSettingsRequest?: UpdateUserSettingsRequest, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiUserSettingsPut(updateUserSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


