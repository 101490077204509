import { useSortable } from '@dnd-kit/sortable';
import {
  Avatar,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  ActionInfo,
  InputDataLogic,
  AiFlowActionGroupLogic,
  AiFlowRunOutputLogic,
  ConfigurationLogic,
} from 'src/api';
import { ACTION_ICONS } from 'src/common/constants/icons.constants';
import Iconify from 'src/components/iconify';
import { SmallActionIcon } from 'src/components/nav-section/mini/styles';
import {
  addAiFlowItem,
  deleteAiFlowItem,
  selectAiFlowItemBySequence,
  selectAiFlowItem,
  setCopiedItems,
} from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';
import { CSS } from '@dnd-kit/utilities';
import { AiFlowItem } from 'src/@types/aiflow';
import _ from 'lodash';
import ItemProcessingSpinner from './ItemProcessingSpinner';
import { useSnackbar } from 'src/components/snackbar';
import ConfirmDialog from 'src/components/confirm-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import MenuPopover from 'src/components/menu-popover';

interface Props {
  item: AiFlowItem;
  index: number;
  children?: React.ReactNode;
}

export default function AiFlowAction({ item, index, children, ...other }: Props) {
  const [actionInfo, setActionInfo] = useState<ActionInfo | undefined>(undefined);
  const [actionGroup, setActionGroup] = useState<AiFlowActionGroupLogic>();
  const [promptInput, setPromptInput] = useState<string>('');
  const [openCopyPopover, setOpenCopyPopover] = useState<HTMLElement | null>(null);
  const [opened, setOpened] = useState(false);
  const [openDeletePopover, setOpenDeletePopover] = useState<HTMLElement | null>(null);
  const [configuration, setConfigurations] = useState<ConfigurationLogic>();
  const [itemOutput, setItemOutput] = useState<AiFlowRunOutputLogic>(); // TODO: [Val] - change to AiFlowItemOutput
  const selectedAiFlowRun = useSelector((state) => state.aiFlowsRuns.selectedAiFlowRun);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const runOutputs = useSelector((state) => state.aiFlowsRuns.aiFlowRunsOutputs);
  const actionGroups = useSelector((state) => state.aiFlows.actionGroups);
  const savingAiFlow = useSelector((state) => state.aiFlows.savingAiFlow);
  const [mouseEvent, setMouseEvent] = useState<any | null>(null);
  const openDeleteConfirm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const itemRef = useRef(item);
  useEffect(() => {
    // const wfItems = selectedAiFlow?.items;
    // const currentItem = wfItems?.find((wfItem) => wfItem.sequence === item.sequence);
    // if (currentItem && !_.isEqual(item, currentItem)) {
    //   itemRef.current = currentItem as AiFlowItem;
    // } else {
    //   itemRef.current = item;
    // }
    // const newItem = itemRef.current;
    if (item && item.actionInfo) {
      setActionInfo(item.actionInfo);
    }
    if (item.inputData) {
      const itemInputData = item.inputData as InputDataLogic;
      const tmpPromptInput = itemInputData?.actionInputData?.promptInput;
      const tmpConfigurations = itemInputData?.actionInputData?.configuration;
      setPromptInput(tmpPromptInput ?? '');
      setConfigurations(tmpConfigurations);
    }
  }, [item, selectedAiFlow]);

  useEffect(() => {
    if (runOutputs && runOutputs[selectedAiFlowRun?.id as number]) {
      const tmpRunOutput = runOutputs[selectedAiFlowRun?.id as number].find(
        (output) => output.aiFlowItemId === item.id
      );
      if (tmpRunOutput) {
        setItemOutput(tmpRunOutput);
      } else {
        setItemOutput(undefined);
      }
    }
  }, [runOutputs, selectedAiFlowRun, item]);

  useEffect(() => {
    if (actionGroups) {
      const tmpActionGroup = actionGroups.find(
        (group) =>
          group.actions &&
          group.actions?.find(
            (groupAction) => groupAction && groupAction.header === item.actionInfo?.header
          ) !== undefined
      );
      setActionGroup(tmpActionGroup);
    }
  }, [actionGroups, item]);

  const dispatch = useDispatch();

  // const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
  //   id: item.sequence?.toString() as string,
  // });

  // const style = {
  //   transform: CSS.Transform.toString(transform),
  //   transition: transition || undefined,
  // };

  const getModelLogo = () => {
    console.log('configuration', configuration);
    if (!configuration || (configuration && configuration.modelProvider?.includes('OpenAI'))) {
      return 'OpenAI';
    } else if (configuration.model?.toLowerCase().includes('gemini')) {
      return 'Gemini';
    } else if (configuration.model?.toLowerCase().includes('claude')) {
      return 'Claude';
    }
    return 'OpenAI';
  };

  return (
    <>
      <Card
        //    ref={setNodeRef}
        sx={{
          p: 2,
          //    cursor: 'pointer',
          ...(item &&
            !item.name &&
            actionGroup && { border: '1px solid', borderColor: 'error.main' }),
          cursor: 'pointer !important',
          position: 'relative',
          maxHeight: 150,
          minHeight: 150,
          height: 150,
          minWidth: 300,
          maxWidth: 300,
          '&:hover': {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z24,
          },
          // '&:hover': { border: '0.5px solid', borderColor: 'primary.main' },
          ...(item &&
            selectedItem &&
            item.sequence === selectedItem?.sequence && {
              border: '1px solid',
              borderColor: 'primary.main',
            }),
          ...(selectedAiFlowRun?.aiFlowId === item?.aiFlowId &&
            selectedAiFlowRun?.status === 'InProgress' &&
            runsMode &&
            (selectedAiFlowRun?.sequence as number) < (item.sequence as number) && {
              opacity: 0.5,
            }),
        }}
        onClick={(event: any) => {
          if (opened) {
            return;
          }
          const processed =
            (selectedAiFlowRun?.aiFlowId === item?.aiFlowId &&
              selectedAiFlowRun?.status === 'InProgress' &&
              (selectedAiFlowRun?.sequence as number) > (item.sequence as number)) ||
            selectedAiFlowRun?.status === 'Completed';
          if (runsMode) {
            dispatch(selectAiFlowItem(item));
          } else if (processed || !runsMode) {
            console.log('item', item);
            dispatch(selectAiFlowItemBySequence(item.sequence));
          }
        }}
      >
        <Stack spacing={1} alignSelf="center" alignItems="start">
          <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" spacing={3}>
            {/* <StyledIcon>{ICONS.configurations}</StyledIcon> */}
            <Typography sx={{ ml: 1, textDecoration: 'underline' }} variant="subtitle2">
              {actionGroup?.label}
            </Typography>
            <Tooltip title={item.name}>
              <div
                style={{
                  overflow: 'hidden',
                  fontSize: '0.875rem',
                  fontFamily: 'Public Sans,sans-serif',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: runsMode ? '6ch' : '12ch',
                  minWidth: runsMode ? '6ch' : '12ch',
                }}
              >
                {item.name}
              </div>
            </Tooltip>
            {runsMode &&
              itemOutput &&
              (item?.aiFlowId ? selectedAiFlowRun?.aiFlowId === item?.aiFlowId : true) && (
                <>
                  {selectedAiFlowRun?.sequence === item.sequence &&
                    itemOutput?.status === 'InProcess' && <ItemProcessingSpinner />}
                  {itemOutput?.status === 'Completed' && (
                    <Iconify
                      icon="mdi:success-circle-outline"
                      sx={{ color: 'primary.main', height: 24, width: 24 }}
                    />
                  )}
                  {itemOutput?.status === 'On Hold' && (
                    <Iconify
                      icon="carbon:pause-outline"
                      sx={{ color: 'warning.main', height: 24, width: 24 }}
                    />
                  )}
                  {itemOutput?.status === 'Failed' && (
                    <Iconify
                      icon="mdi:error-outline"
                      sx={{ color: 'error.main', height: 24, width: 24 }}
                    />
                  )}
                </>
              )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <SmallActionIcon>{ACTION_ICONS[actionInfo?.icon as string]}</SmallActionIcon>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={{ ml: 1 }} variant="h5">
                {item?.sequence && `${item.sequence}.`} {actionInfo?.actionLabel}
              </Typography>
              {item.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchName &&
              (
                item.inputData?.actionInputData?.branchingInputData?.branchInputData
                  ?.branchName as string
              ).length ? (
                <Chip
                  label={
                    <>
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: `10ch`,
                        }}
                      >
                        {
                          item.inputData?.actionInputData?.branchingInputData?.branchInputData
                            ?.branchName
                        }
                      </div>
                    </>
                  }
                  size="small"
                  color="info"
                  variant="soft"
                />
              ) : null}
            </Stack>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed', width: '100%' }} />

          <Stack
            sx={{ minWidth: '100%' }}
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Stack direction="row" spacing={1}>
              {item && item.header === 'ImportContent' && (
                <IconButton
                  disableRipple
                  sx={{
                    cursor: 'default',
                    color: !promptInput ? 'lightgrey' : 'inherit',
                  }}
                >
                  <Iconify icon="material-symbols:input" />
                </IconButton>
              )}
              {item &&
                item.header === 'Validator' &&
                item?.inputData?.actionInputData?.functionalInputData?.validatorInput
                  ?.validatorType === 'Manual' && (
                  <IconButton disableRipple>
                    <Iconify icon="hugeicons:validation-approval" />
                  </IconButton>
                )}
              {item && item.header !== 'ImportContent' && (
                <>
                  {/* <IconButton
                    disableRipple
                    sx={{
                      cursor: 'default',
                      color: !promptInput ? 'lightgrey' : 'inherit',
                    }}
                  >
                    <StyledIcon>{ACTION_ICONS.prompts}</StyledIcon>
                  </IconButton> */}
                  {actionGroup?.label === 'Generative AI' && (
                    <IconButton
                      disableRipple
                      sx={{
                        cursor: 'default',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Avatar
                        src={`/assets/images/integrations/${getModelLogo()}.svg`}
                        sx={{ width: 24, height: 24 }}
                      />
                    </IconButton>
                  )}{' '}
                  {item?.header === 'Input' &&
                    (item?.inputData?.actionInputData?.inputData?.inputType === 'Input' ||
                      !item?.inputData?.actionInputData?.inputData?.inputType) && (
                      <IconButton
                        disableRipple
                        sx={{
                          cursor: 'default',
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Iconify icon="weui:text-outlined" />
                      </IconButton>
                    )}
                  {item?.header === 'Input' &&
                    item?.inputData?.actionInputData?.inputData?.inputType === 'File' && (
                      <IconButton
                        disableRipple
                        sx={{
                          cursor: 'default',
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Iconify icon="bx:file" />
                      </IconButton>
                    )}
                  {item?.header === 'Trigger' && (
                    <>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        {item?.inputData?.actionInputData?.triggerData?.triggerType ===
                          'Web Hook' && (
                          <IconButton
                            disableRipple
                            sx={{
                              cursor: 'default',
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Iconify icon="mdi:webhook" />
                          </IconButton>
                        )}
                        {item?.inputData?.actionInputData?.triggerData?.webhookTriggerData
                          ?.eventType && (
                          <Typography variant="subtitle2" color="textSecondary">
                            {
                              item?.inputData?.actionInputData?.triggerData?.webhookTriggerData
                                ?.eventType
                            }
                          </Typography>
                        )}
                      </Stack>

                      {(item?.inputData?.actionInputData?.triggerData?.triggerType === 'Provider' ||
                        !item?.inputData?.actionInputData?.triggerData?.triggerType) &&
                        item?.inputData?.actionInputData?.triggerData?.providerTriggerData
                          ?.provider && (
                          <>
                            <Stack alignItems="center" direction="row" spacing={1}>
                              <IconButton
                                disableRipple
                                sx={{
                                  cursor: 'default',
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Avatar
                                  src={`/assets/images/integrations/${item?.inputData?.actionInputData?.triggerData?.providerTriggerData?.provider}.svg`}
                                  sx={{ width: 24, height: 24 }}
                                />
                              </IconButton>
                              {item?.inputData?.actionInputData?.triggerData?.providerTriggerData?.webhookEvents?.map(
                                (event) => (
                                  <Chip
                                    key={event}
                                    label={event}
                                    size="small"
                                    color="info"
                                    variant="soft"
                                  />
                                )
                              )}
                            </Stack>
                          </>
                        )}
                    </>
                  )}
                  {item?.header === 'Input' &&
                    item?.inputData?.actionInputData?.inputData?.providerData && (
                      <>
                        <Stack alignItems="center" direction="row" spacing={1}>
                          <IconButton
                            disableRipple
                            sx={{
                              cursor: 'default',
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Avatar
                              src={`/assets/images/integrations/${item?.inputData?.actionInputData?.inputData?.providerData?.provider}.svg`}
                              sx={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                          {item?.inputData?.actionInputData?.inputData?.providerData?.method && (
                            <Typography variant="subtitle2" color="textSecondary">
                              {item?.inputData?.actionInputData?.inputData?.providerData?.method}
                            </Typography>
                          )}
                        </Stack>
                      </>
                    )}
                  {item?.header === 'Operations' &&
                    item?.inputData?.actionInputData?.operationsInputData && (
                      <>
                        <Stack alignItems="center" direction="row" spacing={1}>
                          <IconButton
                            disableRipple
                            sx={{
                              cursor: 'default',
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Avatar
                              src={`/assets/images/integrations/${item?.inputData?.actionInputData?.operationsInputData?.providerData?.provider}.svg`}
                              sx={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                          {item?.inputData?.actionInputData?.inputData?.providerData?.method && (
                            <Typography variant="subtitle2" color="textSecondary">
                              {item?.inputData?.actionInputData?.inputData?.providerData?.method}
                            </Typography>
                          )}
                        </Stack>
                      </>
                    )}
                  {/* <IconButton
                    disableRipple={!configuration}
                    sx={{
                      cursor: configuration ? 'pointer' : 'default',
                      color: !configuration ? 'lightgrey' : 'inherit',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <StyledIcon>{ACTION_ICONS.configurations}</StyledIcon>
                  </IconButton> */}
                  {/* {item?.header === 'Input' &&
                    item?.inputData?.actionInputData?.inputData?.providerData && (
                      <Avatar
                        src={`/assets/images/integrations/${item?.inputData?.actionInputData?.inputData?.providerData?.provider}.svg`}
                        sx={{ width: 24, height: 24 }}
                      />
                    )} */}
                  {configuration?.loopSettings && (
                    <IconButton
                      disableRipple
                      sx={{
                        cursor: 'default',
                        color: 'inherit',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Iconify icon="mdi:loop" />
                    </IconButton>
                  )}
                  {/* <IconButton
                    disableRipple
                    sx={{
                      cursor: 'default',
                      color: 'inherit',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Iconify icon="mdi:multiplex" />
                  </IconButton> */}
                </>
              )}
              {item && !!item.aiFlowItemNotes?.length && (
                <IconButton
                  disableRipple
                  sx={{
                    cursor: 'default',
                  }}
                >
                  <Iconify icon="uil:notes" />
                </IconButton>
              )}
              {item?.inputData?.actionInputData?.branchingInputData?.branchesInputData?.title && (
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {item?.inputData?.actionInputData?.branchingInputData?.branchesInputData?.title}
                  </Typography>
                </Stack>
              )}
              {item &&
                item.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchType ===
                  'Run Always' && (
                  <IconButton
                    disableRipple
                    sx={{
                      cursor: 'default',
                    }}
                  >
                    <Iconify sx={{ width: 28, height: 28 }} icon="codicon:run-coverage" />
                  </IconButton>
                )}
              {item &&
                item.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchType ===
                  'Condition' && (
                  <IconButton
                    disableRipple
                    sx={{
                      cursor: 'default',
                    }}
                  >
                    <Iconify sx={{ width: 28, height: 28 }} icon="carbon:condition-point" />
                  </IconButton>
                )}
              {item &&
                item.inputData?.actionInputData?.branchingInputData?.branchInputData?.branchType ===
                  'Failover' && (
                  <IconButton
                    disableRipple
                    sx={{
                      cursor: 'default',
                    }}
                  >
                    <Iconify sx={{ width: 28, height: 28 }} icon="carbon:retry-failed" />
                  </IconButton>
                )}
            </Stack>
            {!runsMode && item.header !== 'Trigger' && item.header !== 'Branch' && (
              <Stack direction="row" spacing={0.5} sx={{ paddingBottom: 1, marginBottom: 1 }}>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpened(true);
                    setOpenCopyPopover(event.currentTarget);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();

                    // dispatch(
                    //   addAiFlowItem({
                    //     aiFlowItem: clonedItem,
                    //     addAfter: item.sequence as number,
                    //   })
                    // );
                    //  setOpenCopyPopover(event.currentTarget);
                  }}
                >
                  <Iconify icon="carbon:copy" />
                </IconButton>
                <IconButton
                  disabled={savingAiFlow}
                  onClick={(event) => {
                    setMouseEvent(event);
                    event.stopPropagation();
                    setOpened(true);
                    openDeleteConfirm.onTrue();
                  }}
                >
                  <Iconify icon="eva:trash-2-outline" />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Stack>
        <ConfirmDialog
          stopPropagation
          open={openDeleteConfirm.value}
          onClose={() => {
            setTimeout(() => {
              // dispatch(selectAiFlowItemBySequence(null)); // Unselect the item when the dialog is closed
              setOpened(false);
            }, 0);
            openDeleteConfirm.onFalse();
          }}
          title="Delete"
          content="Are you sure want to delete?"
          action={
            <Button
              variant="contained"
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(
                  deleteAiFlowItem({ sequence: item.sequence as number, header: item.header })
                );
                openDeleteConfirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />
        <MenuPopover
          open={openCopyPopover}
          onClose={() => {
            setOpenCopyPopover(null);

            setTimeout(() => {
              setOpened(false);
              //   dispatch(selectAiFlowItemBySequence(null)); // Unselect the item when the dialog is closed
            }, 0);
          }}
          arrow="right-top"
          sx={{ width: 270 }}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              const clonedItem = _.cloneDeep(item);
              const copiedItems = [clonedItem];
              dispatch(setCopiedItems(copiedItems));
              enqueueSnackbar('Action copied', { variant: 'success' });
              setOpenCopyPopover(null);
            }}
          >
            Copy this action
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              // i need to clone all of the items from the current item to the end of the list
              let copiedItems = [];
              // check if this item is part of a branch, if so copy only the items that are part of the branch from this item and forward
              console.log('itemmm', item);
              for (let i = item.sequence as number; i > 0; i--) {
                if (selectedAiFlow?.items) {
                  const tmpItem = selectedAiFlow.items.find(
                    (wfItem) =>
                      wfItem.sequence === i && wfItem.aiFlowVersionId === item.aiFlowVersionId
                  );
                  if (tmpItem?.header === 'Branch') {
                    debugger;
                    // copy from the item until the end of the branch
                    // find out if the last branch
                    const branchItems = selectedAiFlow.items.filter(
                      (wfItem) =>
                        wfItem.parentSequence === tmpItem?.parentSequence &&
                        wfItem.aiFlowVersionId === item.aiFlowVersionId
                    );
                    const branchItemsSortedBySequence = branchItems.sort(
                      (a, b) => (a.sequence as number) - (b.sequence as number)
                    );
                    if (
                      (tmpItem.sequence as number) <
                      (branchItemsSortedBySequence[branchItemsSortedBySequence.length - 1].sequence as number)
                    ) {
                      const findTheNextBranchFromBranchItemsSortedBySequence =
                        branchItemsSortedBySequence.find(
                          (wfItem) => (wfItem.sequence as number) > (tmpItem.sequence as number)
                        ) as AiFlowItem;
                      const tmpCopiedItems = selectedAiFlow.items.filter(
                        (wfItem) =>
                          (wfItem.sequence as number) >= (item.sequence as number) &&
                          (wfItem.sequence as number) <
                            (findTheNextBranchFromBranchItemsSortedBySequence.sequence as number)
                      );
                      copiedItems = tmpCopiedItems;
                      dispatch(setCopiedItems(copiedItems));
                      console.log('copiedItems', copiedItems);
                      enqueueSnackbar('Actions copied', { variant: 'success' });
                      setOpenCopyPopover(null);
                      return;
                    } else {
                      for (let j = item.sequence as number; j < selectedAiFlow.items.length; j++) {
                        if (
                          (selectedAiFlow.items[j - 1].parentSequence as number) + 1 ===
                            selectedAiFlow.items[j - 1].sequence &&
                          selectedAiFlow.items[j - 1].header !== 'Branch'
                        ) {
                          copiedItems.push(selectedAiFlow.items[j - 1]);
                        } else {
                          break;
                        }
                      }
                      dispatch(setCopiedItems(copiedItems));
                      console.log('copiedItems', copiedItems);
                      enqueueSnackbar('Actions copied', { variant: 'success' });
                      setOpenCopyPopover(null);
                      return;
                    }
                  }
                }
              }

              copiedItems =
                selectedAiFlow?.items?.filter(
                  (wfItem) =>
                    wfItem.sequence && (wfItem.sequence as number) >= (item.sequence as number)
                ) || [];
              console.log('copiedItems', copiedItems);
              dispatch(setCopiedItems(copiedItems));
              enqueueSnackbar('Actions copied', { variant: 'success' });
              setOpenCopyPopover(null);
            }}
          >
            Copy this and following actions
          </MenuItem>
        </MenuPopover>
      </Card>
      {children}
    </>
  );
}
