import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import RenderHTML from 'src/utils/renderHtml';
import Scrollbar from '../scrollbar';
// Choose the style theme you prefer
import { useCallback, useEffect, useState } from 'react';
import Iconify from '../iconify';
import { useSnackbar } from 'src/components/snackbar';
import { useBoolean } from 'src/hooks/use-boolean';
import CodeOutputCard from './CodeOutputCard';
import { MultiFilePreview } from '../upload';
import FullPageDialog from 'src/common/modals/FullPageDialog';
import { useDispatch, useSelector } from 'src/redux/store';
import { setFullscreen } from 'src/redux/slices/aiflows';
import { AiFlowItemLogic, AiFlowRunsApi, FilesApi, RunOutputInfo } from 'src/api';
import { downloadFile } from 'src/utils/fileUtils';
import AiFlowItemOutputInfoDialog from 'src/pages/aiFlows/sidebar/item-output-view/AiFlowItemOutputInfoDialog';
import ConfirmDialog from '../confirm-dialog';
import { LoadingButton } from '@mui/lab';
import { updateRun, updateRunOutput } from 'src/redux/slices/aiFlow-runs';
import { usePopover } from '../custom-popover';
import OutputCommentsPopover from './OutputCommentsPopover';
import FileContentViewerDialog from '../upload/preview/FileContentViewerDialog';
import ConditionResult from '../validation/ConditionResult';

interface TextChunk {
  text: string;
  isCode: boolean;
  language?: string;
}

interface Params {
  content?: string;
  outputInfo?: RunOutputInfo;
  outputId?: number;
  multipleOutputs?: boolean;
  files?: string[];
  status?: string;
  height?: number;
  header?: string;
  fullScreen?: boolean;
}

export default function OutputCard({
  content,
  multipleOutputs,
  height,
  header,
  outputId,
  files,
  fullScreen,
  status,
  outputInfo,
}: Params) {
  const [textChunks, setTextChunks] = useState<TextChunk[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const openFullScreen = useBoolean(false);

  const openOutputInfo = useBoolean(false);
  const openDeleteConfirm = useBoolean();
  const openFileViewer = useBoolean();
  const [selectedManualValidatorAction, setSelectedManualValidatorAction] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [conditionResults, setConditionResults] = useState<any[]>([]);
  const [fileContent, setFileContent] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string>();
  const commentsPopover = usePopover();
  const [viewRawState, setViewRawState] = useState<string>();
  const [formattedContent, setFormattedContent] = useState<string>('');
  const [selectedRunItems, setSelectedRunItems] = useState<AiFlowItemLogic[]>([]);
  const [loadingManualValidation, setLoadingManualValidation] = useState<boolean>(false);
  const [viewContent, setViewContent] = useState<any>();
  const [viewHeader, setViewHeader] = useState<any>();
  const [viewOutputInfo, setViewOutputInfo] = useState<any>();
  const aiFlowRunItems = useSelector((state) => state.aiFlowsRuns.aiFlowRunItems);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const selectedAiFlowRun = useSelector((state) => state.aiFlowsRuns.selectedAiFlowRun);
  const runOutput = useSelector(
    (state) => state.aiFlowsRuns.aiFlowRunsOutputs[selectedAiFlowRun?.id as number]
  );
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  useEffect(() => {
    setFormattedContent(`<p style="line-height: 2">${content?.replace(/\n/g, '<br>')}</p>`);
  }, [content]);

  const getSeverityColorByStatus = (statusInput: string) => {
    switch (statusInput) {
      case 'On Hold':
        return 'warning';
      case 'Completed':
        return 'success';
      case 'Failed':
        return 'error';
      default:
        return 'info';
    }
  };

  const getFileFromCloud = (fileName: string) => {
    const filesApi = new FilesApi();
    let path = '';
    let container = 'inputs';
    if (header === 'Export') {
      container = 'exports';
    } else if (header === 'Transformer') {
      container = 'formatted';
    }
    if (selectedItem?.inputData?.actionInputData?.inputData?.fileInputType === 'Files Repo') {
      container = 'files-repos';
      path = `${selectedItem?.inputData?.actionInputData?.inputData?.filesRepoId ?? 'Temp'}/${
        fileName as string
      }`;
    } else {
      path = `${selectedItem?.id ?? 'Temp'}/${fileName as string}`;
    }
    const response = filesApi.apiFilesDownloadContainerNameGet(container as string, path, {
      responseType: 'blob',
    });
    return response;
  };

  const getFileUrlFromCloud = (fileName: string) => {
    const filesApi = new FilesApi();
    let path = '';
    let container = 'inputs';
    if (header === 'Export') {
      container = 'exports';
    } else if (header === 'Transformer') {
      container = 'formatted';
    }
    if (selectedItem?.inputData?.actionInputData?.inputData?.fileInputType === 'Files Repo') {
      container = 'files-repos';
      path = `${selectedItem?.inputData?.actionInputData?.inputData?.filesRepoId ?? 'Temp'}/${
        fileName as string
      }`;
    } else {
      path = `${selectedItem?.id ?? 'Temp'}/${fileName as string}`;
    }
    const response = filesApi.apiFilesSasContainerNameGet(container as string, path);
    return response;
  };

  const separateTextAndCode = useCallback(
    (inputText: string) => {
      if (!inputText) {
        return [];
      }

      const codeBlockRegex = /```[\s\S]*?```/g;
      const result: TextChunk[] = [];
      if (header === 'ContentAnalysis') {
        result.push({
          isCode: true,
          language: 'markdown',
          text: inputText,
        });
        return result;
      }
      let lastIndex = 0;

      inputText.replace(codeBlockRegex, (match, index) => {
        // Add regular text before the code block
        if (index > lastIndex) {
          result.push({
            isCode: false,
            text: `<p style="line-height: 2">${inputText
              .substring(lastIndex, index)
              .replace(/\n/g, '<br>')}</p>`,
          });
        }

        // Add the code block
        result.push({
          isCode: true,
          language: match.substring(3, match.indexOf('\n')),
          text: match.substring(match.indexOf('\n') + 1, match.length - 3),
        });

        lastIndex = index + match.length;
        return match;
      });

      // Add any remaining regular text after the last code block
      if (lastIndex < inputText.length) {
        result.push({
          isCode: false,
          text: `<p style="line-height: 2">${inputText
            .substring(lastIndex)
            .replace(/\n/g, '<br>')}</p>`,
        });
      }
      return result;
    },
    [header]
  );

  useEffect(() => {
    const separatedText = separateTextAndCode(content as string);
    setTextChunks(separatedText);
  }, [content, separateTextAndCode]);

  const handleChangeViewRawState = (event: React.MouseEvent<HTMLElement>, vrState: string) => {
    setViewRawState(vrState);
  };

  useEffect(() => {
    if (selectedAiFlowRun) {
      const runItems = aiFlowRunItems[selectedAiFlowRun.id as number];
      setSelectedRunItems(runItems);
    }
  }, [aiFlowRunItems, selectedAiFlowRun]);

  useEffect(() => {
    if (selectedItem) {
      if (
        selectedItem?.header === 'Condition' ||
        (selectedItem?.header === 'Branch' &&
          selectedItem?.inputData?.actionInputData?.branchingInputData?.branchInputData
            ?.branchType === 'Condition')
      ) {
        try {
          const contentObj = JSON.parse(content as string);
          if (contentObj) {
            setConditionResults(contentObj);
          }
        } catch (e) {
          console.error('Error parsing content as JSON', e);
        }
      }
    }
  }, [content, selectedItem]);

  return (
    <Card sx={{ p: 3, minHeight: 550, maxHeight: 720, zIndex: 2400 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {header && header.includes('Code') && (
          <ToggleButtonGroup
            onChange={handleChangeViewRawState}
            exclusive
            sx={{ maxWidth: 275 }}
            value={viewRawState}
            aria-label="device"
          >
            {/* <ToggleButton value="viewRaw">
              <Tooltip title="View Raw">
                <Iconify icon="fluent-mdl2:raw-source" height={14} width={14} />
              </Tooltip>
            </ToggleButton> */}
          </ToggleButtonGroup>
        )}
        {true && (
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <Tooltip title="Output Info">
              <IconButton onClick={openOutputInfo.onTrue}>
                <Iconify icon="lucide:info" />
              </IconButton>
            </Tooltip>
            <OutputCommentsPopover outputId={outputId as number} />
            {/* <Tooltip title="Comments">
              <IconButtonAnimate
                color={commentsPopover.open ? 'primary' : 'default'}
                onClick={commentsPopover.onOpen}
                sx={{ width: 40, height: 40 }}
              >
                <Badge  badgeContent={2} color="info">
                  <Iconify icon="ic:outline-comment" />
                </Badge>
              </IconButtonAnimate>
            </Tooltip> */}
          </Stack>
        )}
        <div style={{ flexGrow: 1 }} /> {/* Spacer */}
        {!fullScreen && !multipleOutputs && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Tooltip title="Full screen">
              <IconButton onClick={openFullScreen.onTrue}>
                <Iconify icon="material-symbols:fullscreen" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>
      <Stack
        flexGrow={1}
        sx={{
          width: 1,
          //  zIndex: 2400,
          minWidth: 0,
          mt: 2,
          borderRadius: 1.5,
          bgcolor: 'background.default',
        }}
      >
        <Box sx={{ overflow: 'hidden', flexGrow: 1, p: 2, zIndex: 2400 }}>
          <Scrollbar sx={{ height: { md: height ?? 620 }, zIndex: 2400 }}>
            {runsMode &&
              header === 'Validator' &&
              selectedItem?.inputData?.actionInputData?.functionalInputData?.validatorInput
                ?.validatorType === 'Manual' && (
                <Stack spacing={2} sx={{ mb: 2 }}>
                  <Typography variant="h6">
                    Manual Validation:{' '}
                    {
                      selectedItem?.inputData?.actionInputData?.functionalInputData?.validatorInput
                        ?.manualValidationType
                    }{' '}
                  </Typography>
                  <Typography variant="body1">
                    Description:{' '}
                    {
                      selectedItem?.inputData?.actionInputData?.functionalInputData?.validatorInput
                        ?.manualValidationDescription
                    }
                  </Typography>
                </Stack>
              )}
            {runsMode &&
              (header !== 'Branch' ||
                (header === 'Branch' &&
                  selectedItem?.inputData?.actionInputData?.branchingInputData?.branchInputData
                    ?.branchType !== 'Condition')) &&
              header !== 'Condition' && (
                <>
                  {viewRawState === 'viewRaw' && <RenderHTML htmlString={formattedContent} />}
                  {viewRawState !== 'viewRaw' &&
                    textChunks.map((chunk, index) => {
                      if (chunk.isCode) {
                        return (
                          <CodeOutputCard
                            key={index}
                            text={chunk.text}
                            language={chunk.language as string}
                          />
                        );
                      } else {
                        return <RenderHTML key={index} htmlString={chunk.text} />;
                      }
                    })}
                </>
              )}
            {files && files.length > 0 && (
              <MultiFilePreview
                onView={async (fileName: any) => {
                  const response = await getFileFromCloud(fileName);
                  const urlResponse = await getFileUrlFromCloud(fileName);
                  if (response.data && urlResponse.data) {
                    const fileBlob = response.data;
                    setFile(fileBlob);
                    const textData = await fileBlob.text();
                    setFileContent(textData);
                    // const url = URL.createObjectURL(fileBlob);
                    // setFileUrl(url);
                    console.log('urlResponse.data', urlResponse.data);
                    setFileUrl(urlResponse.data);
                  }
                  openFileViewer.onTrue();
                }}
                onDownload={async (fileName: any) => {
                  const response = await getFileFromCloud(fileName);
                  if (response.data) {
                    // Convert the blob to text
                    const fileBlob = response.data;
                    const textData = await fileBlob.text();
                    console.log('textData', textData);
                    downloadFile(response, fileName);
                  }
                }}
                files={files}
              />
            )}
            {((runsMode &&
              header === 'Branch' &&
              selectedItem?.inputData?.actionInputData?.branchingInputData?.branchInputData
                ?.branchType === 'Condition') ||
              header === 'Condition') && (
              <>
                <Stack direction="column" spacing={1}>
                  {conditionResults
                    ?.filter(
                      (cr) =>
                        (cr.operator === 'AND' || !cr.operator) && cr.condition !== 'OverallResult'
                    )
                    .map((cr, index) => (
                      <ConditionResult
                        onSourceClick={(source) => {
                          const runItem = selectedRunItems.find((i) => i.name === source);
                          if (runItem) {
                            // dispatch(setFullscreen(true));
                            const specificRunOutput = runOutput?.find(
                              (ro) => ro.aiFlowItemId === runItem.id
                            );
                            setViewOutputInfo(specificRunOutput?.output?.outputsInfo);
                            setViewHeader(runItem.header);
                            setViewContent(
                              specificRunOutput?.output?.results &&
                                specificRunOutput?.output?.results.length > 0
                                ? specificRunOutput?.output?.results[0]
                                : undefined
                            );
                            openFullScreen.onTrue();
                          }
                        }}
                        conditionResults={conditionResults?.filter(
                          (tmpCr) =>
                            (tmpCr.operator === 'AND' || !tmpCr.operator) &&
                            tmpCr.condition !== 'OverallResult'
                        )}
                        index={index}
                        conditionResult={cr}
                        selectedRunItems={selectedRunItems}
                      />
                    ))}
                  {conditionResults?.filter(
                    (cr) => cr.operator === 'OR' && cr.condition !== 'OverallResult'
                  )?.length > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Divider sx={{ flexGrow: 1, mb: 1, borderBottomWidth: '4px' }} />
                      <Typography sx={{ mx: 2 }}>OR</Typography>
                      <Divider sx={{ flexGrow: 1, mb: 1, borderBottomWidth: '4px' }} />
                    </Box>
                  )}
                  {conditionResults
                    ?.filter((cr) => cr.operator === 'OR' && cr.condition !== 'OverallResult')
                    .map((cr, index) => (
                      <ConditionResult
                        conditionResults={conditionResults?.filter(
                          (tmpCr) => tmpCr.operator === 'OR' && tmpCr.condition !== 'OverallResult'
                        )}
                        onSourceClick={(source) => {
                          const runItem = selectedRunItems.find((i) => i.name === source);
                          if (runItem) {
                            // dispatch(setFullscreen(true));
                            const specificRunOutput = runOutput?.find(
                              (ro) => ro.aiFlowItemId === runItem.id
                            );
                            setViewOutputInfo(specificRunOutput?.output?.outputsInfo);
                            setViewHeader(runItem.header);
                            setViewContent(
                              specificRunOutput?.output?.results &&
                                specificRunOutput?.output?.results.length > 0
                                ? specificRunOutput?.output?.results[0]
                                : undefined
                            );
                            openFullScreen.onTrue();
                          }
                        }}
                        index={index}
                        conditionResult={cr}
                        selectedRunItems={selectedRunItems}
                      />
                    ))}
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Divider sx={{ flexGrow: 1, mb: 1, borderBottomWidth: '4px' }} />
                    <Typography sx={{ mx: 2 }}>Final Result</Typography>
                    <Divider sx={{ flexGrow: 1, mb: 1, borderBottomWidth: '4px' }} />
                  </Box>

                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      pl: 2,
                      pr: 1,
                      py: 1,
                      //   '&:not(:last-of-type)': {
                      //     borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
                      //   },
                    }}
                  >
                    <Typography variant="subtitle1">
                      Validations Passed:{' '}
                      {
                        conditionResults?.filter((r) => r.result && r.condition !== 'OverallResult')
                          .length
                      }
                    </Typography>
                    <Typography variant="subtitle1">
                      Validations Failed:{' '}
                      {
                        conditionResults?.filter(
                          (r) => !r.result && r.condition !== 'OverallResult'
                        ).length
                      }
                    </Typography>
                    <Typography variant="subtitle1">
                      Final Result:{' '}
                      {conditionResults?.find((r) => r.condition === 'OverallResult')?.result
                        ? 'Passed'
                        : 'Failed'}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}

            {runsMode &&
              selectedItem?.header === 'Validator' &&
              selectedItem?.inputData?.actionInputData?.functionalInputData?.validatorInput
                ?.validatorType === 'Manual' && (
                <>
                  <Stack spacing={3}>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <Alert severity={getSeverityColorByStatus(status as string)}>{status}</Alert>
                    </Stack>
                    {status === 'On Hold' && (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 2 }}
                        spacing={3}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setSelectedManualValidatorAction('Approve');
                            openDeleteConfirm.onTrue();
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            setSelectedManualValidatorAction('Reject');
                            openDeleteConfirm.onTrue();
                          }}
                        >
                          Reject
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </>
              )}
          </Scrollbar>
          <ConfirmDialog
            sx={{ zIndex: 2500 }}
            open={openDeleteConfirm.value}
            onClose={() => {
              openDeleteConfirm.onFalse();
            }}
            title={selectedManualValidatorAction === 'Approve' ? 'Approve' : 'Reject'}
            content={
              <Stack spacing={2}>
                <p>Are you sure you want to {selectedManualValidatorAction}?</p>
                <TextField
                  key="comment"
                  name="comment"
                  label="Comment"
                  value={comment}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                  multiline
                  rows={2}
                  sx={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
            }
            action={
              <LoadingButton
                variant="outlined"
                loading={loadingManualValidation}
                color={selectedManualValidatorAction === 'Approve' ? 'primary' : 'error'}
                onClick={async (event) => {
                  event.stopPropagation();
                  setLoadingManualValidation(true);
                  const aiFlowRunApi = new AiFlowRunsApi();
                  const manualValidationResponse =
                    await aiFlowRunApi.apiAiFlowRunsManualValidationPut({
                      runId: selectedAiFlowRun?.id,
                      validationDecision: selectedManualValidatorAction,
                      comment,
                    });
                  setLoadingManualValidation(false);
                  dispatch(updateRunOutput(manualValidationResponse.data.output));
                  dispatch(updateRun(manualValidationResponse.data.run));
                  enqueueSnackbar('Manual Validation Success.', { variant: 'success' });
                  openDeleteConfirm.onFalse();
                }}
              >
                Confirm {selectedManualValidatorAction === 'Approve' ? 'Approval' : 'Rejection'}
              </LoadingButton>
            }
          />
        </Box>
      </Stack>
      <FullPageDialog
        title="Output"
        zIndex={2500}
        children={
          <OutputCard
            outputInfo={viewOutputInfo ?? outputInfo}
            files={files}
            header={viewHeader ?? header}
            content={viewContent ?? content}
            height={620}
            fullScreen
          />
        }
        open={openFullScreen.value}
        onClose={() => {
          dispatch(setFullscreen(false));
          openFullScreen.onFalse();
        }}
      />
      <AiFlowItemOutputInfoDialog
        runOutputInfo={outputInfo as RunOutputInfo}
        onClose={openOutputInfo.onFalse}
        open={openOutputInfo.value}
      />
      <FileContentViewerDialog
        onClose={openFileViewer.onFalse}
        fileType={files && files.length > 0 ? files[0].split('.').pop() : 'txt'}
        fileUrl={fileUrl}
        fileContent={fileContent}
        open={openFileViewer.value}
      />
    </Card>
  );
}
