import { ActionInfo, AiFlowActionGroupLogic, AiFlowItemLogic, AiFlowLogic, AiFlowVersionLogic, ConfigurationLogic, CustomActionLogic, RunOutput } from "src/api";
import {
  Node,
} from '@xyflow/react';

export type IAiFlowState = {
    aiFlows: AiFlow[]; // aiFlow viewmodel
    sidebarPosition: string;
    addBeforeSequence: number;
    addToParentSequence?: number;
    sidebarPopoverOpen: HTMLElement | null;
    actionGroups: AiFlowActionGroupLogic[];
    actions: ActionInfo[];
    customActions: CustomActionLogic[];
    testActionResult: RunOutput | null;
    selectedCustomAction: CustomActionLogic | null;
    selectedAiFlow: AiFlowLogic | null;
    selectedAiFlowVersion: AiFlowVersionLogic | null;
    lastSavedSelectedAiFlow: AiFlowLogic | null;
    selectedAiFlowItem: AiFlowItemLogic | null;
    copiedItems: AiFlowItemLogic[];
    actionValidated: boolean;
    fullscreen: boolean;
    savingAiFlow: boolean;
    runsMode: boolean;
    loading: boolean;
    loadingAiFlowVersions: boolean;
    testingAction: boolean;
    loaded: boolean;
    isDirty: boolean;
    error: Error | string | null;
  };

  export interface AiFlowBranch {
    parentItem: AiFlowItem;
    children: AiFlowBranch[];
  }

  export interface AiFlowItemNode extends Node{
    item?: AiFlowItemLogic;
  }

export interface AiFlowItem extends AiFlowItemLogic {
  actionInfo?: ActionInfo;
  dragging?: boolean;
  new?: boolean;
}

export interface AiFlow extends AiFlowLogic {
  items: AiFlowItem[];
}

export interface InfoData{

}

export interface ActionInfoData extends InfoData{
  action?: string
}

export interface InputData{

}

export interface ActionInputData extends InputData{
  promptInput?: string;
  configuration?: ConfigurationLogic;
  configurationId?: number;
}

export enum ScheduledOccurenceType{
  Daily,
  Weekly,
  Monthly,
  Yearly
}

export enum FailoverTypes{
  ValidationFailure = 0,
  RuntimeError,
  Both
}