import { Stack, TextField } from '@mui/material';
import MailchimpMethodSelect from './MailchimpMethodSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import { updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import { useEffect, useState } from 'react';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import MailchimpCampaignSelect from './MailchimpCampaignSelect';
import MailchimpListSelect from './MailchimpListSelect';

export default function AiFlowInputMailchimpActionSetup() {
  const [timeframeType, setTimeframeType] = useState('QuickSelect');
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
  const [selectedListId, setSelectedListId] = useState<string>('');
  const [selectedMethod, setSelectedMethod] = useState<string>('List all campaigns');
  const [selectedQuickSelect, setSelectedQuickSelect] = useState('Today');
  const [timeFrameOptions, setTimeframeOptions] = useState([
    { label: 'Quick Select', value: 'QuickSelect', disabled: false },
    { label: 'Custom Range', value: 'CustomRange', disabled: false },
  ]);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  useEffect(() => {
    if (selectedItem) {
      setTimeframeType(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.mailchimpInputData
          ?.timeframeType ?? 'QuickSelect'
      );
      setSelectedCampaignId(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.mailchimpInputData
          ?.campaignId ?? ''
      );
      setSelectedListId(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.mailchimpInputData
          ?.listId ?? ''
      );
      setSelectedQuickSelect(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.mailchimpInputData
          ?.selectedQuickSelect ?? 'Last7Days'
      );
      setSelectedMethod(
        selectedItem?.inputData?.actionInputData?.inputData?.providerData?.mailchimpInputData
          ?.method ?? ''
      );
    }
  }, [selectedItem]);

  return (
    <Stack direction="column" spacing={2}>
      <MailchimpMethodSelect
        selectedMethodInput={selectedMethod}
        handleSelectMethod={(method: any) => {
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  providerData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                    method,
                    mailchimpInputData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                        ?.mailchimpInputData,
                      method,
                    },
                  },
                },
              },
            },
          };
          dispatch(updateSelectedAiFlowItem(updatedItem));
        }}
      />

      {selectedMethod === 'List all contacts' && (
        <MailchimpListSelect
        connectionId={selectedItem?.inputData?.actionInputData?.inputData?.providerData?.connectionId ?? 0}
          selectedListInput={selectedListId}
          handleSelectMethod={(listId: string) => {
            setSelectedListId(listId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      mailchimpInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.mailchimpInputData,
                        listId,
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateSelectedAiFlowItem(updatedItem));
          }}
        />
        // <TextField
        //   label="Audience ID"
        //   size="small"
        //   value={
        //     selectedItem?.inputData?.actionInputData?.inputData?.providerData?.params?.list_id ?? ''
        //   }
        //   onChange={(event: any) => {
        //     const updatedItem = {
        //       ...selectedItem,
        //       inputData: {
        //         ...selectedItem?.inputData,
        //         actionInputData: {
        //           ...selectedItem?.inputData?.actionInputData,
        //           inputData: {
        //             ...selectedItem?.inputData?.actionInputData?.inputData,
        //             providerData: {
        //               ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
        //               params: {
        //                 ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
        //                   ?.params,
        //                 list_id: event.target.value,
        //               },
        //             },
        //           },
        //         },
        //       },
        //     };
        //     dispatch(updateSelectedAiFlowItem(updatedItem));
        //   }}
        // />
      )}
      {(selectedMethod === 'Get campaign info' || selectedMethod === 'Get campaign content') && (
        <MailchimpCampaignSelect
          selectedCampaignInput={selectedCampaignId}
          handleSelectMethod={(campaignId: string) => {
            setSelectedCampaignId(campaignId);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      mailchimpInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.mailchimpInputData,
                        campaignId,
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateSelectedAiFlowItem(updatedItem));
          }}
        />
      )}
      {(selectedMethod === 'List all campaigns' ||
        selectedMethod === 'List A/B testing campaigns') && (
        <RHRadioGroup
          value={timeframeType}
          onChange={(event) => {
            setTimeframeType(event.target.value);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  inputData: {
                    ...selectedItem?.inputData?.actionInputData?.inputData,
                    providerData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                      mailchimpInputData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                          ?.mailchimpInputData,
                        timeframeType: event.target.value,
                      },
                    },
                  },
                },
              },
            };
            dispatch(updateSelectedAiFlowItem(updatedItem));
          }}
          row
          spacing={4}
          name="timeframes"
          options={timeFrameOptions}
        />
      )}
      {timeframeType === 'QuickSelect' &&
        (selectedMethod === 'List all campaigns' ||
          selectedMethod === 'List A/B testing campaigns') && (
          <RHRadioGroup
            value={selectedQuickSelect}
            onChange={(event) => {
              setSelectedQuickSelect(event.target.value);
              const updatedItem = {
                ...selectedItem,
                inputData: {
                  ...selectedItem?.inputData,
                  actionInputData: {
                    ...selectedItem?.inputData?.actionInputData,
                    inputData: {
                      ...selectedItem?.inputData?.actionInputData?.inputData,
                      providerData: {
                        ...selectedItem?.inputData?.actionInputData?.inputData?.providerData,
                        mailchimpInputData: {
                          ...selectedItem?.inputData?.actionInputData?.inputData?.providerData
                            ?.mailchimpInputData,
                          selectedQuickSelect: event.target.value,
                        },
                      },
                    },
                  },
                },
              };
              dispatch(updateSelectedAiFlowItem(updatedItem));
            }}
            row
            spacing={5}
          name="quickSelectTimeframes"
          options={[
            { label: 'Today', value: 'Today' },
            { label: 'Yesterday', value: 'Yesterday' },
            { label: 'Last 7 Days', value: 'Last7Days' },
            { label: 'Last 30 Days', value: 'Last30Days' },
            { label: 'Last 90 Days', value: 'Last90Days' },
            { label: 'Last 365 Days', value: 'Last365Days' },
          ]}
          />
        )}
      {timeframeType === 'CustomRange' && (
        <DateRangePicker
          slotProps={{
            popper: {
              // Targeting the popover component
              sx: {
                // Adding styles to the popover component
                zIndex: 9000, // Set your desired zIndex value
              },
            },
          }}
          localeText={{ start: 'Start Date', end: 'End Date' }}
        />
      )}
    </Stack>
  );
}
