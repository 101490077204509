import { MenuItem, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { RHSelect } from 'src/components/hook-form';
import { updateSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';

export default function AiFlowDelayActionSetup() {
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('');
  const [selectedDelayQuantity, setSelectedDelayQuantity] = useState<number>();
  const [selectedDelayType, setSelectedDelayType] = useState<number | null>(0);
  const [quantities, setQuantities] = useState<number[]>([15, 30, 45, 60]);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const dispatch = useDispatch();
  const [delayTypes, setDelayTypes] = useState<any[]>([
    {
      type: 0,
      label: 'Minutes',
    },
    {
      type: 1,
      label: 'Hours',
    },

    {
      type: 2,
      label: 'Days',
    },
    {
      type: 3,
      label: 'Weeks',
    },
    {
      type: 4,
      label: 'Months',
    },
  ]);

  useEffect(() => {
    if (selectedItem) {
      setSelectedDelayType(selectedItem?.inputData?.actionInputData?.delayInputData?.delayType ?? 0);
      setSelectedDelayQuantity(selectedItem?.inputData?.actionInputData?.delayInputData?.delayQuantity ?? 15);
      if (selectedDelayType === 0){
        setQuantities([15, 30, 45, 60]);
      }
      else if (selectedDelayType === 1){
        setQuantities([
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          24,
        ]);
      }
      else if (selectedDelayType === 2){
        setQuantities([1, 2, 3, 4, 5, 6, 7]);
      }
      else if (selectedDelayType === 3){
        setQuantities([1, 2, 3, 4]);
      }
      else if (selectedDelayType === 4){
        setQuantities([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
      }
    }
  }, [selectedItem, selectedDelayType]);

  return (
    <Stack spacing={1}>
      <Stack spacing={6}>
        <Typography variant="subtitle1">Wait</Typography>
        <RHSelect
          zIndex={4500}
          key="step"
          sx={{ marginTop: '24px !important' }}
          name="step"
          size="small"
          label="Unit Type"
          value={selectedDelayType ?? null}
          onChange={(event) => {
            const delayUnitType = parseInt(event.target.value, 10);
            setSelectedDelayType(delayUnitType);
            let delayQuantity = 1;
            if (delayUnitType === 2) {
              // days
              setQuantities([1, 2, 3, 4, 5, 6, 7]);
              delayQuantity = 1;
            } else if (delayUnitType === 1) {
              // hours
              setQuantities([
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                24,
              ]);
              delayQuantity = 1;
            } else if (delayUnitType === 3) {
              // weeks
              setQuantities([1, 2, 3, 4]);
            } else if (delayUnitType === 4) {
              // months
              setQuantities([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
              delayQuantity = 1;
            } else {
              setQuantities([15, 30, 45, 60]);
              delayQuantity = 15;
            }
            setSelectedDelayQuantity(delayQuantity);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  delayInputData: {
                    ...selectedItem?.inputData?.actionInputData?.delayInputData,
                    delayQuantity,
                    delayType: delayUnitType,
                  },
                },
              },
            };
            dispatch(updateSelectedAiFlowItem(updatedItem));
          }}
          InputLabelProps={{ shrink: true }}
        >
          {delayTypes.map((type) => (
            <MenuItem value={type.type}>
              <span>{type.label}</span>
            </MenuItem>
          ))}
        </RHSelect>
        <RHSelect
          zIndex={4500}
          key="quantity"
          sx={{ marginTop: '24px !important' }}
          name="step"
          size="small"
          label="Delay Quantity"
          value={selectedDelayQuantity ?? null}
          onChange={(event) => {
            const delayQuantity = parseInt(event.target.value, 10);
            setSelectedDelayQuantity(delayQuantity);
            const updatedItem = {
              ...selectedItem,
              inputData: {
                ...selectedItem?.inputData,
                actionInputData: {
                  ...selectedItem?.inputData?.actionInputData,
                  delayInputData: {
                    ...selectedItem?.inputData?.actionInputData?.delayInputData,
                    delayQuantity,
                  },
                },
              },
            };
            dispatch(updateSelectedAiFlowItem(updatedItem));
          }}
          InputLabelProps={{ shrink: true }}
        >
          {quantities.map((quantity) => (
            <MenuItem value={quantity}>
              <span>{quantity}</span>
            </MenuItem>
          ))}
        </RHSelect>
      </Stack>
    </Stack>
  );
}
