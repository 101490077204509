import _ from 'lodash';
import { AiFlowItem } from 'src/@types/aiflow';
import { ActionInfo, AiFlowItemLogic, AiFlowVersionLogic } from 'src/api';

export default function mapAiFlowItemData(
  aiFlowItem: AiFlowItem,
  aiFlowItems: AiFlowItem[],
  actions: ActionInfo[],
  groups: any[]
) {
  if (
    actions === undefined ||
    actions.length === 0 ||
    groups === undefined ||
    aiFlowItem === undefined
  ) {
    return aiFlowItem;
  }
  const header = aiFlowItem.header;
  const stateActions = _.cloneDeep(actions) as ActionInfo[];
  const actionInfo = stateActions.find((a) => a.header === header);
  const tmpActionGroup = groups.find(
    (group) =>
      group.actions &&
      group.actions?.find(
        (groupAction: ActionInfo) => groupAction && groupAction.header === header
      ) !== undefined
  );
  let tmpName = `${tmpActionGroup?.label}_${actionInfo?.actionLabel}`;
  console.log('tmpName', tmpName);
  const nameExist = aiFlowItems.find(
    (item) => item.name === tmpName && item?.sequence !== aiFlowItem?.sequence
  );
  console.log('nameExist', nameExist, aiFlowItem);
  if (nameExist) {
    // const itemsWithSameBaseName = aiFlowItems
    //   .filter((item) => item?.name?.startsWith(`${tmpName}*`)) // Filter items that start with the base name
    //   .map((item) => ({
    //     name: item.name,
    //     asteriskCount: (item?.name?.match(/\*/g) || []).length, // Count `*` in each item name
    //   }));

    //   const itemWithMostAsterisks = itemsWithSameBaseName
    //   .sort((a, b) => b.asteriskCount - a.asteriskCount) // Sort descending by asterisk count
    //   .shift(); // Get the first item from the sorted array, which has the most asterisks
    //   tmpName = `${tmpName}_`; // Append `*` instead of `~`
    // add random number from 100 to 1000
    tmpName = `${tmpName}_${Math.floor(Math.random() * 2900 + 100)}`;
    // tmpName = `${itemWithMostAsterisks?.name ?? tmpName}*`; // Append `*` instead of `~`
  }
  let actionName = tmpName;
  if (nameExist) {
    actionName = tmpName;
  } else if (!nameExist && aiFlowItem.name) {
    actionName = aiFlowItem?.name as string;
  }
  console.log('actionName', actionName);
  aiFlowItem = {
    ...aiFlowItem,
    inputData: aiFlowItem.inputData ?? {
      actionInputData: {
        promptInput: actionInfo?.prompt,
      },
    },
    name: actionName,
    actionInfo,
  };

  return aiFlowItem;
}

export const orderItemsSequence = (items: AiFlowItem[]) => {
  const itemsSequence = items.map((item, index) => ({
    ...item,
    sequence: index + 1,
  }));
  return itemsSequence;
};

export const orderItemsBySequence = (items: AiFlowItem[]) =>
  items.sort((a, b) => (a.sequence as number) - (b.sequence as number));

export const findLatestVersion = (aiFlowVersions: AiFlowVersionLogic[]) => {
  if (aiFlowVersions.length === 0) return null; // Return null if the array is empty

  return aiFlowVersions.reduce((highest, current) =>
    // If current version is higher than the highest found so far, return current, otherwise return highest
    (current.version ?? 0) > (highest.version ?? 0) ? current : highest
  );
};

export const updateAiFlowItemWithUpdatedSource = (
  source: string,
  newSource: string,
  item: AiFlowItem
) => {
  if (item.header === 'Locator') {
    if (item.inputData?.actionInputData?.functionalInputData?.locatorInput?.fileSource === source) {
      item.inputData.actionInputData.functionalInputData.locatorInput.fileSource = newSource;
    } else if (
      item.inputData?.actionInputData?.functionalInputData?.locatorInput?.selectedElementsSource ===
      source
    ) {
      item.inputData.actionInputData.functionalInputData.locatorInput.selectedElementsSource =
        newSource;
    }
  } else if (item.header === 'Validator') {
    if (
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.expectedSource &&
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.expectedSource ===
        source
    ) {
      item.inputData.actionInputData.functionalInputData.validatorInput.expectedSource = newSource;
    } else if (
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.targetSource &&
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.targetSource === source
    ) {
      item.inputData.actionInputData.functionalInputData.validatorInput.targetSource = newSource;
    }
  } else if (item.header === 'Extractor') {
    if (item.inputData?.actionInputData?.functionalInputData?.extractorInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.extractorInput.source = newSource;
    }
  } else if (item.header === 'transformer') {
    if (item.inputData?.actionInputData?.functionalInputData?.transformerInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.transformerInput.source = newSource;
    }
  } else if (item.header === 'Segmentation') {
    if (item.inputData?.actionInputData?.functionalInputData?.segmentInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.segmentInput.source = newSource;
    }
  } else if (item.header === 'Scrapper') {
    if (item.inputData?.actionInputData?.functionalInputData?.scrapperInput?.source === source) {
      item.inputData.actionInputData.functionalInputData.scrapperInput.source = newSource;
    }
  }
};

export const checkAiFlowItemHasSource = (item: AiFlowItem, source: string) => {
  if (item.header === 'Locator') {
    return (
      item.inputData?.actionInputData?.functionalInputData?.locatorInput?.fileSource === source ||
      item.inputData?.actionInputData?.functionalInputData?.locatorInput?.selectedElementsSource ===
        source
    );
  } else if (item.header === 'Validator') {
    return (
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.expectedSource ===
        source ||
      item.inputData?.actionInputData?.functionalInputData?.validatorInput?.targetSource === source
    );
  } else if (item.header === 'Extractor') {
    return item.inputData?.actionInputData?.functionalInputData?.extractorInput?.source === source;
  } else if (item.header === 'transformer') {
    return (
      item.inputData?.actionInputData?.functionalInputData?.transformerInput?.source === source
    );
  } else if (item.header === 'Segmentation') {
    return item.inputData?.actionInputData?.functionalInputData?.segmentInput?.source === source;
  } else if (item.header === 'Scrapper') {
    return item.inputData?.actionInputData?.functionalInputData?.scrapperInput?.source === source;
  }
  return false;
};

export const orderFlowItems = (
  newAiFlowItems: AiFlowItem[],
  lastSavedItems: AiFlowItem[],
  versionId = null
) => {
  // Step 1: Create a mapping from old sequences to item names (from the last saved state)
  const newAiFlowItemsOfVersion =
    newAiFlowItems?.filter((item) => item.aiFlowVersionId === versionId) ?? [];
  console.log('newAiFlowItemsOfVersion', newAiFlowItemsOfVersion);
  const lastSavedItemsOfVersion =
    lastSavedItems?.filter((item) => item.aiFlowVersionId === versionId) ?? [];
  console.log('lastSavedItemsOfVersion', lastSavedItemsOfVersion);

  const oldSequenceToNameMap = new Map();
  lastSavedItemsOfVersion.forEach((item) => {
    oldSequenceToNameMap.set(item.sequence, item.name);
  });

  // Step 2: Create a mapping from item names to old parent sequences
  const nameToOldParentSequenceMap = new Map();
  lastSavedItemsOfVersion.forEach((item) => {
    nameToOldParentSequenceMap.set(item.name, item.parentSequence);
  });

  // Step 3: Update sequence numbers and create a mapping from item names to new sequences
  const newNameToSequenceMap = new Map();
  newAiFlowItemsOfVersion.forEach((item, index) => {
    item.sequence = index + 1; // Update sequence numbers
    newNameToSequenceMap.set(item.name, item.sequence);
  });

  // Step 4: Update parentSequence for each item
  newAiFlowItemsOfVersion.forEach((item, index) => {
    // For non-branch items
    if (item.header !== 'Branch') {
      if (index > 0) {
        // Set parentSequence to the sequence of the previous item
        item.parentSequence = newAiFlowItemsOfVersion[index - 1].sequence;
      } else {
        // First item has no parent
        item.parentSequence = null;
      }
    } else {
      // For branch items
      const oldParentSequence = nameToOldParentSequenceMap.get(item.name);

      if (oldParentSequence !== undefined) {
        // Find the parent item's name using oldParentSequence
        const parentItemName = oldSequenceToNameMap.get(oldParentSequence);

        if (parentItemName) {
          // Get the new parentSequence using newNameToSequenceMap
          const newParentSequence = newNameToSequenceMap.get(parentItemName);

          if (newParentSequence) {
            item.parentSequence = newParentSequence;
          } else {
            // Parent item not found in new items; default to previous item's parentSequence
            //       item.parentSequence = newAiFlowItems[index - 1]?.parentSequence || null;
          }
        } else {
          // Parent item name not found; default to previous item's parentSequence
          // item.parentSequence = newAiFlowItems[index - 1]?.parentSequence || null;
        }
      } else {
        //   go back until you find branches
        // let i = index;
        // while (i >= 0) {
        //   if (newAiFlowItemsOfVersion[i].header === 'Branches') {
        //     item.parentSequence = newAiFlowItemsOfVersion[i].sequence;
        //     break;
        //   }
        //   i--;
        // }
        // No old parentSequence; default to previous item's parentSequence
        //   item.parentSequence = newAiFlowItems[index - 1]?.parentSequence || null;
      }
    }
  });
};

export const reassignSequencesUsingNames = (items: AiFlowItemLogic[]) => {
  // First, store old sequences to items mapping
  const oldSequenceToItem:any = {};
  items.forEach((item) => {
    oldSequenceToItem[item.sequence as number] = item;
  });

  // Also store parent item names
  items.forEach((item: any) => {
    if (item.parentSequence !== null) {
      const parentItem = oldSequenceToItem[item.parentSequence];
      if (parentItem) {
        item.parentName = parentItem.name;
      } else {
        item.parentName = null;
      }
    } else {
      item.parentName = null;
    }
  });

  const nameToNewSequence:any = {};
  let newSequence = 1;

  // Reassign sequence numbers and build mapping
  items.forEach((item:any) => {
    nameToNewSequence[item.name as string] = newSequence;
    item.sequence = newSequence;
    newSequence++;
  });

  // Adjust parentSequence using the mapping
  items.forEach((item:any) => {
    if (item.parentName !== null) {
      item.parentSequence = nameToNewSequence[item.parentName];
    } else {
      item.parentSequence = null;
    }
    // Remove the temporary parentName property
    delete item.parentName;
  });

  return items;
};